/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Axios from 'axios';
import config from 'react-global-configuration';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Formatter, Mask } from '../../utils';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useDispatch } from 'react-redux';
import { addMessage } from '../../store/alert';
import Switch from '@material-ui/core/Switch';
import { Isset } from '../../utils';
import ZLoader from '../system/ZLoader';

// import Grid from '@material-ui/core/Grid';
import { TextField, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	calculadora: {},
	content: {
		minHeight: 700,
	},
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
		textAlign: 'center',
	},
	label: {
		fontSize: 12,
		fontWeight: 700,
		textAlign: 'center',
	},
	labelValor: {
		fontSize: 16,
		fontWeight: 800,
		color: 'black',
		textAlign: 'center',
	},
	pageTitle: {
		fontWeight: 'bold',
		fontSize: 18,
		marginBottom: 10,
	},
	planosTaxas: {
		position: 'relative',
	},
	tiposPagamentos: {
		position: 'absolute',
		paddingTop: 50,
		paddingBottom: 52,
		border: 'solid 1px #abc',
		borderRadius: 10,
		padding: 10,
		width: 'calc(100% - 30px)',
	},
	planos: {
		position: 'relative',
		padding: 14,
		height: 50,
		fontSize: 14,
		fontWeight: 600,
		'& span': {
			fontWeight: 'bold',
			fontSize: 16,
		},
	},
	taxaBoletoDiv: {
		marginTop: 10,
		position: 'relative',
		padding: 10,
		height: 50,
	},
	tipoPagamento: {
		fontWeight: 'bold',
		fontSize: 16,
		padding: 4,
		'&:not(:last-child)': {
			borderBottom: 'solid 1px #bcd',
		},
	},
	tipoPagamentoBoleto: {
		float: 'left',
		fontWeight: 'bold',
		fontSize: 16,
		padding: 4,
	},
	bandeirasWrapper: {
		position: 'absolute',
		marginLeft: 120,
		width: 'calc(100% - 160px)',
		// overflowX: 'auto',
	},
	bandeira: {
		position: 'relative',
		padding: 10,
		marginTop: 10,
		border: 'solid 1px #abc',
		borderRadius: 10,
		backgroundColor: '#ffffffc0',
		// height: 490,
		width: 105,
		marginRight: 10,
		float: 'left',
	},
	novaBandeira: {
		position: 'relative',
		padding: 10,
		marginTop: 10,
		border: 'solid 1px #abc',
		borderRadius: 10,
		backgroundColor: '#ffffff',
		width: 160,
		marginRight: 10,
		float: 'left',
	},
	title: {
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: 16,
		height: 22,
		display: 'flex',
		justifyContent: 'center',
	},
	titleLong: {
		fontSize: 11,
	},
	deleteButton: {
		top: 2,
		right: 2,
		cursor: 'pointer',
		position: 'absolute',
		width: 20,
		height: 20,
		color: '#e53935',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		opacity: 0.5,
		'&:hover': {
			opacity: 1,
		},
	},
	fields: {
		marginTop: 8,
		marginBottom: 15,
		borderTop: 'solid 1px #bcd',
	},
	tipoPagamentoFields: {
		borderBottom: 'solid 1px #bcd',
	},
	taxaField: {
		padding: 7.45,
		'&::after': {
			content: '"%"',
			position: 'absolute',
			right: 4,
			fontSize: 12,
		},
		'& .MuiInputBase-input': {
			textAlign: 'right',
			height: '1em',
			padding: 0,
			paddingRight: 15,
		},
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			bottom: -7,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
		},
	},
	saveButton: {
		textAlign: 'center',
		textTransform: 'uppercase',
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
		marginTop: 5,
	},
	adicionarButton: {
		textAlign: 'center',
		textTransform: 'uppercase',
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
		marginTop: 15,
	},
	taxaFieldBoletoReadOnly: {
		marginLeft: 120,
		height: 30.8,
		fontWeight: 'bold',
		fontSize: 15,
		padding: 4,
	},
	taxaFieldReadOnly: {
		height: 30.8,
		marginTop: 1,
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: 15,
		padding: 4,
	},
	empty: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: 18,
		fontWeight: 700,
		height: 100,
	},
	highlight: {
		backgroundColor: '#fdfdc4ad',
	},
}));

export default props => {
	let { estabelecimentoId } = useParams();
	if (!estabelecimentoId) {
		({ estabelecimentoId } = props);
	}

	let ecViaSelect = props.estabelecimentoId;
	const token = useSelector(store => store.auth.token);
	const classes = useStyles();
	const dispatch = useDispatch();

	const [valorLiquido, setValorLiquido] = useState(+props.valor || 0);

	// const [planoVenda, setPlanoVenda] = useState({});
	// const [planoTaxa, setPlanoTaxa] = useState({});
	const [taxaBoleto, setTaxaBoleto] = useState(0);
	const [taxas, setTaxas] = useState({});
	const [countBandeiras, setCountBandeiras] = useState(1);
	const [loading, setLoading] = useState(false);
	const [position, setPosition] = useState({ bandeiraId: null, parcela: null });
	const [readonly] = useState(props.readonly || false);
	const [online, setOnline] = useState(props.online || false);
	const [maximoDeParcelas, setMaximoDeParcelas] = useState(12);

	let cobrancaPorTransacao = useSelector(store =>
		Isset(() => store.system.params['40'].valor) ? store.system.params['40'].valor : null
	);
	let taxaVendaOnline = useSelector(store =>
		Isset(() => store.system.params['20'].valor) ? store.system.params['20'].valor : null
	);
	useEffect(() => {
		if (!ecViaSelect) return;

		const fetchParametros = async () => {
			try {
				const res = await Axios.get(`${config.get('apiUrl')}parametros/por_estabelecimento/${ecViaSelect}`, {
					headers: { Authorization: `Bearer ${token}` },
				});

				if (res.data.success) {
					const parametros = res.data.parametros;
					cobrancaPorTransacao = parametros.find(param => param.id === 40)?.valor;
					taxaVendaOnline = parametros.find(param => param.id === 20)?.valor;
				}
			} catch (error) {
				console.error('Erro buscar taxas do estabelecimento selecionado:', error);
			}
		};

		fetchParametros();
	}, [ecViaSelect]);

	const updateValores = async ({ taxas, taxaBoleto }) => {
		if (valorLiquido > 0) {
			const taxasAux = { ...taxas };

			Object.keys(taxasAux).forEach(bandeiraId => {
				Object.keys(taxasAux[bandeiraId].valores).forEach(tipoPagamentoId => {
					Object.keys(taxasAux[bandeiraId].valores[tipoPagamentoId].valores).forEach(parcela => {
						const { taxa } = taxasAux[bandeiraId].valores[tipoPagamentoId].valores[parcela];

						// Novo cálculo considerando as cobranças extras
						const custoFixoTransacao = parseFloat(cobrancaPorTransacao || 0);
						const taxaExtraVendaOnline = online ? parseFloat(taxaVendaOnline || 0) : 0;
						// console.log('Custo fixo por transação:', custoFixoTransacao);
						// console.log('Taxa adicional venda online:', taxaExtraVendaOnline);

						taxasAux[bandeiraId].valores[tipoPagamentoId].valores[parcela].valorBruto =
							taxa === 0
								? 0
								: ((valorLiquido + custoFixoTransacao + taxaExtraVendaOnline) * 100) / (100 - taxa);

						if (taxa === 0) {
							delete taxasAux[bandeiraId].valores[tipoPagamentoId].valores[parcela];
						}
					});
				});
			});

			setTaxas(taxasAux);

			const custoFixoTransacaoBoleto = parseFloat(cobrancaPorTransacao || 0);
			const taxaExtraVendaOnlineBoleto = online ? parseFloat(taxaVendaOnline || 0) : 0;
			// console.log('Custo fixo por transação:', custoFixoTransacaoBoleto);
			// console.log('Taxa adicional venda online:', taxaExtraVendaOnlineBoleto);

			setTaxaBoleto({
				...taxaBoleto,
				valorBruto: valorLiquido + taxaBoleto.taxa + custoFixoTransacaoBoleto + taxaExtraVendaOnlineBoleto,
			});
		}
	};

	const updateMaximoParcelas = () => {
		if (valorLiquido > 0 && Object.keys(taxas).length > 0) {
			setMaximoDeParcelas(
				Object.keys(taxas).map(bandeiraId =>
					Object.keys(taxas[bandeiraId].valores['credit'].valores).map(item => item)
				)[0].length
			);
		}
	};

	useEffect(() => {
		const getTaxas = async () => {
			setLoading(true);
			const res = await Axios.get(
				`${config.get('apiUrl')}planos_taxas/porEstabelecimento/${estabelecimentoId}/taxaTotal`,
				{
					params: { tipoVendaId: online ? 2 : 1 },
					headers: { Authorization: `Bearer ${token}` },
				}
			);

			if (res.data.success) {
				const taxa = res.data.taxaTotal;

				Object.keys(taxa).forEach(bandeiraId => {
					Object.keys(taxa[bandeiraId].valores).forEach(tipoPagamentoId => {
						Object.keys(taxa[bandeiraId].valores[tipoPagamentoId].valores).forEach(parcela => {
							taxa[bandeiraId].valores[tipoPagamentoId].valores[parcela] = {
								taxa: taxa[bandeiraId].valores[tipoPagamentoId].valores[parcela],
								valorBruto: 0,
							};
						});
					});
				});

				// setPlanoVenda(res.data.planoVenda);
				// setPlanoTaxa(res.data.planoTaxa);
				setTaxaBoleto({ taxa: res.data.taxaBoleto, valorBruto: 0 });
				setTaxas(taxa);
				updateMaximoParcelas();
				setCountBandeiras(Object.keys(taxa).length);
				updateValores({ taxas: taxa, taxaBoleto: { taxa: res.data.taxaBoleto, valorLiquido: 0 } });
			} else {
				dispatch(addMessage({ type: 'error', message: res.data.error }));
			}
			setLoading(false);
		};

		getTaxas();
	}, [online]);

	useEffect(() => {
		updateMaximoParcelas();
	}, [loading, online]);

	useEffect(() => {
		updateValores({ taxas, taxaBoleto });
		updateMaximoParcelas();
	}, [valorLiquido, online]);

	return (
		<Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="md">
			<DialogContent className={classes.content}>
				<div className={classes.calculadora}>
					<div className={classes.header}>Calculadora de Valor Bruto</div>
					<div style={{ display: 'flex' }}>
						<TextField
							disabled={readonly}
							value={Mask.Real(valorLiquido)}
							onChange={e => setValorLiquido(+Mask.OnlyNumber(e.target.value) / 100)}
							label="Valor Líquido"
							InputLabelProps={{
								classes: {
									root: classes.labelValor,
								},
							}}
						/>
						{props.showOnlineButton && (
							<div>
								<Typography className={classes.label}>Taxa Online</Typography>
								<Switch
									checked={online}
									onChange={e => setOnline(e.target.checked)}
									name="checkedA"
									inputProps={{ 'aria-label': 'secondary checkbox' }}
								/>
							</div>
						)}
					</div>

					{loading ? (
						<ZLoader height={200} message="Calculando" />
					) : (
						<>
							{valorLiquido > 0 && Object.keys(taxas).length > 0 ? (
								<>
									<div className={classes.taxaBoleto}>
										<div className={clsx(classes.tiposPagamentos, classes.taxaBoletoDiv)}>
											<div className={classes.tipoPagamentoBoleto}>Boleto</div>
											<div className={classes.taxaFieldBoletoReadOnly}>
												{Formatter.Real(taxaBoleto.valorBruto)}
											</div>
										</div>
									</div>
									<div style={{ marginTop: 10 }}>
										<div className={classes.tiposPagamentos}>
											{Array.from({ length: maximoDeParcelas }).map((_, index) => {
												const parcela = `credit${index + 1}`;

												return (
													<div
														key={index}
														className={clsx(classes.tipoPagamento, {
															[classes.highlight]: position.parcela === parcela,
														})}
													>
														{`Crédito ${index + 1}x`}
													</div>
												);
											})}

											{!online && (
												<div
													className={clsx(classes.tipoPagamento, {
														[classes.highlight]: position.parcela === 'debit1',
													})}
												>
													Débito
												</div>
											)}
										</div>

										{loading ? (
											<ZLoader height={300} />
										) : (
											<div className={classes.bandeirasWrapper}>
												<div style={{ width: (countBandeiras + 1) * 110 }}>
													{Object.keys(taxas).map(bandeiraId => (
														<div
															key={`Bandeira-${bandeiraId}`}
															className={clsx(classes.bandeira, {
																[classes.highlight]: position.bandeiraId === bandeiraId,
															})}
														>
															<div
																className={clsx(classes.title, {
																	[classes.titleLong]:
																		taxas[bandeiraId].titulo.length > 10,
																})}
															>
																{taxas[bandeiraId].titulo}
															</div>
															<div className={classes.fields}>
																{Object.keys(taxas[bandeiraId].valores).map(
																	tipoPagamentoId => (
																		<div
																			key={`${bandeiraId}${tipoPagamentoId}`}
																			className={classes.tipoPagamentoFields}
																		>
																			{Object.keys(
																				taxas[bandeiraId].valores[
																					tipoPagamentoId
																				].valores
																			).map(parcela => (
																				<div
																					key={`${bandeiraId}-${parcela}`}
																					className={
																						classes.taxaFieldReadOnly
																					}
																					onMouseEnter={() =>
																						setPosition({
																							bandeiraId,
																							parcela: `${tipoPagamentoId}${parcela}`,
																						})
																					}
																					onMouseLeave={() =>
																						setPosition({
																							bandeiraId: null,
																							parcela: null,
																						})
																					}
																				>
																					{Mask.Real(
																						taxas[bandeiraId].valores[
																							tipoPagamentoId
																						].valores[parcela].valorBruto
																					)}
																				</div>
																			))}
																		</div>
																	)
																)}
															</div>
														</div>
													))}
												</div>
											</div>
										)}
									</div>
								</>
							) : (Object.keys(taxas).length === 0 && valorLiquido === 0) || valorLiquido === 0 ? (
								<div className={classes.empty}>Informe o Valor Bruto</div>
							) : (
								<div className={classes.empty}>{`Taxa de venda ${
									online ? 'online' : 'física'
								} não encontrada`}</div>
							)}
						</>
					)}
				</div>
			</DialogContent>
		</Dialog>
	);
};
