/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { updateSaldo } from '../../store/system';
import Grid from '@material-ui/core/Grid';

import axios from 'axios';
import config from 'react-global-configuration';

import { Formatter, Isset } from '../../utils';
import { Button } from '@material-ui/core';

import { FaChevronLeft } from 'react-icons/fa';

import NovaTransferencia from '../Transferencias/NovaTransferencia';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
	saldoButtons: {
		textAlign: 'left',
		display: 'none',
		[theme.breakpoints.up('sm')]: {
			display: 'flex',
		},
	},
	button: {
		fontFamily: "'Exo 2', sans-serif",
		letterSpacing: 0,
		textTransform: 'none',
		color: theme.palette.getContrastText(theme.palette.primary.main),
	},
	buttonNovaVenda: {
		fontFamily: "'Exo 2', sans-serif",
		letterSpacing: 0,
		textTransform: 'none',
		color: theme.palette.getContrastText(theme.palette.primary.main),
		backgroundColor: 'rgba(180, 180, 180, 0.3)',
		fontWeight: 'bold',
		border: '1px solid rgba(0, 0, 0, 0.1)',
	},
	saldoBar: {
		backgroundColor: 'rgba(255,255,255,0.2)',
		padding: '4px 20px',
		textAlign: 'right',
		fontSize: '12px',
		height: '46px',
		fontFamily: "'Exo 2', sans-serif",
	},
	saldoValue: {
		fontWeight: 'bold',
		fontSize: '16px',
	},
	saldo: {
		cursor: 'pointer',
		[theme.breakpoints.down('xs')]: {
			textAlign: 'center',
		},
	},
	saldoReceber: {
		[theme.breakpoints.down('xs')]: {
			textAlign: 'center',
		},
	},
}));

export default props => {
	const history = useHistory();
	const location = useHistory();

	const dispatch = useDispatch();

	const token = useSelector(state => state.auth.token);
	const usuario = useSelector(state => state.auth.usuario);
	const estabelecimento = useSelector(state => state.admin.estabelecimento);
	const update = useSelector(state => state.system.updateSaldo);
	const [openTransferencia, setOpenTransferencia] = useState(false);
	const [saldo, setSaldo] = useState({
		atual: 0,
		futuro: 0,
		parcial: 0,
	});
	const classes = useStyles();
	// const param = useSelector(state => state.system.params['35']);
	// console.log(param);

	const canTransferencia = useSelector(store =>
		Isset(() => store.system.params['22'].valor) ? store.system.params['22'].valor : null
	);

	const canSaldoAdicional = useSelector(store =>
		Isset(() => store.system.params['44'].valor) ? store.system.params['44'].valor : null
	);

	const desabilitarNovaVenda = useSelector(store =>
		Isset(() => store.system.params['45'].valor) ? store.system.params['45'].valor : null
	);

	const canSale = Isset(() => usuario.estabelecimento.config.can_sale)
		? !!+usuario.estabelecimento.config.can_sale
		: true;

	const canCriarTransferencia = canTransferencia && [1, 2].includes(usuario.grupo_id);
	const canNovaVenda = [1, 2, 3, 10].includes(usuario.grupo_id) && canSale && !desabilitarNovaVenda;
	const canViewSaldo = [1, 2, 3, 4, 7, 10, 11, 13, 15].includes(usuario.grupo_id);
	const showVoltar = !['/', '/dashboard/representante'].includes(location.pathname);
	const [isLoading, setIsLoading] = useState(false);
	const isElo7 = usuario.estabelecimento.marketplace.id === 129;
	const isAdmin = usuario.grupo_id === 1;

	useEffect(() => {
		dispatch(updateSaldo(true));
	}, []);

	useEffect(() => {
		if (canSaldoAdicional) {
			const getSaldoParcial = async () => {
				try {
					const res = await axios.get(`https://zgs-transfers.nectaco.com.br/transfers/get-future-balance`, {
						headers: {
							Authorization: `Bearer ${token}`,
							authBy: 'zspay',
						},
					});
					if (res.data.futureBalance) {
						setSaldo({
							...saldo,
							parcial: res.data.futureBalance,
						});
					}
				} catch (e) {
					console.log(e);
				}
			};
			dispatch(updateSaldo(true));
			getSaldoParcial();
		}
	}, [canSaldoAdicional]);
	const atendimentoElo7 = usuario.estabelecimento.marketplace.id === 129 && usuario.grupo_id === 12;

	const [estabelecimentoId, setEstabelecimentoId] = useState(null);

	useEffect(() => {
		if ((update && canViewSaldo) || atendimentoElo7) {
			const getSaldo = async () => {
				let id = Isset(() => estabelecimento.id) ? estabelecimento.id : usuario.estabelecimento.id;
				setEstabelecimentoId(id);

				setIsLoading(true);

				const res = await axios.get(`${config.get('apiUrl')}estabelecimentos/${id}/saldo`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				setIsLoading(false);

				if (res.data.success) {
					setSaldo({
						...saldo,
						...res.data.saldo,
					});
				}
			};

			dispatch(updateSaldo(false));
			getSaldo();
		}
	}, [update]);

	const saldoDisponivel = () => {
		if (
			[7].includes(usuario.grupo_id) ||
			(usuario.estabelecimento.marketplace.id === 129 &&
				usuario.grupo_id !== 1 &&
				usuario.grupo_id !== 11 &&
				usuario.grupo_id !== 13 &&
				usuario.grupo_id !== 14 &&
				usuario.grupo_id !== 15)
		) {
			return false;
		}
		history.push('/extrato');
	};

	const saldoReceber = () => {
		if ([7].includes(usuario.grupo_id)) {
			return false;
		}
		history.push('/proximos-lancamentos?tt=2&d=next6months');
	};
	let textoBotao;
	let textoBotaoSaque;
	if (usuario.estabelecimento.marketplace.id === 129 && usuario.grupo_id !== 1) {
		textoBotao = 'Depósito';
		textoBotaoSaque = 'Saque';
	} else {
		textoBotao = 'Nova Venda';
		textoBotaoSaque = 'Criar Transferência';
	}

	return (
		<div className={clsx(classes.saldoBar)}>
			<Grid container spacing={3} direction="row" alignItems="center">
				<Grid item sm={6} md={6} className={classes.saldoButtons}>
					{showVoltar && (
						<Button className={classes.button} onClick={() => history.goBack()}>
							<FaChevronLeft /> voltar
						</Button>
					)}
					{usuario.estabelecimento.status.id === 2 && (
						<>
							{canCriarTransferencia && (
								<Button className={classes.button} onClick={() => setOpenTransferencia(true)}>
									{textoBotaoSaque}
								</Button>
							)}
							{canNovaVenda && (
								<Button
									className={classes.buttonNovaVenda}
									onClick={() => history.push('/vendas/nova')}
								>
									{textoBotao}
								</Button>
							)}
						</>
					)}
				</Grid>
				{isElo7 && (isAdmin || estabelecimentoId !== 45693) && (
					<>
						<Grid
							item
							xs={canSaldoAdicional ? 4 : 6}
							sm={canSaldoAdicional ? 2 : 3}
							md={canSaldoAdicional ? 2 : 3}
							className={clsx(classes.saldo, classes.saldoReceber)}
							onClick={saldoDisponivel}
						>
							<div>Saldo Disponível</div>
							<div className={clsx(classes.saldoValue)}>
								{isLoading ? (
									<div className="-mx-20">
										<CircularProgress size={24} style={{ color: '#555555' }} />
									</div>
								) : (
									Formatter.Real(saldo.atual)
								)}
							</div>
						</Grid>
						<Grid
							item
							xs={canSaldoAdicional ? 4 : 6}
							sm={canSaldoAdicional ? 2 : 3}
							md={canSaldoAdicional ? 2 : 3}
							className={clsx(classes.saldo, classes.saldoReceber)}
							onClick={saldoReceber}
						>
							<div>Saldo a receber</div>
							<div className={clsx(classes.saldoValue)}>
								{isLoading ? (
									<CircularProgress size={24} style={{ color: '#555555' }} />
								) : (
									Formatter.Real(saldo.futuro)
								)}
							</div>
						</Grid>
					</>
				)}

				{canViewSaldo && !isElo7 && (
					<>
						{/* {canSaldoAdicional && (
							<Grid item xs={4} sm={2} md={2} className={clsx(classes.saldo, classes.saldoReceber)}>
								<div>Saldo parcial</div>
								<div className={clsx(classes.saldoValue)}>
									{isLoading ? (
										<CircularProgress
											size={24}
											style={{ color: '#555555', marginLeft: '0 20px' }}
										/>
									) : (
										Formatter.Real(saldo.parcial)
									)}
								</div>
							</Grid>
						)} */}
						<Grid
							item
							xs={6}
							sm={3}
							md={3}
							className={clsx(classes.saldo, classes.saldoReceber)}
							onClick={saldoDisponivel}
						>
							<div>Saldo Disponível</div>
							<div className={clsx(classes.saldoValue)}>
								{isLoading ? (
									<div className="-mx-20">
										<CircularProgress size={24} style={{ color: '#555555' }} />
									</div>
								) : (
									Formatter.Real(saldo.atual)
								)}
							</div>
						</Grid>
						<Grid
							item
							xs={6}
							sm={3}
							md={3}
							className={clsx(classes.saldo, classes.saldoReceber)}
							onClick={saldoReceber}
						>
							<div>Saldo a receber</div>
							<div className={clsx(classes.saldoValue)}>
								{isLoading ? (
									<CircularProgress size={24} style={{ color: '#555555' }} />
								) : (
									Formatter.Real(saldo.futuro)
								)}
							</div>
						</Grid>
					</>
				)}
				{openTransferencia && <NovaTransferencia open={true} onClose={() => setOpenTransferencia(false)} />}
			</Grid>
		</div>
	);
};
