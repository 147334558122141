/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, memo } from 'react';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
// import InfiniteScroll from 'react-infinite-scroll-component';
import TablePagination from '@material-ui/core/TablePagination';
import axios from 'axios';
import config from 'react-global-configuration';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import { FaDownload } from 'react-icons/fa';
import clsx from 'clsx';

import moment from 'moment';
import ZTitle from '../system/ZTitle';
import ZMenuOptions from '../system/ZMenuOptions';
import ZFiltros from '../system/ZFiltros';
import { Formatter, Isset } from '../../utils';
import ZLoader from '../system/ZLoader';
import { makeStyles } from '@material-ui/core/styles';
import { addMessage } from '../../store/alert';
import ZModalConfirm from '../system/ZModalConfirm';
import FileDownload from 'js-file-download';

const useStyles = makeStyles(theme => ({
	export: {
		position: 'absolute',
		right: 10,
		opacity: 0.5,
		cursor: 'pointer',
		color: '#464ae6',
		fontSize: 16,
		transition: theme.transitions.create(['all'], {
			duration: theme.transitions.duration.complex,
		}),
		'&:hover': {
			fontSize: 18,
			opacity: 1,
		},
	},
	btn: {
		marginBottom: 10,
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}dd`,
		},
	},
}));

const getStatusLabel = status => {
	switch (+status) {
		case 2:
			return (
				<Chip
					size="small"
					label="Habilitado"
					style={{
						backgroundColor: '#43a047',
						color: 'white',
						fontSize: 10,
						textTransform: 'uppercase',
						fontWeight: 700,
					}}
				/>
			);
		case 3:
			return (
				<Chip
					size="small"
					label="Inativo"
					style={{
						backgroundColor: '#e53935',
						color: 'white',
						fontSize: 10,
						textTransform: 'uppercase',
						fontWeight: 700,
					}}
				/>
			);
		case 4:
			return (
				<Chip
					size="small"
					label="Desabilitado"
					style={{
						backgroundColor: '#e53935',
						color: 'white',
						fontSize: 10,
						textTransform: 'uppercase',
						fontWeight: 700,
					}}
				/>
			);
		default:
			return (
				<Chip
					size="small"
					label="Aguardando Aprovação"
					style={{
						backgroundColor: '#e6c038',
						color: 'black',
						fontSize: 10,
						textTransform: 'uppercase',
						fontWeight: 700,
					}}
				/>
			);
	}
};

const EstabelecimentoRow = memo(props => {
	const { estabelecimento } = props;
	const usuario = useSelector(store => store.auth.usuario);

	return (
		<TableRow>
			<TableCell component="th" scope="row">
				{estabelecimento.nome_fantasia}
				<div style={{ fontSize: 10 }}>
					{`${estabelecimento.razao_social} - `}
					{estabelecimento.identificacao_fatura}
				</div>
			</TableCell>
			<TableCell align="right">
				{estabelecimento.documento && (
					<>
						{estabelecimento.documento.length <= 11
							? Formatter.Cpf(estabelecimento.documento)
							: Formatter.Cnpj(estabelecimento.documento)}
					</>
				)}
			</TableCell>
			<TableCell align="right">{estabelecimento.email}</TableCell>
			<TableCell align="right">{moment(estabelecimento.created, 'YYYY-MM-DD').format('DD/MM/YYYY')}</TableCell>
			{[1, 7, 10].includes(usuario.grupo_id) && <TableCell align="right">{estabelecimento.repMaster}</TableCell>}
			<TableCell align="right">{getStatusLabel(estabelecimento.status_estabelecimento_id)}</TableCell>
			<TableCell align="right">
				<ZMenuOptions
					data={[
						// {
						// 	title: 'Visualizar Estabelecimento',
						// 	visible: [5, 8].includes(usuario.grupo_id),
						// 	onClick: () => {
						// 		dispatch(
						// 			setSelectedEC({
						// 				id: estabelecimento.id,
						// 				nome: estabelecimento.nome_fantasia,
						// 			})
						// 		);
						// 		history.push('/estabelecimentos/visualizar/' + estabelecimento.id);
						// 	},
						// },
						// {
						// 	title: 'Editar Estabelecimento',
						// 	visible: usuario.grupo_id <= 3 || usuario.grupo_id === 7,
						// 	onClick: () => {
						// 		dispatch(
						// 			setSelectedEC({
						// 				id: estabelecimento.id,
						// 				nome: estabelecimento.nome_fantasia,
						// 			})
						// 		);
						// 		history.push('/estabelecimentos/editar/' + estabelecimento.id);
						// 	},
						// },
						// {
						// 	title: 'Configurar Split',
						// 	visible: usuario.grupo_id <= 2,
						// 	onClick: () => {
						// 		dispatch(
						// 			setSelectedEC({
						// 				id: estabelecimento.id,
						// 				nome: estabelecimento.nome_fantasia,
						// 			})
						// 		);
						// 		history.push('/estabelecimentos/configurar-split/' + estabelecimento.id);
						// 	},
						// },
						// {
						// 	title: 'Configurações',
						// 	visible: usuario.grupo_id <= 2 || usuario.grupo_id === 7,
						// 	onClick: () => {
						// 		dispatch(
						// 			setSelectedEC({
						// 				id: estabelecimento.id,
						// 				nome: estabelecimento.nome_fantasia,
						// 			})
						// 		);
						// 		history.push('/estabelecimentos/configuracoes/' + estabelecimento.id);
						// 	},
						// },
						// {
						// 	title: 'Visualizar Taxas',
						// 	visible: usuario.grupo_id <= 2 || usuario.grupo_id === 7,
						// 	onClick: () => {
						// 		dispatch(
						// 			setSelectedEC({
						// 				id: estabelecimento.id,
						// 				nome: estabelecimento.nome_fantasia,
						// 			})
						// 		);
						// 		history.push('/estabelecimentos/taxas/' + estabelecimento.id);
						// 	},
						// },
						{
							title: 'Habilitar',
							visible: usuario.grupo_id <= 2 || usuario.grupo_id === 7,
							onClick: () => {
								props.onHabilitarClick(estabelecimento.id);
							},
						},
					]}
				/>
			</TableCell>
		</TableRow>
	);
});

export default props => {
	const history = useHistory();
	const dispatch = useDispatch();
	const classes = useStyles();
	const token = useSelector(store => store.auth.token);
	const usuario = useSelector(store => store.auth.usuario);
	const estabelecimentoAdmin = useSelector(store => store.admin.estabelecimento);

	const { pathname } = useLocation();
	const pathnameSlug = pathname.toLowerCase().replace(/[^a-z0-9]/gi, '');

	const filtersCache = useSelector(store => store.filters[pathnameSlug] || {});

	const [estabelecimentos, setEstabelecimentos] = useState([]);
	const [habilitarEC, setHabilitarEC] = useState({
		ecId: null,
		open: false,
	});

	const [loading, setLoading] = useState(true);
	// const [page, setPage] = useState(1);
	// const [rowsPerPage] = useState(15);
	const [totalRows, setTotalRows] = useState(0);
	// const [hasMore, setHasMore] = useState(false);
	const [filtros, setFiltros] = useState({
		omni: '',
		desabilitados: true,
		parentId: Isset(() => estabelecimentoAdmin.id) ? estabelecimentoAdmin.id : null,
		...filtersCache,
	});

	const [showExportMessage, setShowExportMessage] = useState(false);
	const marketplacesFilhos = pathnameSlug === 'estabelecimentosmarketplacefilho' ? true : false;

	const exportXls = async () => {
		setShowExportMessage(true);
		const estabelecimentos = await axios.get(`${config.get('apiUrl')}export/estabelecimentos`, {
			headers: { Authorization: `Bearer ${token}` },
			responseType: 'blob',
			params: {
				marketplacesFilhos,
				filters: {
					...filtros,
				},
			},
		});

		if (estabelecimentos.status === 200) {
			const { 'content-disposition': contentDisposition, 'content-type': contentType } = estabelecimentos.headers;
			let filename = 'estabelecimentos.xlsx';
			if (contentDisposition.indexOf('filename="') !== -1) {
				filename = contentDisposition.slice(
					contentDisposition.indexOf('filename="') + 10,
					contentDisposition.length - 1
				);
			}

			FileDownload(estabelecimentos.data, filename, contentType);
		} else {
			dispatch(addMessage({ type: 'error', message: 'Erro ao exportar estabelecimentos.' }));
		}
		setShowExportMessage(false);
	};

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(100);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = event => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const getData = async () => {
		setLoading(true);
		// setPage(1);
		let res = await axios.get(`${config.get('apiUrl')}estabelecimentos/filhos`, {
			headers: { Authorization: `Bearer ${token}` },
			params: {
				page,
				limit: rowsPerPage,
				filters: {
					...filtros,
					desabilitados: true,
				},
			},
		});

		if (res.data.success) {
			setEstabelecimentos(res.data.estabelecimentos);
			setTotalRows(res.data.quantidade);
			// setHasMore(res.data.quantidade > res.data.estabelecimentos.length);
		}
		setLoading(false);
	};

	useEffect(() => {
		getData();
	}, [filtros, page, rowsPerPage]);

	useEffect(() => {
		setFiltros({ ...filtros, parentId: Isset(() => estabelecimentoAdmin.id) ? estabelecimentoAdmin.id : null });
	}, [estabelecimentoAdmin]);

	// const getMoreData = async () => {
	// 	// setPage(page + 1);
	// 	setLoading(true);
	// 	let res = await axios.get(`${config.get('apiUrl')}estabelecimentos/filhos`, {
	// 		headers: { Authorization: `Bearer ${token}` },
	// 		params: {
	// 			page: page,
	// 			limit: rowsPerPage,
	// 			filters: {
	// 				...filtros,
	// 			},
	// 		},
	// 	});
	// 	if (res.data.success) {
	// 		const newData = [...estabelecimentos, ...res.data.estabelecimentos];
	// 		setEstabelecimentos(newData);
	// 		// setHasMore(totalRows > newData.length);
	// 	}
	// 	setLoading(false);
	// };

	const filtrosChange = data => {
		setPage(0);
		setFiltros({
			...filtros,
			...data,
		});
	};

	const handleHabilitarEC = result => {
		const ecId = habilitarEC.ecId;
		setHabilitarEC({ ...habilitarEC, open: false, ecId: null });

		if (result) {
			axios
				.post(
					`${config.get('apiUrl')}estabelecimentos/${ecId}/habilitar`,
					{},
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then(res => res.data)
				.then(data => {
					if (data.success) {
						dispatch(addMessage({ type: 'success', message: 'Estabelecimento habilitado com sucesso' }));
						getData();
					}
				});
		}
	};

	return (
		<Grid>
			<ZTitle title="Estabelecimentos Desabilitados" />
			<Grid container>
				<Grid item xs={12}>
					<Button
						variant="contained"
						size="small"
						className={classes.btn}
						onClick={() => history.push('/estabelecimentos')}
					>
						Ver Todos
					</Button>
				</Grid>
				<Grid item xs={12}>
					<Paper
						style={{
							position: 'relative',
							marginBottom: 10,
							borderRadius: 4,
							padding: '5px 10px',
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<ZFiltros
							onChange={filtrosChange}
							input={[
								{
									key: 'omni',
									defaultValue: filtros.omni,
									modal: false,
									placeholder: 'Estabelecimento',
									md: 2,
								},
								{
									key: 'searchEstabelecimentoByCpfOrCNPJ',
									defaultValue: filtros.searchEstabelecimentoByCpfOrCNPJ || '',
									placeholder: 'CPF/CNPJ',
									mask: 'documento',
									modal: false,
									md: 2,
								},
								{
									key: 'nomeComprovante',
									defaultValue: filtros.nomeComprovante || '',
									placeholder: 'Nome do Comprovante',
									md: 2,
								},
							]}
						/>
						<Tooltip title="Exportar">
							<Button className={clsx(classes.buttonExport, classes.iconButton)} onClick={exportXls}>
								<FaDownload className={classes.exportDownload} />
							</Button>
						</Tooltip>
					</Paper>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12}>
					<Paper style={{ overflowX: 'auto', width: 'calc(100vw - 30px)' }}>
						{loading ? (
							<ZLoader height={200} message="Carregando Estabelecimentos" />
						) : (
							// <InfiniteScroll
							// 	dataLength={estabelecimentos.length}
							// 	next={getMoreData}
							// 	hasMore={hasMore}
							// 	loader={<h4 style={{ textAlign: 'center' }}>Carregando...</h4>}
							// >
							<>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>Nome</TableCell>
											<TableCell align="right">CPF / CNPJ</TableCell>
											<TableCell align="right">E-mail</TableCell>
											<TableCell align="right">Data Criação</TableCell>
											{[1, 7, 10].includes(usuario.grupo_id) && (
												<TableCell align="right">Representante</TableCell>
											)}
											<TableCell align="right">Status</TableCell>
											<TableCell align="right">Opções</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{estabelecimentos.map((value, key) => (
											<EstabelecimentoRow
												key={`estab-${key}`}
												estabelecimento={value}
												onHabilitarClick={ecId => {
													setHabilitarEC({
														...habilitarEC,
														ecId,
														open: true,
													});
												}}
											/>
										))}
									</TableBody>
								</Table>
								<TablePagination
									rowsPerPageOptions={[15, 30, 60, 100]}
									component="div"
									count={totalRows}
									labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
									labelRowsPerPage={'Resultados por página:'}
									rowsPerPage={rowsPerPage}
									page={page}
									onChangePage={handleChangePage}
									onChangeRowsPerPage={handleChangeRowsPerPage}
								/>
							</>
							// </InfiniteScroll>
						)}
					</Paper>
				</Grid>
			</Grid>

			<ZModalConfirm
				title={`Habilitar Estabelecimento`}
				message={`Deseja habilitar esse estabelecimento?`}
				open={habilitarEC.open}
				onClose={handleHabilitarEC}
			/>
		</Grid>
	);
};
