/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { Switch } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ListSubheader from '@material-ui/core/ListSubheader';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import { green } from '@material-ui/core/colors';
import CircularProgress from '@material-ui/core/CircularProgress';

import axios from 'axios';
import config from 'react-global-configuration';
import moment from 'moment';
import { addMessage } from '../../store/alert';

import { useSelector, useDispatch } from 'react-redux';
import { Isset } from '../../utils';
import clsx from 'clsx';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';

import ZSaveButton from '../system/ZSaveButton';
import ZText from '../system/ZText';
import ZSelect from '../system/ZSelect';
// import ZInputFile from '../system/ZInputFile';
import { Mask, ValidarCpf, ValidatorForm } from '../../utils/';

import { registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';

import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css';

registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginFilePoster);

const useStyles = makeStyles(theme => ({
	configHeader: {
		fontWeight: 700,
		fontSize: 16,
		margin: 4,
	},
	novaContaBancariaFields: {
		padding: 10,
	},
	box: {
		position: 'relative',
		padding: 10,
		borderTop: `solid 4px transparent`,
	},
	boxAtivo: {
		borderTop: `solid 4px ${theme.palette.primary.main}`,
	},
	ativo: {
		position: 'absolute',
		right: 10,
		top: 5,
		textTransform: 'uppercase',
		fontSize: 11,
		fontWeight: 'bold',
	},
	formControl: {
		width: '100%',
		marginTop: 4,
	},
	salvarBtn: {
		textAlign: 'center',
		textTransform: 'uppercase',
		margin: 8,
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
	},
	textField: {
		width: '100%',
		marginTop: 4,
	},
	tipoContaBancaria: {
		padding: 20,
		fontSize: 12,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontWeight: 700,
		color: theme.palette.primary.main,
		border: `solid 1px ${theme.palette.primary.main}`,
		borderRadius: 6,
		cursor: 'pointer',
		'&.selected': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.getContrastText(theme.palette.primary.main),
		},
		[theme.breakpoints.down('md')]: {
			padding: 10,
		},
	},
	icon: {
		color: theme.palette.primary.main,
	},
	updateCheck: {
		position: 'absolute',
		top: 10,
		right: 10,
	},
	field: {
		padding: 5,
	},
	label: {
		fontSize: 11,
		textTransform: 'uppercase',
		fontWeight: 700,
	},
	configUpdateBtn: {
		textAlign: 'center',
		textTransform: 'uppercase',
		paddingBottom: 15,
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
	},
	segundoStep: {
		border: '64px solid white',
		background: 'white',
		margin: 'auto',
		marginBottom: '32px',
		marginTop: '32px',
	},
	titulo: {
		display: 'flex',
		justifyContent: 'center',
		margin: '16px',
	},
	aprovar: {
		backgroundColor: 'green',
		color: 'white',
		'&:hover': {
			backgroundColor: green[700],
		},
	},
	buttonProgress: {
		color: green[500],
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
}));

const validateFields = {
	tipoEstabelecimentoId: {
		required: true,
	},
	nome: {
		required: true,
	},
	email: {
		required: true,
	},
	celular: {
		required: true,
	},
	dataNascimento: {
		required: true,
	},
	dataNascimento2: '',
	cpf: {
		required: true,
	},
	categoria: {
		required: true,
	},
	endereco: {
		logradouro: {
			required: true,
		},
		numero: {
			required: true,
		},
		cidade: {
			required: true,
		},
		estado: {
			required: true,
		},
		cep: {
			required: true,
		},
	},
};

const ValidateFieldsPessoaJuridica = {
	tipoEstabelecimentoId: {
		required: true,
	},
	razaoSocial: {
		required: true,
	},
	nomeFantasia: {
		required: true,
	},
	telefone: {
		required: true,
	},
	email: {
		required: true,
	},
	cnpj: {
		required: true,
	},
	categoria: {
		required: true,
	},
	endereco: {
		logradouro: {
			required: true,
		},
		numero: {
			required: true,
		},
		cidade: {
			required: true,
		},
		estado: {
			required: true,
		},
		cep: {
			required: true,
		},
	},
};

export default props => {
	const classes = useStyles();

	// const { history, match } = useReactRouter();
	const history = useHistory();
	const match = useRouteMatch();
	const dispatch = useDispatch();

	const token = useSelector(store => store.auth.token);
	const usuario = useSelector(store => store.auth.usuario);
	const estabelecimento = useSelector(store => store.auth.estabelecimento);
	const adminEstabelecimento = useSelector(state => state.admin.estabelecimento);

	const isEdmond = usuario.marketplace.id === 17;
	const isModerador = [1, 10].includes(usuario.grupo_id);

	// const [title, setTitle] = useState('Pré Cadastro de Estabelecimento');
	const [loading, setLoading] = useState(false);
	const [onError, setOnError] = useState(false);

	const [step, setStep] = useState(1);

	const [logoData, setLogoData] = useState([]);
	const [documentos, setDocumentos] = useState([]);
	const [documentosProprietario, setDocumentosProprietario] = useState([]);
	const [documentosAtividade, setDocumentosAtividade] = useState([]);
	const [documentosResidencia, setDocumentosResidencia] = useState([]);
	const [outrosDocumentos, setOutrosDocumentos] = useState([]);

	const [bancos, setBancos] = useState([]);
	const [errors, setErrors] = useState({});

	const [categorias, setCategorias] = useState([]);
	const [switchBoolean, setSwitch] = useState(false);

	const canInsertLogo = useSelector(store =>
		Isset(() => store.system.params['18'].valor) ? store.system.params['18'].valor : null
	);

	const [data, setData] = useState({
		tipoEstabelecimentoId: 1,
		identificadorPlano: '',
		nome: '',
		nomeComprovante: '',
		email: '',
		telefone: '',
		celular: '',
		dataNascimento: '',
		dataNascimento2: '',
		cpf: '',
		categoria: '',
		descricao_categoria: '',
		quantidade_pos: 0,
		faturamento_estimado: 0,
		observacao: '',
		endereco: {
			logradouro: '',
			numero: '',
			cidade: '',
			estado: '',
			cep: '',
			bairro: '',
			complemento: '',
		},
		enderecoPOS: {
			logradouro: '',
			numero: '',
			cidade: '',
			estado: '',
			cep: '',
			bairro: '',
			complemento: '',
		},
		proprietario: {
			nome: '',
			sobrenome: '',
			email: '',
			celular: '',
			dataNascimento: '',
			cpf: '',
			endereco: {
				logradouro: '',
				numero: '',
				cidade: '',
				estado: '',
				cep: '',
				complemento: '',
				bairro: '',
			},
		},
		contaBancaria: {
			tipoContaBancaria: 1,
			nomeTitular: '',
			bancoId: isEdmond && !isModerador ? '112' : '',
			agencia: '',
			conta: '',
		},
	});

	useEffect(() => {
		const getCategorias = async () => {
			const res = await axios.get(`${config.get('apiUrl')}estabelecimentos/mcc`, {
				headers: { Authorization: `Bearer ${token}` },
			});

			if (res.data.success && Isset(() => res.data.result)) {
				const cat = [];
				Object.keys(res.data.result).forEach(a => {
					const cats = res.data.result[a];
					cat.push({ id: null, description: a });
					cats.forEach(c => cat.push({ id: c.id, description: c.description }));
				});

				setCategorias(cat);
			} else {
				history.push('/error');
			}
		};

		const getBancos = async () => {
			const result = await axios.get(`${config.get('apiUrl')}bancos`);

			if (result.data.success) {
				setBancos(result.data.bancos);
			}
		};

		getBancos();

		getCategorias();
	}, []);

	useEffect(() => {
		if (match.params.estabelecimentoId && categorias.length > 0) {
			// setTitle('Analisar Pré Cadastro de Estabelecimento');

			axios
				.get(`${config.get('apiUrl')}estabelecimentos/pre_cadastro/${match.params.estabelecimentoId}`, {
					headers: { Authorization: `Bearer ${token}` },
				})
				.then(res => res.data)
				.then(dados => {
					if (dados.success) {
						const ec = dados.estabelecimento;
						const selectedCategory = categorias.find(categoria => +categoria.id === ec.mcc);
						const telefone = ec.pre_estabelecimentos_contatos.find(a => a.tipo_contato_id === 1);
						const celular = ec.pre_estabelecimentos_contatos.find(a => a.tipo_contato_id === 2);
						const email = ec.pre_estabelecimentos_contatos.find(a => a.tipo_contato_id === 3);

						const cpf = ec.pre_estabelecimentos_documentos.find(a => a.tipo_documento_id === 2);
						const cnpj = ec.pre_estabelecimentos_documentos.find(a => a.tipo_documento_id === 3);

						const { endereco, endereco_pos } = ec;
						const { endereco: enderecoProprietario } = ec.pre_estabelecimento_proprietario || {};

						let contaBancaria = {};
						if (Isset(() => ec.pre_estabelecimentos_contas_bancarias[0].conta_bancaria)) {
							contaBancaria = ec.pre_estabelecimentos_contas_bancarias[0].conta_bancaria;
						}

						setSwitch(ec.pos_endereco_id ? true : false);
						setData({
							...data,
							tipoEstabelecimentoId: ec.tipo_estabelecimento.id,
							nome: ec.nome_fantasia,
							nomeComprovante: ec.identificacao_fatura,
							razaoSocial: ec.razao_social,
							identificadorPlano: ec.identificador_plano,
							nomeFantasia: ec.nome_fantasia,
							email: Isset(() => email.contato) ? email.contato : '',
							telefone: Isset(() => telefone.contato) ? telefone.contato : '',
							celular: Isset(() => celular.contato) ? celular.contato : '',
							dataNascimento: ec.data_nascimento,
							dataNascimento2: moment(ec.data_nascimento).format('DD/MM/YYYY'),
							cpf: Isset(() => cpf.documento) ? cpf.documento : '',
							cnpj: Isset(() => cnpj.documento) ? cnpj.documento : '',
							quantidade_pos: ec.quantidade_pos || 0,
							observacao: ec.observacao || '',
							faturamento_estimado: ec.faturamento_estimado / 100,
							categoria: ec.mcc || '',
							descricao_categoria: selectedCategory.description || '',
							endereco: {
								logradouro: endereco ? endereco.logradouro : '',
								numero: endereco ? endereco.numero : '',
								cidade: endereco ? endereco.cidade : '',
								estado: endereco ? endereco.uf : '',
								cep: endereco ? endereco.cep : '',
								complemento: endereco ? endereco.complemento : '',
								bairro: endereco ? endereco.bairro : '',
							},
							enderecoPOS: {
								logradouro: endereco_pos !== null ? endereco_pos.logradouro : '',
								numero: endereco_pos !== null ? endereco_pos.numero : '',
								cidade: endereco_pos !== null ? endereco_pos.cidade : '',
								estado: endereco_pos !== null ? endereco_pos.uf : '',
								cep: endereco_pos !== null ? endereco_pos.cep : '',
								complemento: endereco_pos !== null ? endereco_pos.complemento || '' : '',
								bairro: endereco_pos !== null ? endereco_pos.bairro || '' : '',
							},
							proprietario: {
								...data.proprietario,
								...ec.pre_estabelecimento_proprietario,
								dataNascimento: Isset(() => ec.pre_estabelecimento_proprietario.data_nascimento)
									? moment(ec.pre_estabelecimento_proprietario.data_nascimento).format('DD/MM/YYYY')
									: '',
								endereco: {
									...data.proprietario.endereco,
									...enderecoProprietario,
									estado: Isset(() => enderecoProprietario.uf) ? enderecoProprietario.uf : '',
									bairro: Isset(() => enderecoProprietario.bairro) ? enderecoProprietario.bairro : '',
								},
							},
							contaBancaria: {
								tipoContaBancaria: contaBancaria.tipo_conta_bancaria_id || 1,
								nomeTitular: contaBancaria.nome_titular || '',
								bancoId: contaBancaria.banco_id || (isEdmond && !isModerador ? '112' : ''),
								agencia: contaBancaria.agencia || '',
								conta: contaBancaria.conta || '',
							},
							status_estabelecimento_id: ec.status_estabelecimento.id,
						});

						if (Isset(() => ec.arquivo.url)) {
							setLogoData(ec.arquivo.url);
						}

						const docs = ec.pre_estabelecimentos_documentos
							.filter(a => a.tipo_documento_id === 5 && Isset(() => a.arquivo.url))
							.map(a => ({
								source: a.arquivo.url,
								options: {
									type: 'local',
									file: {
										id: a.arquivo.id,
										name: a.arquivo.nome,
										size: a.arquivo.tamanho,
										type: a.arquivo.mimetype,
									},
									metadata: {
										poster: a.arquivo.url,
									},
								},
							}));
						const docsAtividade = ec.pre_estabelecimentos_documentos
							.filter(a => a.tipo_documento_id === 6 && Isset(() => a.arquivo.url))
							.map(a => ({
								source: a.arquivo.url,
								options: {
									type: 'local',
									file: {
										id: a.arquivo.id,
										name: a.arquivo.nome,
										size: a.arquivo.tamanho,
										type: a.arquivo.mimetype,
									},
									metadata: {
										poster: a.arquivo.url,
									},
								},
							}));
						const docsResidencia = ec.pre_estabelecimentos_documentos
							.filter(a => a.tipo_documento_id === 7 && Isset(() => a.arquivo.url))
							.map(a => ({
								source: a.arquivo.url,
								options: {
									type: 'local',
									file: {
										id: a.arquivo.id,
										name: a.arquivo.nome,
										size: a.arquivo.tamanho,
										type: a.arquivo.mimetype,
									},
									metadata: {
										poster: a.arquivo.url,
									},
								},
							}));
						const docsProprietario = ec.pre_estabelecimentos_documentos
							.filter(a => a.tipo_documento_id === 8 && Isset(() => a.arquivo.url))
							.map(a => ({
								source: a.arquivo.url,
								options: {
									type: 'local',
									file: {
										id: a.arquivo.id,
										name: a.arquivo.nome,
										size: a.arquivo.tamanho,
										type: a.arquivo.mimetype,
									},
									metadata: {
										poster: a.arquivo.url,
									},
								},
							}));
						const outrosDocs = ec.pre_estabelecimentos_documentos
							.filter(a => a.tipo_documento_id === 4 && Isset(() => a.arquivo.url))
							.map(a => ({
								source: a.arquivo.url,
								options: {
									type: 'local',
									file: {
										id: a.arquivo.id,
										name: a.arquivo.nome,
										size: a.arquivo.tamanho,
										type: a.arquivo.mimetype,
									},
									metadata: {
										poster: a.arquivo.url,
									},
								},
							}));

						setDocumentos(docs);
						setDocumentosAtividade(docsAtividade);
						setDocumentosResidencia(docsResidencia);
						setDocumentosProprietario(docsProprietario);
						setOutrosDocumentos(outrosDocs);
					} else {
						setErrors({
							estabelecimento: true,
						});
					}
				});
		}
	}, [match.params.estabelecimentoId, token, categorias]);

	// const validateContaBancaria = () => {
	// 	const e = {};

	// 	if (!data.contaBancaria.nomeTitular) {
	// 		e.nomeTitular = true;
	// 	}
	// 	if (!data.contaBancaria.bancoId) {
	// 		e.bancoId = true;
	// 	}
	// 	if (!data.contaBancaria.agencia) {
	// 		e.agencia = true;
	// 	}
	// 	if (!data.contaBancaria.conta) {
	// 		e.conta = true;
	// 	}

	// 	setErrors(e);

	// 	return Object.keys(e).length > 0;
	// };

	const handleSubmitForm = async (status = 0) => {
		try {
			// setErrors([]);
			setLoading(true);
			try {
				if (+data.tipoEstabelecimentoId === 1) {
					ValidatorForm(validateFields, data);
				} else {
					ValidatorForm(ValidateFieldsPessoaJuridica, data);
				}
			} catch (erro) {
				window.scrollTo(0, 0);
				dispatch(addMessage({ type: 'error', message: 'Existem campos inválidos no formulário' }));
				setLoading(false);
				setOnError(true);
				return false;
			}

			data.estabelecimentoId = Isset(() => adminEstabelecimento.id)
				? adminEstabelecimento.id
				: estabelecimento.id;

			var formData = new FormData();

			for (let key in data) {
				if (typeof data[key] === 'object') {
					for (let key2 in data[key]) {
						if (typeof data[key][key2] === 'object') {
							for (let key3 in data[key][key2]) {
								formData.append(`${key}[${key2}][${key3}]`, data[key][key2][key3]);
							}
						} else {
							formData.append(`${key}[${key2}]`, data[key][key2]);
						}
					}
				} else {
					formData.append(key, data[key]);
				}
			}

			const logo = !Array.isArray(logoData) ? [logoData] : logoData;
			const documentosFiles = !Array.isArray(documentos) ? [documentos] : documentos;
			const documentosProprietarioFiles = !Array.isArray(documentosProprietario)
				? [documentosProprietario]
				: documentosProprietario;
			const documentosAtividadeFiles = !Array.isArray(documentosAtividade)
				? [documentosAtividade]
				: documentosAtividade;
			const documentosResidenciaFiles = !Array.isArray(documentosResidencia)
				? [documentosResidencia]
				: documentosResidencia;
			const outrosDocumentosFiles = !Array.isArray(outrosDocumentos) ? [outrosDocumentos] : outrosDocumentos;

			if (canInsertLogo) {
				logo.forEach(a => {
					if (!a.type.match('image.*')) {
						return;
					}
					formData.append('logo', a, a.name);
				});
			}

			const addFile = (name, file, fd) => {
				if (!(file instanceof Blob)) {
					fd.append(`documentosExistentes[]`, file.id);
					return;
				}
				if (!file.type.match('image.*') && !file.type.match('application/pdf')) {
					return;
				}
				fd.append(`${name}[]`, file, file.name);
			};

			documentosFiles.forEach(a => addFile('documentos', a, formData));
			documentosProprietarioFiles.forEach(a => addFile('documentosProprietario', a, formData));
			documentosAtividadeFiles.forEach(a => addFile('documentosAtividade', a, formData));
			documentosResidenciaFiles.forEach(a => addFile('documentosResidencia', a, formData));
			outrosDocumentosFiles.forEach(a => addFile('outrosDocumentos', a, formData));

			if (!Isset(() => match.params.estabelecimentoId) || match.params.estabelecimentoId === null) {
				// if (validateContaBancaria()) {
				// 	setLoading(false);
				// 	return false;
				// }
				const res = await axios.post(`${config.get('apiUrl')}estabelecimentos/pre-cadastrar`, formData, {
					headers: { Authorization: `Bearer ${token}` },
				});

				setLoading(false);
				if (!res.data.success) {
					dispatch(addMessage({ type: 'error', message: res.data.error }));
				} else {
					dispatch(addMessage({ type: 'success', message: res.data.message }));
					history.push('/estabelecimentos');
				}
			} else {
				formData.append('status', status);
				let res = false;
				if (data.status_estabelecimento_id === 5) {
					formData.append('old_id', match.params.estabelecimentoId);
					res = await axios.post(`${config.get('apiUrl')}estabelecimentos/pre-cadastrar/`, formData, {
						headers: { Authorization: `Bearer ${token}` },
					});
				} else {
					res = await axios.put(
						`${config.get('apiUrl')}estabelecimentos/alterar_status_pre_cadastro/${
							match.params.estabelecimentoId
						}`,
						formData,
						{
							headers: { Authorization: `Bearer ${token}` },
						}
					);
				}

				setLoading(false);
				if (!res.data.success) {
					dispatch(addMessage({ type: 'error', message: res.data.error }));
				} else {
					dispatch(addMessage({ type: 'success', message: res.data.message }));
					history.push('/estabelecimentos/listar-pre-cadastro');
				}
			}
		} catch (error) {
			console.log(error.message);
			setLoading(false);
			// await axios.post(
			// 	`${config.get('apiUrl')}sendReport`,
			// 	{
			// 		title: 'Error - Pré Cadastrar Estabelecimento',
			// 		error: JSON.stringify(error),
			// 	},
			// 	{
			// 		headers: { Authorization: `Bearer ${token}` },
			// 	}
			// );
		}
	};

	const handleTipoEstabelecimento = name => event => {
		setData({
			...data,
			tipoEstabelecimentoId: +event.target.value,
		});
	};

	const handleChange = name => event => {
		if (name === 'cpf') {
			if (!ValidarCpf(event.target.value)) {
				// setErrors(['CPF inválido']);
				dispatch(addMessage({ type: 'error', message: 'CPF inválido' }));
				setData({
					...data,
					cpf: '',
				});
				return false;
			}
		}

		var value = event.target.value;
		let onlyNumber = ['celular', 'telefone', 'cnpj', 'cpf', 'celular'];

		if (onlyNumber.indexOf(name) !== -1) value = Mask.OnlyNumber(value);

		setData({
			...data,
			[name]: value,
		});
	};

	const handleDataNascimento = name => event => {
		if (name === 'dataNascimento2') {
			setData({
				...data,
				[name]: event.target.value,
				dataNascimento: moment(event.target.value, 'DD/MM/YYYY').format('YYYY-MM-DD'),
			});
		}
	};

	const handleProprietario = name => event => {
		setData({
			...data,
			proprietario: {
				...data.proprietario,
				[name]: event.target.value,
			},
		});
	};

	const getCep = async cep => {
		var res = await axios.get(config.get('apiUrl') + 'buscar_cep/' + cep);
		return res.data.endereco;
	};

	const handleChangeEndereco = name => async event => {
		var value = event.target.value;
		if (name === 'cep') {
			value = Mask.OnlyNumber(value);
		}

		if (name === 'cep' && event.target.value.length === 9) {
			var res = await axios.get(config.get('apiUrl') + 'buscar_cep/' + event.target.value);

			if (res.data.success === false || res.data.endereco.erro) {
				setData({
					...data,
					endereco: {
						...data.endereco,
						cep: value,
						logradouro: '',
						cidade: '',
						estado: '',
						complemento: '',
					},
				});
				// setErrors([ 'CEP não encontrado' ]);
				dispatch(addMessage({ type: 'error', message: 'CEP não encontrado' }));
				return false;
			}

			setData({
				...data,
				endereco: {
					...data.endereco,
					cep: value,
					logradouro: res.data.endereco.logradouro,
					cidade: res.data.endereco.localidade,
					estado: res.data.endereco.uf,
					complemento: '',
					bairro: res.data.endereco.bairro,
				},
			});
		} else {
			setData({
				...data,
				endereco: {
					...data.endereco,
					[name]: value,
				},
			});
		}
	};

	const handleChangeProprietarioEndereco = name => async event => {
		var value = event.target.value;
		if (name === 'cep') {
			value = Mask.OnlyNumber(value);
		}

		if (name === 'cep' && event.target.value.length === 9) {
			var res = await axios.get(config.get('apiUrl') + 'buscar_cep/' + event.target.value);

			if (res.data.success === false || res.data.endereco.erro) {
				setData({
					...data,
					proprietario: {
						...data.proprietario,
						endereco: {
							...data.proprietario.endereco,
							cep: value,
							logradouro: '',
							cidade: '',
							estado: '',
							complemento: '',
						},
					},
				});
				// setErrors([ 'CEP não encontrado' ]);
				dispatch(addMessage({ type: 'error', message: 'CEP não encontrado' }));
				return false;
			}

			setData({
				...data,
				proprietario: {
					...data.proprietario,
					endereco: {
						...data.proprietario.endereco,
						cep: value,
						logradouro: res.data.endereco.logradouro,
						cidade: res.data.endereco.localidade,
						estado: res.data.endereco.uf,
						complemento: '',
						bairro: res.data.endereco.bairro,
					},
				},
			});
		} else {
			setData({
				...data,
				proprietario: {
					...data.proprietario,
					endereco: {
						...data.proprietario.endereco,
						[name]: value,
					},
				},
			});
		}
	};
	if (errors.estabelecimento) {
		return (
			<Grid>
				{/* <ZTitle title={'Estabelecimento não encontrado'} /> */}
				<Grid container>
					<Grid item xs={12} align="center">
						<h2> Estabelecimento não encontrado. </h2>
						<Link to="/estabelecimentos"> Clique aqui para voltar a listagem de estabelecimentos.</Link>
					</Grid>
				</Grid>
			</Grid>
		);
	}

	return (
		<Grid>
			<Grid className={classes.conteudo} container spacing={1} display="flex" direction="column">
				<Grid item>
					{[5, 8].includes(usuario.grupo_id) ? (
						<Typography className={classes.titulo} variant="h4">
							Pré Cadastro de Estabelecimento
						</Typography>
					) : (
						<Typography className={classes.titulo} variant="h4">
							Analisar Pré Cadastro de Estabelecimento
						</Typography>
					)}
				</Grid>
				<Grid container spacing={1}>
					<Grid item xs={10} md={6} className={classes.segundoStep}>
						{step === 1 && (
							<>
								<Grid item xs={12} style={{ marginBottom: 16 }}>
									<Typography variant="h6">Informações do estabelecimento.</Typography>
								</Grid>
								<Grid container spacing={4}>
									<Grid item xs={12} md={6}>
										<ZSelect
											options={[
												{
													id: 1,
													name: 'Pessoa Física',
												},
												{
													id: 2,
													name: 'Pessoa Jurídica',
												},
											]}
											label="Tipo"
											required={true}
											value={data.tipoEstabelecimentoId}
											onChange={handleTipoEstabelecimento('tipoEstabelecimentoId')}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<FormControl style={{ marginTop: 15, width: '100%' }}>
											<InputLabel>Categoria</InputLabel>
											<Select
												value={categorias.length > 0 ? data.categoria : ''}
												onChange={e => {
													const selectedCategory = categorias.find(
														categoria => categoria.id === e.target.value
													);
													setData({
														...data,
														categoria: e.target.value,
														descricao_categoria: selectedCategory
															? selectedCategory.description
															: '',
													});
												}}
												required={true}
											>
												<MenuItem value="">
													<em>Categoria</em>
												</MenuItem>
												{categorias.map((categoria, i) => {
													if (!categoria.id) {
														return (
															<ListSubheader key={i}>
																{categoria.description}
															</ListSubheader>
														);
													}

													return (
														<MenuItem key={i} value={categoria.id}>
															{categoria.description}
														</MenuItem>
													);
												})}
											</Select>
											{onError && !data.categoria && (
												<div style={{ color: 'red' }}>O campo não pode ser vazio.</div>
											)}
										</FormControl>
									</Grid>

									{data.tipoEstabelecimentoId === 1 ? (
										<>
											<Grid item xs={12} md={6}>
												<ZText
													onError={onError}
													required={true}
													value={data.nome}
													onBlur={handleChange('nome')}
													label="Nome Completo"
												/>
											</Grid>
											<Grid item xs={12} md={6}>
												<ZText
													label="Data Nascimento"
													onError={onError}
													required={true}
													value={data.dataNascimento2}
													mask="99/99/9999"
													onBlur={handleDataNascimento('dataNascimento2')}
												/>
											</Grid>
										</>
									) : (
										<>
											<Grid item xs={12} md={6}>
												<ZText
													onError={onError}
													required={true}
													value={data.razaoSocial}
													onBlur={handleChange('razaoSocial')}
													label="Razão Social"
												/>
											</Grid>
											<Grid item xs={12} md={6}>
												<ZText
													label="Nome Fantasia"
													onError={onError}
													required={true}
													value={data.nomeFantasia}
													onBlur={handleChange('nomeFantasia')}
												/>
											</Grid>
										</>
									)}
									<Grid item xs={12} md={6}>
										<ZText
											label="E-mail"
											onError={onError}
											required={true}
											type="email"
											email={true}
											value={data.email}
											onBlur={handleChange('email')}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										{data.tipoEstabelecimentoId === 1 ? (
											<>
												<ZText
													onError={onError}
													required={true}
													mask="999.999.999-99"
													value={data.cpf}
													onBlur={handleChange('cpf')}
													label="CPF"
												/>
											</>
										) : (
											<>
												<ZText
													onError={onError}
													required={true}
													mask="99.999.999/9999-99"
													value={data.cnpj}
													onBlur={handleChange('cnpj')}
													label="CNPJ"
												/>
											</>
										)}
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											label="Celular"
											onError={onError}
											required={true}
											value={data.celular}
											mask="(99) 9 9999-9999"
											onBlur={handleChange('celular')}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											onError={onError}
											required={true}
											value={data.nomeComprovante}
											onBlur={handleChange('nomeComprovante')}
											label="Nome Comprovante"
										/>
									</Grid>
									{data.tipoEstabelecimentoId === 2 && (
										<>
											<Grid item xs={12} md={6}>
												<ZText
													label="Telefone"
													onError={onError}
													required={true}
													value={data.telefone}
													mask="(99) 9999-9999"
													onBlur={handleChange('telefone')}
												/>
											</Grid>
										</>
									)}
									<Grid item xs={12} md={6}>
										<ZText
											label={
												match.params.estabelecimentoId
													? 'Plano inicial'
													: 'Identificador do plano'
											}
											onError={onError}
											disabled={match.params.estabelecimentoId ? true : false}
											required={false}
											value={data.identificadorPlano || ''}
											onBlur={handleChange('identificadorPlano')}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											label={'Quantidade de POS'}
											// onError={onError}
											// disabled={match.params.estabelecimentoId ? true : false}
											// required={false}
											value={data.quantidade_pos}
											onBlur={e => {
												setData({
													...data,
													quantidade_pos: Mask.OnlyNumber(e.target.value),
												});
											}}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											label={'Faturamento estimado'}
											// onError={onError}
											// disabled={match.params.estabelecimentoId ? true : false}
											// required={false}
											value={Mask.Real(data.faturamento_estimado || 0)}
											onChange={value =>
												setData({
													...data,
													faturamento_estimado: Mask.OnlyNumber(value) / 100,
												})
											}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											label={'Observações'}
											type={'textarea'}
											// onError={onError}
											// disabled={match.params.estabelecimentoId ? true : false}
											// required={false}
											value={data.observacao}
											onBlur={e => {
												setData({ ...data, observacao: e.target.value });
											}}
										/>
									</Grid>
								</Grid>
							</>
						)}
						{step === 2 && (
							<>
								<Typography variant="h6">Preencha seu endereço.</Typography>
								<Grid container spacing={4}>
									<Grid item xs={12} md={6}>
										<ZText
											label="CEP"
											onError={onError}
											required={true}
											mask="99999-999"
											value={data.endereco.cep}
											onBlur={handleChangeEndereco('cep')}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											label="Endereço"
											onError={onError}
											required={true}
											value={data.endereco.logradouro}
											onBlur={handleChangeEndereco('logradouro')}
										/>
									</Grid>
									<Grid item xs={12} md={3}>
										<ZText
											label="Número"
											onError={onError}
											required={true}
											value={data.endereco.numero}
											onBlur={handleChangeEndereco('numero')}
										/>
									</Grid>
									<Grid item xs={12} md={9}>
										<ZText
											onError={onError}
											value={data.endereco.complemento}
											onBlur={handleChangeEndereco('complemento')}
											label="Complemento"
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											onError={onError}
											required={true}
											value={data.endereco.cidade}
											onBlur={handleChangeEndereco('cidade')}
											label="Cidade"
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											onError={onError}
											required={true}
											value={data.endereco.estado}
											onBlur={handleChangeEndereco('estado')}
											label="UF"
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											onError={onError}
											required={true}
											value={data.endereco.bairro}
											onBlur={handleChangeEndereco('bairro')}
											label="Bairro"
										/>
									</Grid>
								</Grid>
							</>
						)}
						{step === 3 && (
							<>
								<Typography variant="h6">Preencha os dados do proprietário.</Typography>
								<Grid container spacing={4}>
									<Grid item xs={12} md={6}>
										<ZText
											label="Nome"
											onError={onError}
											required={true}
											value={data.proprietario.nome}
											onBlur={handleProprietario('nome')}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											label="Sobrenome"
											onError={onError}
											required={true}
											value={data.proprietario.sobrenome}
											onBlur={handleProprietario('sobrenome')}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											label="E-mail"
											onError={onError}
											required={true}
											type="email"
											email={true}
											value={data.proprietario.email}
											onBlur={handleProprietario('email')}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											label="Data Nascimento"
											onError={onError}
											required={true}
											value={data.proprietario.dataNascimento}
											mask="99/99/9999"
											onBlur={handleProprietario('dataNascimento')}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											label="Celular"
											onError={onError}
											required={true}
											mask="(99) 9 9999-9999"
											value={data.proprietario.celular}
											onBlur={handleProprietario('celular')}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											mask="999.999.999-99"
											label="CPF"
											onError={onError}
											required={true}
											value={data.proprietario.cpf}
											onBlur={handleProprietario('cpf')}
										/>
									</Grid>
								</Grid>
							</>
						)}
						{step === 4 && (
							<>
								<Typography variant="h6">Preencha os dados residencias do proprietário.</Typography>
								<Grid container spacing={4}>
									<Grid item xs={12} md={6}>
										<ZText
											label="CEP"
											onError={onError}
											required={true}
											mask="99999-999"
											value={data.proprietario.endereco.cep}
											onBlur={handleChangeProprietarioEndereco('cep')}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											label="Endereço"
											onError={onError}
											required={true}
											value={data.proprietario.endereco.logradouro}
											onBlur={handleChangeProprietarioEndereco('logradouro')}
										/>
									</Grid>
									<Grid item xs={12} md={3}>
										<ZText
											label="Número"
											onError={onError}
											required={true}
											value={data.proprietario.endereco.numero}
											onBlur={handleChangeProprietarioEndereco('numero')}
										/>
									</Grid>
									<Grid item xs={12} md={9}>
										<ZText
											onError={onError}
											value={data.proprietario.endereco.complemento}
											onBlur={handleChangeProprietarioEndereco('complemento')}
											label="Complemento"
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											onError={onError}
											required={true}
											value={data.proprietario.endereco.cidade}
											onBlur={handleChangeProprietarioEndereco('cidade')}
											label="Cidade"
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											onError={onError}
											required={true}
											value={data.proprietario.endereco.estado}
											onBlur={handleChangeProprietarioEndereco('estado')}
											label="UF"
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											onError={onError}
											required={true}
											value={data.proprietario.endereco.bairro}
											onBlur={handleChangeProprietarioEndereco('bairro')}
											label="Bairro"
										/>
									</Grid>
								</Grid>
							</>
						)}
						{step === 5 && (
							<>
								<Typography variant="h6" gutterBottom>
									Entregar POS em um endereço diferente do cadastro?{' '}
									<Switch
										checked={switchBoolean}
										onChange={() => setSwitch(!switchBoolean)}
										inputProps={{ 'aria-label': 'primary checkbox' }}
									/>
								</Typography>
								{switchBoolean && (
									<>
										<Grid container spacing={4}>
											<Grid item xs={12} md={6}>
												<ZText
													label="CEP"
													onError={onError}
													required={true}
													mask="99999-999"
													value={data.enderecoPOS.cep}
													onBlur={async e => {
														let dataCep = await getCep(e.target.value);
														setData({
															...data,
															enderecoPOS: {
																...data.enderecoPOS,
																cep: Mask.OnlyNumber(dataCep.cep || ''),
																logradouro: dataCep.logradouro,
																bairro: dataCep.bairro,
																cidade: dataCep.localidade,
																estado: dataCep.uf,
															},
														});
													}}
												/>
											</Grid>
											<Grid item xs={12} md={6}>
												<ZText
													label="Número"
													onError={onError}
													required={true}
													value={data.enderecoPOS.numero}
													onBlur={e =>
														setData({
															...data,
															enderecoPOS: {
																...data.enderecoPOS,
																numero: e.target.value,
															},
														})
													}
												/>
											</Grid>
											<Grid item xs={12} md={6}>
												<ZText
													label="Logradouro"
													value={data.enderecoPOS.logradouro}
													onBlur={e =>
														setData({
															...data,
															enderecoPOS: {
																...data.enderecoPOS,
																logradouro: e.target.value,
															},
														})
													}
												/>
											</Grid>
											<Grid item xs={12} md={6}>
												<ZText
													label="Bairro"
													value={data.enderecoPOS.bairro}
													onBlur={e =>
														setData({
															...data,
															enderecoPOS: {
																...data.enderecoPOS,
																bairro: e.target.value,
															},
														})
													}
												/>
											</Grid>
											<Grid item xs={12} md={6}>
												<ZText
													label="Cidade"
													value={data.enderecoPOS.cidade}
													onBlur={e =>
														setData({
															...data,
															enderecoPOS: {
																...data.enderecoPOS,
																cidade: e.target.value,
															},
														})
													}
												/>
											</Grid>
											<Grid item xs={12} md={6}>
												<ZText
													label="Estado"
													value={data.enderecoPOS.estado}
													onBlur={e =>
														setData({
															...data,
															enderecoPOS: {
																...data.enderecoPOS,
																estado: e.target.value,
															},
														})
													}
												/>
											</Grid>
											<Grid item xs={12} md={6}>
												<ZText
													label="Complemento"
													// onError={onError}
													value={data.enderecoPOS.complemento}
													onBlur={e =>
														setData({
															...data,
															enderecoPOS: {
																...data.enderecoPOS,
																complemento: e.target.value,
															},
														})
													}
												/>
											</Grid>
											<Grid item xs={6}></Grid>
										</Grid>
									</>
								)}
							</>
						)}
						{step === 6 && (
							<>
								<Typography variant="h6">Preencha seus dados bancários.</Typography>
								<Grid container spacing={4}>
									<Grid container flex spacing={4} item xs={12}>
										<Grid item xs={6}>
											<div
												className={clsx(classes.tipoContaBancaria, {
													selected: data.contaBancaria.tipoContaBancaria === 1,
												})}
												onClick={() =>
													setData({
														...data,
														contaBancaria: {
															...data.contaBancaria,
															tipoContaBancaria: 1,
														},
													})
												}
											>
												Conta Corrente
											</div>
										</Grid>
										<Grid item xs={6}>
											<div
												className={clsx(classes.tipoContaBancaria, {
													selected: data.contaBancaria.tipoContaBancaria === 2,
												})}
												onClick={() =>
													setData({
														...data,
														contaBancaria: {
															...data.contaBancaria,
															tipoContaBancaria: 2,
														},
													})
												}
											>
												Conta Poupança
											</div>
										</Grid>
									</Grid>
									<Grid item xs={12} md={6}>
										<TextField
											label="Nome"
											className={classes.textField}
											value={data.contaBancaria.nomeTitular}
											onChange={e =>
												setData({
													...data,
													contaBancaria: {
														...data.contaBancaria,
														nomeTitular: e.target.value,
													},
												})
											}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<TextField
											label={data.tipoEstabelecimentoId === 2 ? `CNPJ` : `CPF`}
											disabled={true}
											className={classes.textField}
											value={
												data.tipoEstabelecimentoId === 2
													? Mask.Cnpj(data.cnpj || '')
													: Mask.Cpf(data.cpf || '')
											}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<FormControl
											//error={errors.bancoId || false}
											className={classes.formControl}
										>
											<InputLabel htmlFor="banco">Banco</InputLabel>
											<Select
												value={data.contaBancaria.bancoId}
												className={classes.textField}
												onChange={e =>
													setData({
														...data,
														contaBancaria: {
															...data.contaBancaria,
															bancoId: e.target.value,
														},
													})
												}
												inputProps={{ id: 'bancoId' }}
											>
												{bancos.map((b, i) => (
													<MenuItem key={i} value={b.id}>
														{b.nome}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={12} md={6}>
										<TextField
											label="Agência"
											className={classes.textField}
											value={data.contaBancaria.agencia}
											onChange={e =>
												setData({
													...data,
													contaBancaria: {
														...data.contaBancaria,
														agencia: e.target.value,
													},
												})
											}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<TextField
											error={errors.conta || false}
											className={classes.textField}
											label="Conta"
											value={data.contaBancaria.conta}
											onChange={e =>
												setData({
													...data,
													contaBancaria: {
														...data.contaBancaria,
														conta: e.target.value,
													},
												})
											}
										/>
									</Grid>
								</Grid>
							</>
						)}
						<Grid item style={{ marginTop: '16px' }} xs={12}>
							<Grid container spacing={4}>
								<Grid item xs={12} md={6}>
									{step !== 1 && step !== 6 ? (
										<Button
											fullWidth
											size="large"
											className={classes.botao}
											onClick={
												data.tipoEstabelecimentoId === 1 && step === 5
													? () => setStep(step - 3)
													: () => setStep(step - 1)
											}
											variant="contained"
										>
											Voltar
										</Button>
									) : (
										<>
											{step === 6 && (
												<>
													<ZSaveButton
														onClick={() => handleSubmitForm(1)}
														color={'secondary'}
														align="right"
														label={'Reprovar'}
														required={true}
														loading={loading}
														style={{ marginRight: 10, backgroundColor: 'red' }}
													/>
												</>
											)}
										</>
									)}
								</Grid>
								{step === 6 && (
									<>
										<Grid item xs={12} md={6}>
											<Button
												onClick={() => handleSubmitForm(2)}
												fullWidth
												size="large"
												className={classes.aprovar}
												disabled={loading}
												variant="contained"
											>
												{loading ? (
													<CircularProgress size={24} className={classes.buttonProgress} />
												) : (
													'Aprovar'
												)}
											</Button>
										</Grid>
									</>
								)}
								<Grid item xs={12} md={6}>
									{step === 6 || (step === 6 && match.params.estabelecimentoId) ? (
										<></>
									) : (
										<Button
											fullWidth
											size="large"
											className={classes.botao}
											onClick={
												data.tipoEstabelecimentoId === 1 && step === 2
													? () => setStep(step + 3)
													: () => setStep(step + 1)
											}
											variant="contained"
										>
											Continuar
										</Button>
									)}
								</Grid>
								<Grid item xs={12}>
									{step === 6 && (
										<Button
											fullWidth
											size="large"
											className={classes.botao}
											onClick={
												data.tipoEstabelecimentoId === 1 && step === 5
													? () => setStep(step - 3)
													: () => setStep(step - 1)
											}
											variant="contained"
										>
											Voltar
										</Button>
									)}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
