/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect } from 'react';
import { Grid, Typography, Container, Paper, Button, SvgIcon, CircularProgress, TextField } from '@material-ui/core';
import ZCard from '../system/ZCard';
import ZText from '../system/ZText';
import ZAlert from '../system/ZAlert';
import ZLoader from '../system/ZLoader';
import { Mask, ValidatorForm, Isset, ValidarCpf, ValidarCnpj, fallbackCopyTextToClipboard, wait } from '../../utils';
import { useSelector, useDispatch } from 'react-redux';
import { addMessage } from '../../store/alert';
import { makeStyles } from '@material-ui/core/styles';
import { FiShoppingBag, FiAlertTriangle } from 'react-icons/fi';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import config from 'react-global-configuration';
import axios from 'axios';
import moment from 'moment';
import { FaBarcode, FaRegCreditCard, FaDownload, FaCopy } from 'react-icons/fa';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import Countdown from 'react-countdown';
import saveSvgAsPng from 'save-svg-as-png';
import QRCode from 'qrcode.react';
//import das bandeiras
import mastercard from '../../assets/flags/mastercard.png';
import american from '../../assets/flags/american.png';
import elo from '../../assets/flags/elo-logo.png';
import visa from '../../assets/flags/visa-logo.png';
import diners from '../../assets/flags/dinners.png';
import hiper from '../../assets/flags/hiper-logo.png';
import hipercard from '../../assets/flags/hipercard-logo.png';
import banescard from '../../assets/flags/banescard-logo.png';

import { Checkbox } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { subHours } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

// const TooltipTitle = props => {
// 	return (
// 		<div>
// 			<div>{props.type}</div>
// 			<div>{props.brand}</div>
// 		</div>
// 	);
// };

const getBandeira = bandeira => {
	switch (bandeira) {
		case 'american express':
			return 'American Express';
		case 'elo':
			return 'Elo';
		case 'maestro':
			return 'Maestro';
		case 'mastercard':
			return 'Mastercard';
		case 'visa':
			return 'Visa';
		case 'visa electron':
			return 'Visa Electron';
		case 'diners club':
			return 'Diners Club';
		case 'hiper':
			return 'Hiper';
		case 'hipercard':
			return 'HiperCard';
		case 'banescard':
			return 'Banescard';
		default:
			return bandeira;
	}
};

const getPaymentIcon = brand => {
	if (brand) {
		brand = getBandeira(brand);
	}
	switch (brand) {
		case 'Mastercard':
			return <img alt="" height={32} src={mastercard} />;
		case 'Visa':
			return <img alt="" height={32} src={visa} />;
		case 'American Express':
			return <img alt="" height={32} src={american} />;
		case 'Elo':
			return <img alt="" height={32} src={elo} />;
		case 'Diners Club':
			return <img alt="" height={32} src={diners} />;
		case 'HiperCard':
			return <img alt="" height={32} src={hipercard} />;
		case 'Hiper':
			return <img alt="" height={32} src={hiper} />;
		case 'Banescard':
			return <img alt="" height={32} src={banescard} />;
		default:
			return <FaRegCreditCard size="32px" />;
	}
};

const useStyles = makeStyles(theme => ({
	buttonReenviar: {
		backgroundColor: '#2a9df4',
		color: '#fff',
		padding: '4px 8px',
		'&:hover': {
			backgroundColor: '#187bcd',
		},
	},
	reenviar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	wrapper: {
		position: 'relative',
		display: 'block',
		width: '100%',
		'& .MuiInputLabel-root': {
			paddingRight: theme.spacing(4),
		},
	},
	whatsappIcon: {
		position: 'absolute',
		width: 20,
		height: 20,
		transform: 'translateY(-50%)',
		// Estilo para telas pequenas
		top: '50%',
		right: theme.spacing(2),
		// Estilo unificado para telas médias e grandes
		[theme.breakpoints.up('md')]: {
			top: '31%',
			right: theme.spacing(12),
		},
	},
	paper: {
		borderRadius: 5,
		padding: 32,
		// marginTop: 25,
		marginBottom: 16,
	},
	containerPaper: {
		margin: '32px auto',
		[theme.breakpoints.down('xs')]: {
			margin: '16px 0',
		},
	},
	error: {
		backgroundColor: '#c53030',
	},
	warning: {
		backgroundColor: '#ef6c00',
	},
	success: {
		backgroundColor: '#50c878',
	},
	container: {
		width: '100%',
		minHeight: '50vh',
		display: 'flex',

		'& .container__icon': {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			marginTop: '64px',

			position: 'relative',
			backgroundColor: '#FFF',
			borderRadius: '50%',
			width: '100px',
			height: '100px',
		},

		'& .container__icon-check': {
			position: 'absolute',
			top: '66px',
			left: '79px',
		},

		'& .paper': {
			borderRadius: 5,
			padding: 32,
			position: 'absolute',
			// top: '55%',

			'& .paper__content-title': {
				color: '#424242',
				fontSize: '18px',
				fontWeight: 'bold',
				margin: 0,
			},

			'& .paper__content-text': {
				color: '#757575',
				fontSize: '14px',
				lineHeight: '24px',
				margin: 0,
			},
		},
	},
	button: {
		backgroundColor: ({ colorButton }) => colorButton,
		color: '#fff',
		padding: '12px 8px',
		'&:hover': {
			backgroundColor: ({ colorButton }) => colorButton,
		},
	},
	backButton: {
		backgroundColor: 'transparent',
		color: '#777',
		border: ({ colorButton }) => `1px solid ${colorButton}`,
		padding: '12px 8px',
		'&:hover': {
			color: '#fff',
			backgroundColor: ({ colorButton }) => colorButton,
		},
	},
	title: {
		[theme.breakpoints.down('xs')]: {
			textAlign: 'center',
		},
	},
	label: {
		[theme.breakpoints.down('xs')]: {
			marginTop: 32,
		},
	},
	description: {
		[theme.breakpoints.down('xs')]: {
			textAlign: 'center',
		},
	},
	hideMobile: {
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
	},
	displayMobile: {
		height: 'full',
		minHeight: '100vh',
		display: 'flex',
		alignItems: 'center',
		// [theme.breakpoints.down('xs')]: {
		// 	flexDirection: 'column-reverse',
		// },
	},
	hideLogo: {
		// [theme.breakpoints.down('xs')]: {
		// 	display: 'none',
		// },
	},
	errorText: {
		color: '#ff0033',
		fontSize: 14,
	},
	qrcodeHover: {
		transition: theme.transitions.create(['all'], {
			duration: theme.transitions.duration.complex,
		}),
		opacity: 0,
		position: 'absolute',
		height: 256,
		width: 256,
		background: '#fafafa',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		zIndex: 100,
		top: 0,
		left: 0,
		'&:hover': {
			opacity: 0.7,
		},
	},
	hoverPixCopiaCola: {
		textAlign: 'center',
		padding: 10,
		border: 'solid 1px white',
		borderRadius: 10,
		cursor: 'pointer',
		'&:hover': {
			border: 'dashed 1px black',
		},
	},
}));
// eslint-disable-next-line
const getBandeiraId = bandeira => {
	switch (`${bandeira}`.toLowerCase()) {
		case 'visa':
			return 'Visa';
		case 'mastercard':
			return 'MasterCard';
		case 'dinersclub':
			return 'Diners Club';
		case 'amex':
			return 'American Express';
		case 'aura':
			return 'Aura';
		case 'jcb':
			return 'JCB';
		case 'hipercard':
			return 'Hipercard';
		case 'discover':
		case 'elo':
			return 'Elo';
		default:
			return 'others';
	}
};

export default props => {
	//sdk
	const [sessionId, setSessionId] = useState('');

	useEffect(() => {
		const id = uuidv4();
		setSessionId(id);

		const noscript = document.createElement('noscript');
		const img = document.createElement('img');
		img.src = `https://device.clearsale.com.br/p/fp.png?sid=${id}&app=t0nbutochacjb6i7ie3f&ns=1`;
		noscript.appendChild(img);
		document.body.appendChild(noscript);

		const script = document.createElement('script');
		script.innerHTML = `
      (function (a, b, c, d, e, f, g) {
        a['CsdpObject'] = e;
        a[e] = a[e] || function () {
          (a[e].q = a[e].q || []).push(arguments);
        },
        a[e].l = 1 * Date.now();
        f = b.createElement(c),
        g = b.getElementsByTagName(c)[0];
        f.async = 1;
        f.src = '//device.clearsale.com.br/p/fp.js';
        g.parentNode.insertBefore(f, g);
      })(window, document, 'script', '', 'csdp');
      csdp('app', 't0nbutochacjb6i7ie3f&bl=1');
			csdp('sessionid', ${sessionId});
    `;
		document.body.appendChild(script);

		const checkUrl = async url => {
			try {
				await axios.get(url);
			} catch (error) {
				axios.get(`https://web.fpcs-monitor.com.br/p/fp.png?sid=${id}&app=t0nbutochacjb6i7ie3f
&bl=1`);
			}
		};

		setTimeout(() => {
			if (window.csdp) {
				window.csdp('inputsessionid', 'session-id-input');

				checkUrl('https://device.clearsale.com.br/p/fp.png?app=t0nbutochacjb6i7ie3f');
			} else {
				console.error('csdp não está definido.');
			}
		}, 500);
	}, []);
	const dispatch = useDispatch();

	const history = useHistory();

	const { token: preVendaToken } = props;

	if (['1581626117701688', '196901626127666096'].includes(preVendaToken)) {
		history.push(`/lp-multiple/${preVendaToken}`);
	}

	const validateFields = {
		cliente: {
			nome: {
				required: true,
			},
			email: {
				required: true,
				email: true,
			},
			celular: {
				required: true,
			},
			cpf: {
				required: true,
			},
			dataNascimento: {
				required: true,
			},
		},
		endereco: {
			logradouro: {
				required: true,
			},
			numero: {
				required: true,
			},
			cep: {
				required: true,
			},
			cidade: {
				required: true,
			},
			estado: {
				required: true,
			},
		},
		cartao: {
			titular: {
				required: true,
			},
			validade: {
				required: true,
			},
			numero: {
				required: true,
			},
			codigoSeguranca: {
				required: true,
			},
		},
	};
	const [pixVencido, setPixVencido] = useState(false);
	const qrCodeRef = React.createRef();

	const downloadQRCode = () => {
		const svg = qrCodeRef.current.getElementsByTagName('svg')[0];
		saveSvgAsPng.saveSvgAsPng(svg, 'qrcode.png', {
			scale: 5,
			encoderOptions: 1,
			backgroundColor: 'white',
		});
	};

	const message = useSelector(state => state.alert);
	const [text, setText] = useState('Carregando...');
	const [pedidoId, setPedidoId] = useState('');
	const [loading, setLoading] = useState(false);
	const [loadingData, setLoadingData] = useState(false);
	//state do checkbox
	const [isCartaoSelected, setCartaoSelected] = useState(false);
	const [isBoletoSelected, setBoletoSelected] = useState(false);
	// const ip = useClientIp();
	const ip = '';
	const [activeStep, setActiveStep] = useState(0);

	const [onError, setOnError] = useState(false);
	const [pagamentoAprovado, setPagamentoAprovado] = useState(false);
	// const [dataPagamento, setDataPagamento] = useState({"planoId":"4","cliente":{"nome":"Teste2","email":"dafdsf@hotmail.com","dataNascimento":"1991-12-26","cpf":"19860489009","telefone":"1421421241","celular":"14241241241"},"endereco":{"logradouro":"Rua Leoneta","numero":"4","cep":"03380235","cidade":"São Paulo","estado":"SP","complemento":""},"cartao":{"titular":"Dayglor Campos","validade":"02/07","numero":"5234211181847112","codigoSeguranca":"069"}});
	const [dataPagamento, setDataPagamento] = useState({
		token: props.token,
		//	validationToken: '',
		tipoPagamentoId: 3,
		parcelas: 1,
		valor: 0,
		juros: 0,
		repassarTaxaCliente: false,
		tipoEstabelecimentoId: 1,
		cliente: {
			nome: '',
			email: '',
			dataNascimento: '',
			cpf: '',
			cnpj: '',
			telefone: '',
			celular: '',
		},
		endereco: {
			logradouro: '',
			numero: '',
			cep: '',
			cidade: '',
			estado: '',
			complemento: '',
			bairro: '',
		},
		cartao: {
			bandeira: '',
			titular: '',
			validade: '',
			numero: '',
			codigoSeguranca: '',
		},
		valorParcela: 0,
		valorTotal: 0,
	});
	// const [dataPagamento, setDataPagamento] = useState({"planoId":"3","cliente":{"nome":"f dsa fdsaf","email":"fdafdsa@hotmail.com","dataNascimento":"1991-12-26","cpf":"74575174050","telefone":"1124141412","celular":"41241241212"},"endereco":{"logradouro":"Rua Leoneta","numero":"421","cep":"03380235","cidade":"São Paulo","estado":"SP","complemento":""},"cartao":{"titular":"Titular Cartão","validade":"12/23","numero":"5346955803456653","codigoSeguranca":"124"}});

	const [data, setData] = useState({
		name: '',
		description: '',
		setup_amount: 0.0,
		amount: 0.0,
		grace_period: '',
		tolerance_period: '',
		frequency: '',
		zoop_plano_id: null,
		interval: 1,
		juros: 0,
		tipo_pagamento_id: 3,
		colorEC: '',
	});

	const primary_color = data.colorEC;

	const classes = useStyles({ colorButton: primary_color || '#3f51b5' });

	const [pedido, setPedido] = useState();
	const [dataPix, setDataPix] = useState({
		pedidoId: undefined,
	});

	const handleGetSale = async () => {
		if (dataPix && data.tipo_pagamento_id === 6) {
			const res = await axios.get(`${config.get('apiUrl')}vendas/${dataPix.pedidoId}/publico`);

			if (res.data.success) {
				if (res.data.venda.status.id === 2) {
					setPagamentoAprovado(true);
					setStatusPedido(2);
				}
			}
		}
	};

	useEffect(() => {
		handleGetSale();

		const id = setInterval(handleGetSale, 12000);

		return () => {
			clearInterval(id);
		};
	}, [activeStep, dataPix.pedidoId]);

	const [sendedToken, setSendedToken] = useState(0);
	const [sendedTokenLoading, setSendedTokenLoading] = useState(false);

	// const sendTokenClient = async () => {
	// 	if (
	// 		dataPagamento.cliente.celular.length >= 11 &&
	// 		((sendedToken > 1 && !sendedTokenLoading) || sendedToken === 1 || sendedToken === 0)
	// 	) {
	// 		setSendedTokenLoading(true);
	// 		try {
	// 			const res = await axios.post(
	// 				config.get('apiV2Url') + 'validation/send-whatsapp-token',
	// 				{
	// 					object: props.token,
	// 					phoneNumber: '55' + dataPagamento.cliente.celular,
	// 				},
	// 				{
	// 					headers: { Authorization: `Bearer ${token}` },
	// 				}
	// 			);

	// 			if (res.data.success) {
	// 				dispatch(addMessage({ type: 'success', message: 'Token enviado para o seu celular com sucesso!' }));
	// 			} else {
	// 				dispatch(addMessage({ type: 'error', message: 'Falha ao enviar token para o seu celular.' }));
	// 			}
	// 		} catch (e) {
	// 			dispatch(addMessage({ type: 'error', message: e.response.data.message }));
	// 		}
	// 		setSendedTokenLoading(false);
	// 	}
	// };

	const [parcelasOptions, setParcelasOptions] = useState([]);
	const [taxas, setTaxas] = useState(null);
	// eslint-disable-next-line
	const [jurosBandeira, setJurosBandeira] = useState({});
	const [statusPedido, setStatusPedido] = useState(null);
	const [errorMessage, setErrorMessage] = useState('');

	const status = {
		success: [1, 2].includes(statusPedido),
		warning: [7, 11].includes(statusPedido),
		error: [3, 4, 5, 12].includes(statusPedido),
	};

	const token = useSelector(store => store.auth.token);
	// const estabelecimento = useSelector(store => store.auth.estabelecimento);

	const handleNext = () => {
		dispatch(addMessage({ type: 'error', message: '' }));
		setActiveStep(prevActiveStep => prevActiveStep + 1);
		//		if (activeStep === 3 && sendedToken === 0) {
		//			sendTokenClient();
		//			setSendedToken(1);
		//		}
	};

	const handleBack = () => {
		if (data.tipo_pagamento_id !== 8 && activeStep === 3) {
			setActiveStep(prevActiveStep => prevActiveStep - 2);
		} else {
			setActiveStep(prevActiveStep => prevActiveStep - 1);
		}
	};

	const getParcelas = (juros = {}) => {
		const parcelas = [];

		if (data.tipo_pagamento_id === 1 && Isset(() => taxas.taxaBoleto)) {
			const jurosBoleto = taxas.taxaBoleto;
			juros = { 1: jurosBoleto };
		}

		if (+data.amount > 10) {
			for (var i = 1; i <= data.parcelamento_ate; i++) {
				let valor = +data.amount;
				// eslint-disable-next-line no-loop-func
				if (Isset(() => juros[i]) && data.repassarTaxaCliente) {
					valor = +((+data.amount * 100) / (100 - +juros[i]));
				}
				let value = Mask.Real(valor / i);
				let amount = valor / i;
				if (data.juros > 0 && i > 1) {
					if (i >= data.juros_a_partir) {
						let amountJuros = +((valor / 100) * (i * data.juros)).toFixed(2);
						amount = valor + amountJuros;
						value = Mask.Real(+(amount / i).toFixed(2));
					}
				}

				let textParcela = i > 1 ? ` vezes ` : ' vez';
				textParcela += ` de ${value}`;

				if (data.juros && i !== 1 && i >= data.juros_a_partir) {
					textParcela += ` (Juros ${data.juros}% a.m)`;
				} else if (data.repassarTaxaCliente) {
					textParcela += ``;
				} else {
					textParcela += ` (Sem juros)`;
				}

				parcelas.push({ id: i, name: i + textParcela, value: amount });
			}
		} else {
			parcelas.push({ id: 1, name: '1 vez' });
		}

		setParcelasOptions(parcelas);
	};

	const getValorParcela = parcela => {
		let valor = +data.amount;

		let juros = jurosBandeira;

		if (data.tipo_pagamento_id === 1 && Isset(() => taxas.taxaBoleto)) {
			const jurosBoleto = taxas.taxaBoleto;
			juros = { 1: jurosBoleto };
		}

		// eslint-disable-next-line no-loop-func
		if (Isset(() => juros[parcela])) {
			valor = +((+data.amount * 100) / (100 - +juros[parcela]));
		}

		const valorParcela = valor / parcela;

		return { amount: valor, valorParcela };
	};

	const handleDocument = e => {
		const value = e.target.value.replace(/[^\d]+/g, '');
		const cpfCnpj = value.length <= 11 ? ValidarCpf(value) : ValidarCnpj(value);
		const errorMessage = value.length <= 11 ? 'CPF Inválido' : 'CNPJ Inválido';

		if (!cpfCnpj && value) {
			setErrorMessage(errorMessage);
		} else {
			setErrorMessage('');
		}
	};

	useEffect(() => {
		const getData = async () => {
			setLoadingData(true);
			// if( match.params.planoId ){
			let pedidoToken = props.token;
			if (!pedidoToken && props.pedidoId) {
				const pagarBoletoComCartao = await axios.get(
					`${config.get('apiUrl')}pre-venda/pagar-boleto-com-cartao/${props.pedidoId}`,
					{
						// headers: { Authorization: `Bearer ${token}` },
					}
				);
				pedidoToken = pagarBoletoComCartao.data.lp.token;
			}

			if (pedidoToken) {
				const res = await axios.get(`${config.get('apiUrl')}pre_venda/${pedidoToken}/token`, {
					// headers: { Authorization: `Bearer ${token}` },
				});

				if (res.data.success) {
					const configEC = res.data.preVenda.estabelecimento.estabelecimentos_configuracoes.find(
						configEC => configEC['tipo_configuracao_id'] === 2
					);
					setData({
						url_redirect: res.data.preVenda.url_redirect,
						colorEC: configEC ? configEC.valor : '',
						titulo: res.data.preVenda.titulo,
						descricao: res.data.preVenda.descricao,
						amount: res.data.preVenda.amount / 100,
						valor: res.data.preVenda.amount / 100,
						data_expiracao: res.data.preVenda.data_expiracao,
						parcelamento_ate: res.data.preVenda.parcelamento_ate,
						logo: !!res.data.preVenda.logo,
						logoUrl: Isset(() => res.data.preVenda.estabelecimento.arquivo.url)
							? res.data.preVenda.estabelecimento.arquivo.url
							: null,
						nome_fantasia: res.data.preVenda.estabelecimento.nome_fantasia,
						razao_social: res.data.preVenda.estabelecimento.razao_social,
						identificacao_fatura: res.data.preVenda.estabelecimento.identificacao_fatura,
						documento: Isset(() => res.data.preVenda.estabelecimento.estabelecimentos_documentos)
							? res.data.preVenda.estabelecimento.estabelecimentos_documentos[0].documento
							: null,
						estabelecimentoId: res.data.preVenda.estabelecimento.id,
						juros: res.data.preVenda.juros,
						juros_a_partir: res.data.preVenda.juros_a_partir,
						tipo_pagamento_id: res.data.preVenda.tipo_pagamento_id,
						repassarTaxaCliente: res.data.preVenda.repassar_taxa_cliente,
					});

					setDataPagamento({
						...dataPagamento,
						token: pedidoToken,
						valor: res.data.preVenda.amount / 100,
						tipoPagamentoId: res.data.preVenda.tipo_pagamento_id,
						valorParcela: res.data.preVenda.amount / 100,
						valorTotal: res.data.preVenda.amount / 100,
					});

					const taxas = await axios.get(
						`${config.get('apiUrl')}planos_taxas/porEstabelecimento/${
							res.data.preVenda.estabelecimento.id
						}/taxaTotal`,
						{
							params: { tipoVendaId: 2 },
							headers: { Authorization: `Bearer ${token}` },
						}
					);

					if (taxas.data.success) {
						setTaxas(taxas.data);
					}
				} else {
					setText(res.data.message);
				}
			}
		};

		getData();
		// }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.token]);
	useEffect(() => {
		getParcelas();
		setLoadingData(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data.amount, taxas]);

	useEffect(() => {
		installmentOnChange({ target: { value: dataPagamento.parcelas } });
	}, [parcelasOptions]);

	const getCountryFromIp = async () => {
		try {
			const response = await fetch('https://ipapi.co/json/');
			const data = await response.json();
			return data.country;
		} catch (error) {
			console.error('Erro ao detectar a região', error);
			return 'undefined';
		}
	};
	const handleSubmitForm = async () => {
		const country = await getCountryFromIp();

		dispatch(addMessage({ type: 'error', message: '' }));
		setLoading(true);

		try {
			if (dataPagamento.tipoEstabelecimentoId === 2) {
				delete validateFields.cliente.cpf;
				// delete validateFields.cliente.dataNascimento;
				validateFields.cliente.cnpj = {
					required: true,
				};
			} else {
				delete validateFields.cliente.cnpj;
				validateFields.cliente.cpf = {
					required: true,
				};
				// validateFields.cliente.dataNascimento = {
				// 	required: true,
				// };
			}
			if (data.tipo_pagamento_id === 1 || isBoletoSelected) {
				delete validateFields.cartao;
			}

			ValidatorForm(validateFields, dataPagamento);
		} catch (erro) {
			setActiveStep(0);
			dispatch(addMessage({ type: 'error', message: 'Existem campos inválidos no formulário' }));
			setLoading(false);
			setOnError(true);
			return false;
		}

		let res = '';
		var dadosPagamento = dataPagamento;
		if (dataPagamento.tipoEstabelecimentoId === 2) {
			delete dadosPagamento.cliente.cpf;
			delete dadosPagamento.cliente.dataNascimento;
		} else {
			delete dadosPagamento.cliente.cnpj;
		}

		dadosPagamento.estabelecimento_id = data.estabelecimentoId;
		dadosPagamento.ip = ip;
		dadosPagamento.sessionId = sessionId;
		// console.log(dadosPagamento);
		// console.log(data);
		// if (dadosPagamento.valorTotal > data.amount) {
		// 	dadosPagamento.valor = +dadosPagamento.valorTotal.toFixed(2);
		// }
		dadosPagamento.valor = +dadosPagamento.valorTotal.toFixed(2);
		dadosPagamento.ip = ip;
		// dadosPagamento.sendMail = false;
		// dadosPagamento.email = false;
		if (isBoletoSelected) {
			dadosPagamento.tipoPagamentoId = 1;
			delete validateFields.cartao;
		}
		if (isCartaoSelected || data.tipo_pagamento_id === 3) {
			dadosPagamento.tipoPagamentoId = 3;
		}

		res = await axios.post(config.get('apiUrl') + 'vendas/token', dadosPagamento, {
			// headers: { Authorization: `Bearer ${token}` },
		});

		if (res.data.error) {
			dispatch(addMessage({ type: 'error', message: res.data.error.message }));
			setLoading(false);
		}

		if (res.data.success && res.data.pedido) {
			const { status_pedido_id } = res.data.pedido;
			setPedido(res.data.pedido);

			setStatusPedido(status_pedido_id);
			if (data.url_redirect && status_pedido_id === 2) {
				await wait(15000);
				window.location = `${data.url_redirect}`;
			}
		}

		if (res.data.errors) {
			dispatch(addMessage({ type: 'error', message: res.data.errors.join(' - ') }));
			setLoading(false);
		}

		if (Isset(() => res.data.success) && res.data.success === true) {
			dispatch(addMessage({ type: 'success', message: 'Operação realizada com sucesso' }));
			setPagamentoAprovado(true);
			setPedidoId('Operação: ' + res.data.pedido.id);
			setData({ ...data });
		} else {
			dispatch(addMessage({ type: 'error', message: res.data.message }));
		}
		setLoading(false);
	};

	const getEndereco = async value => {
		if (value.length === 8) {
			var res = await axios.get(`https://viacep.com.br/ws/${value}/json/`);
			if (res.status !== 200) {
				setDataPagamento({
					...dataPagamento,
					endereco: {
						...dataPagamento.endereco,
						cep: value,
						logradouro: '',
						cidade: '',
						estado: '',
						complemento: '',
						bairro: '',
					},
				});
				return false;
			}

			setDataPagamento({
				...dataPagamento,
				endereco: {
					...dataPagamento.endereco,
					cep: Mask.OnlyNumber(res.data.cep),
					logradouro: res.data.logradouro,
					cidade: res.data.localidade,
					estado: res.data.uf,
					complemento: res.data.complemento,
					bairro: res.data.bairro,
				},
			});
		}
	};

	const maskCreditCard = useMemo(() => {
		const regex = /\b(?:\d{4}[ -]?){3}(?=\d{4}\b)/gm;
		const subst = `**** **** **** `;

		return dataPagamento.cartao.numero.replace(regex, subst);
	}, [dataPagamento.cartao.numero]);

	const onCardChange = (type, isValid) => {
		setDataPagamento({
			...dataPagamento,
			cartao: {
				...dataPagamento.cartao,
				bandeira: type.issuer,
			},
		});
		const bandeira = getBandeiraId(type.issuer);
		if (Isset(() => taxas.taxaTotal[bandeira].valores.credit)) {
			const taxasParcelas = taxas.taxaTotal[bandeira].valores.credit.valores;
			setJurosBandeira(taxasParcelas);
			getParcelas(taxasParcelas);
		} else {
			setJurosBandeira({});
			getParcelas();
		}
	};

	const installmentOnChange = e => {
		let valorParcela = data.amount;
		let amount = data.amount;
		if (e.target.value > 1 && (data.juros_a_partir <= e.target.value || !data.juros_a_partir) && data.juros) {
			let amountJuros = +((data.amount / 100) * data.juros).toFixed(2);

			amountJuros = amountJuros * e.target.value;
			amount = data.amount + amountJuros;
			valorParcela = +(amount / e.target.value).toFixed(2);
		} else if (e.target.value > 1) {
			valorParcela = +(amount / e.target.value).toFixed(2);
		}

		if (data.repassarTaxaCliente) {
			const valorParcelaAux = getValorParcela(e.target.value);
			valorParcela = valorParcelaAux.valorParcela;
			amount = valorParcelaAux.amount;
		}

		setDataPagamento({
			...dataPagamento,
			parcelas: e.target.value,
			tipoPagamentoId: data.tipo_pagamento_id,
			valorParcela,
			valorTotal: amount,
		});
	};

	if (loadingData) {
		return <ZLoader message="Carregando" />;
	}
	if (text && text !== 'Carrregando...' && !data.titulo) {
		return text;
	}

	if (data.tipo_pagamento_id === 6 && !pagamentoAprovado) {
		const gerarPix = async () => {
			setLoading(true);
			const payload = {
				cliente: {
					celular: dataPagamento.cliente.celular,
					cpf: dataPagamento.cliente.cpf,
					dataNascimento: dataPagamento.cliente.dataNascimento,
					email: dataPagamento.cliente.email,
					nome: dataPagamento.cliente.nome,
					telefone: dataPagamento.cliente.phone,
				},
				dataVencimento: data.data_expiracao,
				descricao: data.descricao,
				endereco: {
					cep: dataPagamento.endereco.cep,
					cidade: dataPagamento.endereco.cidade,
					complemento: dataPagamento.endereco.complemento,
					estado: dataPagamento.endereco.estado,
					logradouro: dataPagamento.endereco.logradouro,
					numero: dataPagamento.endereco.numero,
					bairro: dataPagamento.endereco.bairro,
				},
				estabelecimentoId: data.estabelecimentoId,
				tipoPagamentoId: 5,
				valor: data.amount,
				token: dataPagamento.token,
			};
			const res = await axios.post(`${config.get('apiUrl')}vendas/token`, payload, {
				// headers: { Authorization: `Bearer ${token}` },
			});
			if (res.data.success) {
				const parts = res.data.pedido.validadePix.split(' ');
				const dateParts = parts[0].split('/');
				const timeParts = parts[1].split(':');
				const dateValidation = subHours(
					new Date(dateParts[2], dateParts[1] - 1, dateParts[0], timeParts[0], timeParts[1], timeParts[2]),
					3
				);

				setDataPix({
					qrcode: res.data.pedido.qrCodePix,
					validadePix: dateValidation.toISOString(),
					pedidoId: res.data.pedido.id,
				});

				if (activeStep < 2) {
					setActiveStep(prevActiveStep => prevActiveStep + 1);
				} else {
					setPixVencido(false);
				}
			}
			setLoading(false);
		};
		return (
			<div style={{ background: primary_color, position: 'aboslute' }}>
				<Container maxWidth="md">
					<Grid container className={classes.displayMobile}>
						{message.message && (
							<ZAlert type={message.type} message={message.message} time={moment().unix()} />
						)}
						{/* input sdk */}
						<input type="hidden" id="session-id-input" value={sessionId} />
						<Grid item xs={12} md={6} className={classes.containerPaper}>
							<Grid item xs={12}>
								<Paper className={classes.paper}>
									<Grid item xs={12}>
										<Grid container spacing={2}>
											<Grid item xs={12}>
												<Grid container spacing={2}>
													{activeStep === 0 && (
														<>
															{/* <Grid
																	item
																	xs={12}
																	align="left"
																	className={classes.displayMobile}
																></Grid> */}
															{/* <Grid item xs={12} sm={12} md={12}>
																	<ZSelect
																		options={[
																			{
																				id: 1,
																				name: 'Pessoa Física',
																			},
																			{
																				id: 2,
																				name: 'Pessoa Jurídica',
																			},
																		]}
																		label="Tipo"
																		required={true}
																		value={dataPagamento.tipoEstabelecimentoId}
																		onChange={e => {
																			setDataPagamento({
																				...dataPagamento,
																				tipoEstabelecimentoId: e.target.value,
																			});
																		}}
																	/>
																</Grid> */}

															{dataPagamento.tipoEstabelecimentoId === 1 && (
																<>
																	<Grid item xs={12} sm={6} md={6}>
																		<ZText
																			label="Nome"
																			onError={onError}
																			value={dataPagamento.cliente.nome}
																			onBlur={e =>
																				setDataPagamento({
																					...dataPagamento,
																					cliente: {
																						...dataPagamento.cliente,
																						nome: e.target.value,
																					},
																				})
																			}
																		/>
																	</Grid>
																	<Grid item xs={12} sm={6} md={6}>
																		<ZText
																			label="Data Nascimento"
																			mask={'99/99/9999'}
																			maskChar={null}
																			onError={onError}
																			value={moment(
																				dataPagamento.cliente.dataNascimento
																			).format('DD/MM/YYYY')}
																			onBlur={e => {
																				let dataNascimento = moment(
																					e.target.value,
																					'DD/MM/YYYY'
																				).format('YYYY-MM-DD');
																				setDataPagamento({
																					...dataPagamento,
																					cliente: {
																						...dataPagamento.cliente,
																						dataNascimento: dataNascimento,
																					},
																				});
																			}}
																		/>
																	</Grid>
																	<Grid item xs={12} sm={6} md={6}>
																		{/* <ZText
																				label="CPF/CNPJ"
																				mask={'999.999.999-99'}
																				onError={onError}

																				value={dataPagamento.cliente.cpf.length <= 11 ? Mask.Cpf(dataPagamento.cliente.cpf) : Mask.Cnpj(dataPagamento.cliente.cpf)}
																				onBlur={}
																			/> */}
																		<TextField
																			className={classes.fullWidth}
																			margin="normal"
																			id="standard-full-width"
																			fullWidth
																			InputLabelProps={{
																				shrink: true,
																			}}
																			// error={errors.cpf}
																			label="CPF / CNPJ *"
																			value={
																				dataPagamento.cliente.cpf.length <= 11
																					? Mask.Cpf(
																							dataPagamento.cliente.cpf
																					  )
																					: Mask.Cnpj(
																							dataPagamento.cliente.cpf
																					  )
																			}
																			onChange={({ target }) =>
																				setDataPagamento({
																					...dataPagamento,
																					cliente: {
																						...dataPagamento.cliente,
																						cpf: Mask.OnlyNumber(
																							target.value
																						),
																					},
																				})
																			}
																			onBlur={handleDocument}
																		/>
																		{errorMessage && (
																			<span className={classes.errorText}>
																				{errorMessage}
																			</span>
																		)}
																	</Grid>
																</>
															)}
															{dataPagamento.tipoEstabelecimentoId === 2 && (
																<Grid item xs={12} sm={6} md={6}>
																	<ZText
																		label="CNPJ"
																		mask={'99.999.999/9999-99'}
																		onError={onError}
																		value={dataPagamento.cliente.cnpj}
																		onBlur={e =>
																			setDataPagamento({
																				...dataPagamento,
																				cliente: {
																					...dataPagamento.cliente,
																					cnpj: Mask.OnlyNumber(
																						e.target.value
																					),
																				},
																			})
																		}
																	/>
																</Grid>
															)}
															<Grid item xs={12} sm={6} md={6}>
																<ZText
																	label="E-mail"
																	onError={onError}
																	email
																	value={dataPagamento.cliente.email}
																	onBlur={e =>
																		setDataPagamento({
																			...dataPagamento,
																			cliente: {
																				...dataPagamento.cliente,
																				email: e.target.value,
																			},
																		})
																	}
																/>
															</Grid>
															<Grid item xs={12} sm={6} md={6}>
																<ZText
																	label="Telefone"
																	mask={'(99) 9999-9999'}
																	maskChar={null}
																	onError={onError}
																	required={false}
																	value={dataPagamento.cliente.telefone}
																	onBlur={e =>
																		setDataPagamento({
																			...dataPagamento,
																			cliente: {
																				...dataPagamento.cliente,
																				telefone: Mask.OnlyNumber(
																					e.target.value
																				),
																			},
																		})
																	}
																/>
															</Grid>
															<Grid item xs={12} sm={6} md={6}>
																<ZText
																	label="Celular"
																	mask={'(99) 9 9999-9999'}
																	maskChar={null}
																	onError={onError}
																	value={dataPagamento.cliente.celular}
																	onBlur={e =>
																		setDataPagamento({
																			...dataPagamento,
																			cliente: {
																				...dataPagamento.cliente,
																				celular: Mask.OnlyNumber(
																					e.target.value
																				),
																			},
																		})
																	}
																/>
															</Grid>
														</>
													)}
													{activeStep === 1 && (
														<Grid container justifyContent="center" spacing={2}>
															<Grid item xs={12} sm={6} md={6}>
																<ZText
																	label="CEP"
																	mask={'99999-999'}
																	onError={onError}
																	value={dataPagamento.endereco.cep}
																	onBlur={e => {
																		let cep = Mask.OnlyNumber(e.target.value);
																		setDataPagamento({
																			...dataPagamento,
																			endereco: {
																				...dataPagamento.endereco,
																				cep: cep,
																			},
																		});
																		getEndereco(cep);
																	}}
																/>
															</Grid>
															<Grid item xs={12} sm={6} md={6}>
																<ZText
																	label="Número"
																	onError={onError}
																	value={dataPagamento.endereco.numero}
																	onBlur={e =>
																		setDataPagamento({
																			...dataPagamento,
																			endereco: {
																				...dataPagamento.endereco,
																				numero: e.target.value,
																			},
																		})
																	}
																/>
															</Grid>
															<Grid item xs={12} sm={6} md={6}>
																<ZText
																	label="Logradouro"
																	onError={onError}
																	value={dataPagamento.endereco.logradouro}
																	onBlur={e =>
																		setDataPagamento({
																			...dataPagamento,
																			endereco: {
																				...dataPagamento.endereco,
																				logradouro: e.target.value,
																			},
																		})
																	}
																/>
															</Grid>

															<Grid item xs={12} sm={6} md={6}>
																<ZText
																	label="Complemento"
																	value={dataPagamento.endereco.complemento}
																	onBlur={e =>
																		setDataPagamento({
																			...dataPagamento,
																			endereco: {
																				...dataPagamento.endereco,
																				complemento: e.target.value,
																			},
																		})
																	}
																/>
															</Grid>

															<Grid item xs={12} sm={6} md={6}>
																<ZText
																	label="Cidade"
																	onError={onError}
																	//
																	disabled
																	value={dataPagamento.endereco.cidade}
																	onBlur={e =>
																		setDataPagamento({
																			...dataPagamento,
																			endereco: {
																				...dataPagamento.endereco,
																				cidade: e.target.value,
																			},
																		})
																	}
																/>
															</Grid>
															<Grid item xs={12} sm={6} md={6}>
																<ZText
																	label="Estado"
																	onError={onError}
																	disabled
																	//
																	value={dataPagamento.endereco.estado}
																	onBlur={e =>
																		setDataPagamento({
																			...dataPagamento,
																			endereco: {
																				...dataPagamento.endereco,
																				estado: e.target.value,
																			},
																		})
																	}
																/>
															</Grid>
															<Grid item xs={12} sm={12} md={12}>
																<ZText
																	label="Bairro"
																	disabled
																	onError={onError}
																	value={dataPagamento.endereco.bairro}
																	onBlur={e =>
																		setDataPagamento({
																			...dataPagamento,
																			endereco: {
																				...dataPagamento.endereco,
																				bairro: e.target.value,
																			},
																		})
																	}
																/>
															</Grid>
														</Grid>
													)}
													{activeStep === 2 &&
														(!pixVencido ? (
															<>
																<Grid xs={12}>
																	<Countdown
																		date={dataPix.validadePix}
																		onComplete={() => setPixVencido(true)}
																		renderer={({
																			hours,
																			minutes,
																			seconds,
																			completed,
																		}) => (
																			<div
																				style={{
																					display: 'flex',
																					justifyContent: 'center',
																					alignItems: 'center',
																					height: '100%',
																					fontWeight: 'bold',
																					flexDirection: 'column',
																					margin: '18px',
																				}}
																			>
																				<div>Este PIX expira em</div>
																				<span
																					style={{
																						fontSize: 30,
																						color: '#149611',
																					}}
																				>
																					{`${hours}`.padStart(2, '0')}:
																					{`${minutes}`.padStart(2, '0')}:
																					{`${seconds}`.padStart(2, '0')}
																				</span>
																			</div>
																		)}
																	/>
																</Grid>
																<Grid xs={12}>
																	<div
																		ref={qrCodeRef}
																		style={{
																			display: 'flex',
																			flexDirection: 'column',
																			justifyContent: 'center',
																			alignItems: 'center',
																			fontWeight: 'bold',
																			margin: '12px',
																			height: 256,
																			cursor: 'pointer',
																		}}
																		onClick={downloadQRCode}
																	>
																		<QRCode
																			renderAs="svg"
																			value={dataPix.qrcode}
																			width={256}
																			height={256}
																		/>
																		{/* <div className={classes.qrcodeHover}>
																		<FaDownload />
																	</div> */}
																	</div>
																</Grid>
																<Grid xs={12}>
																	<div
																		onClick={() => {
																			if (navigator.clipboard) {
																				navigator.clipboard.writeText(
																					dataPix.qrcode
																				);
																			} else {
																				fallbackCopyTextToClipboard(
																					dataPix.qrcode
																				);
																			}
																			dispatch(
																				addMessage({
																					type: 'success',
																					message:
																						'Código PIX Copiado com sucesso.',
																				})
																			);
																		}}
																		style={{
																			display: 'flex',
																			flexDirection: 'column',
																			justifyContent: 'center',
																			alignItems: 'center',
																			height: '100%',
																			fontWeight: 'bold',
																		}}
																	>
																		<div style={{ fontSize: 16 }}>
																			PIX Copia & Cola <FaCopy />
																		</div>
																		<div
																			className={classes.hoverPixCopiaCola}
																			style={{ fontSize: 10 }}
																		>
																			{dataPix.qrcode}
																		</div>
																	</div>
																</Grid>
															</>
														) : (
															<Grid
																xs={12}
																style={{
																	display: 'flex',
																	justifyContent: 'center',
																	alignItems: 'center',
																	height: '256px',
																	fontWeight: 'bold',
																	fontSize: 30,
																	color: '#dc3e3e',
																}}
															>
																Pix Vencido
															</Grid>
														))}
												</Grid>
											</Grid>
										</Grid>
									</Grid>
									{activeStep < 2 ? (
										<Grid item xs={12}>
											<Grid
												spacing={2}
												container
												direction="row"
												justifyContent="flex-end"
												style={{ marginTop: '32px' }}
											>
												<Grid item xs={12} sm={6} md={6}>
													<Button
														disabled={activeStep === 0}
														className={classes.backButton}
														onClick={handleBack}
														fullWidth
													>
														Voltar
													</Button>
												</Grid>
												<Grid item xs={12} sm={6} md={6}>
													{loading ? (
														<Button
															// onClick={() => handleSubmitForm()}
															className={classes.button}
															fullWidth
														>
															<CircularProgress size={24} style={{ color: '#fff' }} />
														</Button>
													) : (
														<Button
															className={classes.button}
															fullWidth
															onClick={activeStep !== 1 ? handleNext : gerarPix}
														>
															{activeStep !== 1 ? 'Continuar' : 'Gerar QRCode'}
														</Button>
													)}
												</Grid>
											</Grid>
										</Grid>
									) : (
										<Grid item xs={12}>
											{pixVencido && (
												<Grid
													spacing={2}
													container
													direction="row"
													justifyContent="flex-end"
													style={{ marginTop: '32px' }}
												>
													<Grid item xs={12} sm={6} md={6}></Grid>
													<Grid item xs={12} sm={6} md={6}>
														{loading ? (
															<Button
																// onClick={() => handleSubmitForm()}
																className={classes.button}
																fullWidth
															>
																<CircularProgress size={24} style={{ color: '#fff' }} />
															</Button>
														) : (
															<Button
																className={classes.button}
																fullWidth
																onClick={gerarPix}
															>
																{'Gerar novo QRCode'}
															</Button>
														)}
													</Grid>
												</Grid>
											)}
										</Grid>
									)}
								</Paper>
							</Grid>
						</Grid>
						<Grid
							item
							xs={12}
							md={4}
							className={classes.containerPaper}
							style={{
								marginTop: '16px',
							}}
						>
							{data.logo && data.logoUrl && (
								<Grid item xs={12} md={12} align="center" className={classes.hideLogo}>
									<img
										src={data.logoUrl}
										alt={data.nome_fantasia}
										title={data.nome_fantasia}
										style={{ marginBottom: '20px', maxWidth: '100%', maxHeight: '100px' }}
									/>
								</Grid>
							)}
							<Paper className={classes.paper}>
								<Grid container spacing={9} direction="row" justifyContent="center" alignItems="center">
									<Grid item xs={12} md={12}>
										<Typography className={classes.title} variant="h5" gutterBottom>
											{data.titulo}
										</Typography>
										<Typography className={classes.description} variant="subtitle1" gutterBottom>
											{data.descricao}
										</Typography>
										<Typography className={classes.description} variant="subtitle1" gutterBottom>
											<p>
												Preço: <b>{Mask.Real(dataPagamento.valorTotal)}</b>
											</p>
										</Typography>
										<Typography variant="body2" gutterBottom style={{ marginBottom: 16 }}>
											{data.identificacao_fatura ? data.identificacao_fatura : data.nome_fantasia}
										</Typography>
									</Grid>
								</Grid>
							</Paper>
						</Grid>
					</Grid>
				</Container>
			</div>
		);
	}

	if (pagamentoAprovado) {
		return (
			<>
				{pagamentoAprovado ? (
					<Grid
						container
						className={clsx(classes.container, {
							[classes.warning]: status.warning,
							[classes.error]: status.error,
							[classes.success]: status.success,
						})}
					>
						<Grid container item justifyContent="center">
							<div className="container__icon">
								<div>
									{(() => {
										if (status.success) {
											return <FiShoppingBag color="#059142" size="50px" strokeWidth="1px" />;
										}

										if (status.warning) {
											return <FiAlertTriangle color="#FFC107" size="50px" strokeWidth="1px" />;
										}

										if (status.error) {
											return <FiAlertTriangle color="#ff0000" size="50px" strokeWidth="1px" />;
										}
									})()}
								</div>

								{status.success && (
									<div className="container__icon-check">
										<SvgIcon
											component={CheckCircleIcon}
											fontSize="large"
											style={{ color: '#043927' }}
										/>
									</div>
								)}
							</div>
							<Grid container item justifyContent="center">
								<section>
									<p
										style={{
											color: '#fff',
											fontSize: '32px',
											margin: 0,
											padding: 10,
											textAlign: 'center',
										}}
									>
										{(() => {
											if (status.success) {
												if (
													data.tipo_pagamento_id === 1 ||
													(data.tipo_pagamento_id === 8 && isCartaoSelected === false)
												)
													return 'Pronto, seu boleto foi gerado';

												return 'Pronto, seu pagamento foi aprovado!';
											}

											if (status.error) {
												return 'Pagamento recusado! Entre em contato com o estabelecimento';
											}

											if (status.warning) {
												return 'Pagamento em análise, em breve enviaremos uma notificação por e-mail';
											}
										})()}
									</p>
									<p
										style={{
											color: '#fff',
											fontSize: '18px',
											textAlign: 'center',
											marginBottom: '64px',
											marginTop: 8,
										}}
									>
										{pedidoId}
									</p>
								</section>
							</Grid>
						</Grid>
						{status.success && (
							<Grid container justifyContent="center" alignItems="center" style={{ marginTop: 64 }}>
								<Paper className="paper">
									<Grid
										className="paper__container-content"
										item
										container
										direction="column"
										xs={12}
									>
										{data.tipo_pagamento_id === 8 && isCartaoSelected === true && (
											<FaRegCreditCard size="32px" />
										)}

										{data.tipo_pagamento_id !== 1 && isBoletoSelected === false && (
											<Grid item xs={12}>
												<p className="paper__content-title">
													{'Você pagou'} {Mask.Real(dataPagamento.valorTotal)}{' '}
												</p>
											</Grid>
										)}

										<Grid
											item
											xs={12}
											style={{
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center',
												minWidth: '220px',
												gap: '10px',
											}}
										>
											{data.tipo_pagamento_id === 1 && <FaBarcode size="32px" />}{' '}
											{data.tipo_pagamento_id === 8 && isBoletoSelected === true && (
												<FaBarcode size="32px" />
											)}{' '}
											{data.tipo_pagamento_id === 3 && (
												<>
													{getPaymentIcon(pedido?.bandeira ? pedido?.bandeira : '')}
													{/* {getPaymentIcon('mastercard')} */}
													<p>
														{data?.tipo_pagamento_id === 3 &&
															pedido?.parcelas === 1 &&
															'Crédito á Vista'}
														{data?.tipo_pagamento_id === 3 &&
															pedido?.parcelas !== 1 &&
															`Crédito Parcelado ${pedido?.parcelas}X`}
													</p>
												</>
											)}
										</Grid>

										<Grid item xs={12}>
											{data.tipo_pagamento_id === 1 ||
											(data.tipo_pagamento_id === 8 && isCartaoSelected === false) ? (
												<p className="paper__content-title">
													{'Você vai pagar'} {Mask.Real(dataPagamento.valorTotal)}{' '}
												</p>
											) : (
												<p style={{ margin: 0, fontSize: '14px' }}>{maskCreditCard}</p>
											)}
											{data.tipo_pagamento_id !== 1 && (
												<p style={{ fontSize: '14px' }}>
													{pedido?.created &&
														moment(pedido?.created).format('DD/MM/YYYY HH:mm:ss')}
												</p>
											)}
											<p>
												{data.nome_fantasia} {' - '}{' '}
												{data.documento.length === 11
													? Mask.Cpf(data.documento)
													: Mask.Cnpj(data.documento)}
											</p>
											<p className="paper__content-text">
												{data.tipo_pagamento_id === 1 &&
													'O boleto foi enviado para o seu e-mail, faça o pagamento o quanto antes pois a compensação pode demorar até 3 dias úteis'}

												{data.tipo_pagamento_id === 8 &&
													isBoletoSelected === true &&
													'O boleto foi enviado para o seu e-mail, faça o pagamento o quanto antes pois a compensação pode demorar até 3 dias úteis'}
												{![1, 5, 6].includes(data.tipo_pagamento_id) &&
													isBoletoSelected === false && (
														<>
															`Na fatura do seu cartão você verá o pagamento em nome de{' '}
															<strong>{data.nome_fantasia.toUpperCase()}</strong>`
														</>
													)}
											</p>
										</Grid>
									</Grid>
								</Paper>
							</Grid>
						)}
					</Grid>
				) : (
					<ZLoader />
				)}
			</>
		);
	}

	if (data.titulo && data.tipo_pagamento_id !== 6) {
		return (
			<div style={{ background: primary_color, position: 'aboslute' }}>
				<Container maxWidth="md">
					<Grid container className={classes.displayMobile}>
						{message.message && (
							<ZAlert type={message.type} message={message.message} time={moment().unix()} />
						)}

						<Grid item xs={12} md={6} className={classes.containerPaper}>
							<Grid item xs={12}>
								<Paper className={classes.paper}>
									<Grid item xs={12}>
										<Grid container spacing={2}>
											<Grid item xs={12}>
												<Grid container spacing={2}>
													{activeStep === 0 && (
														<>
															{/* <Grid
																item
																xs={12}
																align="left"
																className={classes.displayMobile}
															></Grid> */}
															{/* <Grid item xs={12} sm={12} md={12}>
																<ZSelect
																	options={[
																		{
																			id: 1,
																			name: 'Pessoa Física',
																		},
																		{
																			id: 2,
																			name: 'Pessoa Jurídica',
																		},
																	]}
																	label="Tipo"
																	required={true}
																	value={dataPagamento.tipoEstabelecimentoId}
																	onChange={e => {
																		setDataPagamento({
																			...dataPagamento,
																			tipoEstabelecimentoId: e.target.value,
																		});
																	}}
																/>
															</Grid> */}

															{dataPagamento.tipoEstabelecimentoId === 1 && (
																<>
																	<Grid item xs={12} sm={6} md={6}>
																		<ZText
																			label="Nome"
																			onError={onError}
																			required={true}
																			value={dataPagamento.cliente.nome}
																			onBlur={e =>
																				setDataPagamento({
																					...dataPagamento,
																					cliente: {
																						...dataPagamento.cliente,
																						nome: e.target.value,
																					},
																				})
																			}
																		/>
																	</Grid>
																	<Grid item xs={12} sm={6} md={6}>
																		<ZText
																			label="Data Nascimento"
																			mask={'99/99/9999'}
																			maskChar={null}
																			onError={onError}
																			required={true}
																			value={moment(
																				dataPagamento.cliente.dataNascimento
																			).format('DD/MM/YYYY')}
																			onBlur={e => {
																				let dataNascimento = moment(
																					e.target.value,
																					'DD/MM/YYYY'
																				).format('YYYY-MM-DD');
																				setDataPagamento({
																					...dataPagamento,
																					cliente: {
																						...dataPagamento.cliente,
																						dataNascimento: dataNascimento,
																					},
																				});
																			}}
																		/>
																	</Grid>
																	<Grid item xs={12} sm={6} md={6}>
																		{/* <ZText
																			label="CPF/CNPJ"
																			mask={'999.999.999-99'}
																			onError={onError}
																			required={true}
																			value={dataPagamento.cliente.cpf.length <= 11 ? Mask.Cpf(dataPagamento.cliente.cpf) : Mask.Cnpj(dataPagamento.cliente.cpf)}
																			onBlur={}
																		/> */}
																		<TextField
																			className={classes.fullWidth}
																			margin="normal"
																			id="standard-full-width"
																			fullWidth
																			InputLabelProps={{
																				shrink: true,
																			}}
																			// error={errors.cpf}
																			label="CPF / CNPJ *"
																			value={
																				dataPagamento.cliente.cpf.length <= 11
																					? Mask.Cpf(
																							dataPagamento.cliente.cpf
																					  )
																					: Mask.Cnpj(
																							dataPagamento.cliente.cpf
																					  )
																			}
																			onChange={({ target }) =>
																				setDataPagamento({
																					...dataPagamento,
																					cliente: {
																						...dataPagamento.cliente,
																						cpf: Mask.OnlyNumber(
																							target.value
																						),
																					},
																				})
																			}
																			onBlur={handleDocument}
																		/>
																		{errorMessage && (
																			<span className={classes.errorText}>
																				{errorMessage}
																			</span>
																		)}
																	</Grid>
																</>
															)}
															{dataPagamento.tipoEstabelecimentoId === 2 && (
																<Grid item xs={12} sm={6} md={6}>
																	<ZText
																		label="CNPJ"
																		mask={'99.999.999/9999-99'}
																		onError={onError}
																		required={true}
																		value={dataPagamento.cliente.cnpj}
																		onBlur={e =>
																			setDataPagamento({
																				...dataPagamento,
																				cliente: {
																					...dataPagamento.cliente,
																					cnpj: Mask.OnlyNumber(
																						e.target.value
																					),
																				},
																			})
																		}
																	/>
																</Grid>
															)}
															<Grid item xs={12} sm={6} md={6}>
																<ZText
																	label="E-mail"
																	onError={onError}
																	required={true}
																	email
																	value={dataPagamento.cliente.email}
																	onBlur={e =>
																		setDataPagamento({
																			...dataPagamento,
																			cliente: {
																				...dataPagamento.cliente,
																				email: e.target.value,
																			},
																		})
																	}
																/>
															</Grid>
															<Grid item xs={12} sm={6} md={6}>
																<ZText
																	label="Telefone"
																	mask={'(99) 9999-9999'}
																	maskChar={null}
																	onError={onError}
																	required={false}
																	value={dataPagamento.cliente.telefone}
																	onBlur={e =>
																		setDataPagamento({
																			...dataPagamento,
																			cliente: {
																				...dataPagamento.cliente,
																				telefone: Mask.OnlyNumber(
																					e.target.value
																				),
																			},
																		})
																	}
																/>
															</Grid>
															<Grid item xs={12} sm={6} md={6}>
																<div className={classes.wrapper}>
																	<ZText
																		label="Celular"
																		mask="(99) 9 9999-9999"
																		maskChar={null}
																		onError={onError}
																		required={true}
																		value={dataPagamento.cliente.celular}
																		onBlur={e =>
																			setDataPagamento({
																				...dataPagamento,
																				cliente: {
																					...dataPagamento.cliente,
																					celular: Mask.OnlyNumber(
																						e.target.value
																					),
																				},
																			})
																		}
																	/>
																	{/* <img
																		src="https://zgs-upload-image.s3.sa-east-1.amazonaws.com/1742563243159_whatsapp-svgrepo-com.png"
																		alt="Ícone Whatsapp"
																		className={classes.whatsappIcon}
																	/> */}
																</div>
															</Grid>
														</>
													)}

													{activeStep === 1 && (
														<Grid container justifyContent="center" spacing={2}>
															<Grid item xs={12} sm={6} md={6}>
																<ZText
																	label="CEP"
																	mask={'99999-999'}
																	onError={onError}
																	required={true}
																	value={dataPagamento.endereco.cep}
																	onBlur={e => {
																		let cep = Mask.OnlyNumber(e.target.value);
																		setDataPagamento({
																			...dataPagamento,
																			endereco: {
																				...dataPagamento.endereco,
																				cep: cep,
																			},
																		});
																		getEndereco(cep);
																	}}
																/>
															</Grid>
															<Grid item xs={12} sm={6} md={6}>
																<ZText
																	label="Número"
																	onError={onError}
																	required={true}
																	value={dataPagamento.endereco.numero}
																	onBlur={e =>
																		setDataPagamento({
																			...dataPagamento,
																			endereco: {
																				...dataPagamento.endereco,
																				numero: e.target.value,
																			},
																		})
																	}
																/>
															</Grid>
															<Grid item xs={12} sm={6} md={6}>
																<ZText
																	label="Logradouro"
																	onError={onError}
																	required={true}
																	value={dataPagamento.endereco.logradouro}
																	onBlur={e =>
																		setDataPagamento({
																			...dataPagamento,
																			endereco: {
																				...dataPagamento.endereco,
																				logradouro: e.target.value,
																			},
																		})
																	}
																/>
															</Grid>

															<Grid item xs={12} sm={6} md={6}>
																<ZText
																	label="Complemento"
																	value={dataPagamento.endereco.complemento}
																	onBlur={e =>
																		setDataPagamento({
																			...dataPagamento,
																			endereco: {
																				...dataPagamento.endereco,
																				complemento: e.target.value,
																			},
																		})
																	}
																/>
															</Grid>

															<Grid item xs={12} sm={6} md={6}>
																<ZText
																	label="Cidade"
																	onError={onError}
																	// required={true}
																	disabled
																	value={dataPagamento.endereco.cidade}
																	onBlur={e =>
																		setDataPagamento({
																			...dataPagamento,
																			endereco: {
																				...dataPagamento.endereco,
																				cidade: e.target.value,
																			},
																		})
																	}
																/>
															</Grid>
															<Grid item xs={12} sm={6} md={6}>
																<ZText
																	label="Estado"
																	onError={onError}
																	disabled
																	// required={true}
																	value={dataPagamento.endereco.estado}
																	onBlur={e =>
																		setDataPagamento({
																			...dataPagamento,
																			endereco: {
																				...dataPagamento.endereco,
																				estado: e.target.value,
																			},
																		})
																	}
																/>
															</Grid>
															<Grid item xs={12} sm={12} md={12}>
																<ZText
																	disabled
																	label="Bairro"
																	onError={onError}
																	value={dataPagamento.endereco.bairro}
																	onBlur={e =>
																		setDataPagamento({
																			...dataPagamento,
																			endereco: {
																				...dataPagamento.endereco,
																				bairro: e.target.value,
																			},
																		})
																	}
																/>
															</Grid>
														</Grid>
													)}

													{activeStep === 2 && data.tipo_pagamento_id === 8 && (
														<>
															{data.amount > 10 && ''}
															<Grid item xs={12}>
																<Typography variant="h6" gutterBottom align="center">
																	Método de Pagamento
																</Typography>
																<Grid
																	container
																	spacing={2}
																	justify="center"
																	alignItems="center"
																>
																	<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
																		<Paper
																			elevation={2}
																			onClick={() => {
																				setCartaoSelected(false);
																				setBoletoSelected(true);
																			}}
																			style={{
																				backgroundColor: 'rgba(0, 0, 0, 0.1)',
																				width: '100%',
																				height: '125px',
																				display: 'flex',
																				flexDirection: 'column',
																				justifyContent: 'center',
																				alignItems: 'center',
																				textAlign: 'center',
																			}}
																		>
																			<Checkbox
																				checked={isBoletoSelected}
																				icon={
																					<CheckBoxOutlineBlankIcon
																						fontSize="small"
																						style={{
																							display: 'block',
																							margin: 'auto',
																						}}
																					/>
																				}
																				checkedIcon={
																					<CheckBoxIcon
																						fontSize="small"
																						style={{
																							display: 'block',
																							margin: 'auto',
																						}}
																					/>
																				}
																				size="small"
																			/>
																			<Typography
																				variant="subtitle1"
																				style={{ marginTop: '6px' }}
																			>
																				Boleto
																			</Typography>
																			<MonetizationOnIcon
																				className={classes.icon}
																				style={{
																					display: 'block',
																					margin: 'auto',
																				}}
																			/>
																		</Paper>
																	</Grid>
																	<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
																		<Paper
																			elevation={2}
																			onClick={() => {
																				setCartaoSelected(true);
																				setBoletoSelected(false);
																			}}
																			style={{
																				backgroundColor: 'rgba(0, 0, 0, 0.1)',
																				width: '100%',
																				height: '125px',
																				display: 'flex',
																				flexDirection: 'column',
																				justifyContent: 'center',
																				alignItems: 'center',
																				textAlign: 'center',
																			}}
																		>
																			<Checkbox
																				checked={isCartaoSelected}
																				icon={
																					<CheckBoxOutlineBlankIcon
																						fontSize="small"
																						style={{
																							display: 'block',
																							margin: 'auto',
																						}}
																					/>
																				}
																				checkedIcon={
																					<CheckBoxIcon
																						fontSize="small"
																						style={{
																							display: 'block',
																							margin: 'auto',
																						}}
																					/>
																				}
																				size="small"
																			/>
																			<Typography
																				variant="subtitle1"
																				style={{ marginTop: '6px' }}
																			>
																				Cartão de Crédito
																			</Typography>
																			<CreditCardIcon
																				className={classes.icon}
																				style={{
																					display: 'block',
																					margin: 'auto',
																				}}
																			/>
																		</Paper>
																	</Grid>
																</Grid>
															</Grid>
														</>
													)}

													{activeStep === 2 && data.tipo_pagamento_id !== 8 && handleNext()}

													{activeStep === 3 && (
														<>
															{data.amount > 10 && ''}
															{/* <Grid
																item
																xs={12}
																align="center"
																className={classes.displayMobile}
															>
																<Typography variant="h5" gutterBottom>
																	PAGAMENTO
																</Typography>
															</Grid> */}

															{(data.tipo_pagamento_id === 3 || isCartaoSelected) && (
																<Grid item xs={12}>
																	<ZCard
																		number={dataPagamento.cartao.numero}
																		name={dataPagamento.cartao.titular}
																		expiry={dataPagamento.cartao.validade}
																		cvc={dataPagamento.cartao.codigoSeguranca}
																		numberOnBlur={e => {
																			setDataPagamento({
																				...dataPagamento,
																				cartao: {
																					...dataPagamento.cartao,
																					numero: Mask.OnlyNumber(
																						e.target.value
																					),
																				},
																			});
																		}}
																		nameOnBlur={e =>
																			setDataPagamento({
																				...dataPagamento,
																				cartao: {
																					...dataPagamento.cartao,
																					titular: e.target.value,
																				},
																			})
																		}
																		expiryOnBlur={e =>
																			setDataPagamento({
																				...dataPagamento,
																				cartao: {
																					...dataPagamento.cartao,
																					validade: e.target.value,
																				},
																			})
																		}
																		cvcOnBlur={e =>
																			setDataPagamento({
																				...dataPagamento,
																				cartao: {
																					...dataPagamento.cartao,
																					codigoSeguranca: e.target.value,
																				},
																			})
																		}
																		onError={onError}
																		maxInstallment={parcelasOptions}
																		installment={dataPagamento.parcelas}
																		installmentOnChange={installmentOnChange}
																		enableInstallmentOnCardNumber
																		cardGrid={{
																			xs: 12,
																			sm: 12,
																			md: 12,
																			lg: 12,
																		}}
																		containerInputsGrid={{
																			xs: 12,
																			sm: 12,
																			md: 12,
																			lg: 12,
																			marginTop: 30,
																		}}
																		onChange={onCardChange}
																	/>
																</Grid>
															)}

															{(data.tipo_pagamento_id === 1 || isBoletoSelected) && (
																<Grid item xs={12}>
																	<Typography variant="h6" gutterBottom>
																		Boleto Bancário
																	</Typography>
																	<Typography variant="body2" gutterBottom>
																		O boleto será enviado para o seu e-mail logo
																		após concluir o processo. <br />
																		Clique em avançar para continuar
																	</Typography>
																</Grid>
															)}
														</>
													)}
													{/* {activeStep === 4 && (
														<Grid container spacing={2}>
															<Grid item xs={12}>
																<Typography variant="h5" gutterBottom>
																	Verificação de segurança
																</Typography>
															</Grid>
															<Grid item xs={12}>
																<Typography variant="body1" color="textSecondary">
																	Um código de verificação foi enviado para o seu
																	WhatsApp. Insira abaixo
																</Typography>
															</Grid>

															<Grid item xs={12} sm={6} md={6}>
																<ZText
																	// label="Token"
																	onError={onError}
																	required
																	value={dataPagamento.validationToken}
																	onBlur={e =>
																		setDataPagamento({
																			...dataPagamento,
																			validationToken: e.target.value,
																		})
																	}
																/>
															</Grid>
															<Grid
																item
																xs={12}
																sm={6}
																md={6}
																className={classes.reenviar}
															>
																<Button
																	className={classes.buttonReenviar}
																	onClick={() => {
																		console.log(sendedToken);
																		console.log(sendedTokenLoading);
																		if (sendedToken === 1 && !sendedTokenLoading) {
																			sendTokenClient();
																		}
																	}}
																>
																	{loading ? (
																		<CircularProgress size={24} />
																	) : (
																		'Reenviar código'
																	)}
																</Button>
															</Grid>
														</Grid>
													)} */}
													{activeStep === 4 && (
														<Grid container direction="row">
															<Grid item xs={12} md={6}>
																<strong>CLIENTE:</strong>
																<Typography variant="subtitle2" gutterBottom>
																	<p>
																		Nome: <br />
																		<b>{dataPagamento.cliente.nome}</b>
																	</p>
																	{dataPagamento.tipoEstabelecimentoId === 1 && (
																		<>
																			<p>
																				CPF/CNPJ: <br />
																				<b>{dataPagamento.cliente.cpf}</b>
																			</p>
																		</>
																	)}
																	{dataPagamento.tipoEstabelecimentoId === 2 && (
																		<>
																			<p>
																				CNPJ: <br />
																				<b>{dataPagamento.cliente.cnpj}</b>
																			</p>
																		</>
																	)}
																	<p>
																		E-mail: <br />
																		<b>{dataPagamento.cliente.email}</b>
																	</p>
																</Typography>
															</Grid>
															<Grid item xs={12} md={6} className={classes.label}>
																<strong>PAGAMENTO:</strong>
																<Typography variant="subtitle2" gutterBottom>
																	<p>
																		{data.tipo_pagamento_id === 1
																			? 'Boleto Bancário'
																			: 'Cartão de Crédito'}
																	</p>
																	{/* Preço: <b>{Mask.Real(data.amount)}</b> */}
																	<p>
																		<span>
																			{' '}
																			{dataPagamento.parcelas} ve
																			{dataPagamento.parcelas > 1 ? 'zes ' : 'z '}
																			de {Mask.Real(dataPagamento.valorParcela)}
																		</span>
																	</p>
																	{dataPagamento.valorTotal > data.amount && (
																		<>
																			<p>
																				{' '}
																				Valor total: <br />
																				<b>
																					{Mask.Real(
																						dataPagamento.valorTotal
																					)}
																				</b>
																			</p>
																		</>
																	)}
																</Typography>
															</Grid>
														</Grid>
													)}
												</Grid>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12}>
										<Grid
											spacing={2}
											container
											direction="row"
											justifyContent="flex-end"
											style={{ marginTop: '32px' }}
										>
											<Grid item xs={12} sm={6} md={6}>
												<Button
													disabled={activeStep === 0}
													className={classes.backButton}
													onClick={handleBack}
													fullWidth
												>
													Voltar
												</Button>
											</Grid>
											{activeStep < 3 ? (
												<Grid item xs={12} sm={6} md={6}>
													<Button className={classes.button} fullWidth onClick={handleNext}>
														Continuar
													</Button>
												</Grid>
											) : (
												<Grid item xs={12} md={6}>
													{loading ? (
														<Button
															// onClick={() => handleSubmitForm()}
															className={classes.button}
															fullWidth
														>
															<CircularProgress size={24} style={{ color: '#fff' }} />
														</Button>
													) : (
														<Button
															onClick={() => handleSubmitForm()}
															className={classes.button}
															fullWidth
														>
															Concluir
														</Button>
													)}
												</Grid>
											)}
										</Grid>
									</Grid>
								</Paper>
							</Grid>
						</Grid>
						<Grid
							item
							xs={12}
							md={4}
							className={classes.containerPaper}
							style={{
								marginTop: '16px',
							}}
						>
							{data.logo && data.logoUrl && (
								<Grid item xs={12} md={12} align="center" className={classes.hideLogo}>
									<img
										src={data.logoUrl}
										alt={data.nome_fantasia}
										title={data.nome_fantasia}
										style={{ marginBottom: '20px', maxWidth: '100%', maxHeight: '100px' }}
									/>
								</Grid>
							)}
							<Paper className={classes.paper}>
								<Grid container spacing={9} direction="row" justifyContent="center" alignItems="center">
									<Grid item xs={12} md={12}>
										<Typography className={classes.title} variant="h5" gutterBottom>
											{data.titulo}
										</Typography>
										<Typography className={classes.description} variant="subtitle1" gutterBottom>
											{data.descricao}
										</Typography>
										<Typography className={classes.description} variant="subtitle1" gutterBottom>
											<p>
												Preço: <b>{Mask.Real(dataPagamento.valorTotal)}</b>
											</p>
										</Typography>
										<Typography variant="body2" gutterBottom style={{ marginBottom: 16 }}>
											{data.identificacao_fatura ? data.identificacao_fatura : data.nome_fantasia}
										</Typography>
									</Grid>
								</Grid>
							</Paper>
						</Grid>
					</Grid>
				</Container>
			</div>
		);
	}
};
