import React, { useState, useEffect, memo, useMemo, useCallback } from 'react';
import useClientIp from '../../hooks/useClientIp';
import clsx from 'clsx';
import ZTitle from '../system/ZTitle';
import ZLoader from '../system/ZLoader';
import ZText from '../system/ZText';
import ZSelect from '../system/ZSelect';
import ZDatePicker from '../system/ZDatePicker';
import ZSwitch from '../system/ZSwitch';
import { Mask, ValidatorForm, Isset, Formatter, isWhite } from '../../utils';
import { useSelector, useDispatch } from 'react-redux';
import { addMessage } from '../../store/alert';
import config from 'react-global-configuration';
import axios from 'axios';
import moment from 'moment';
// import { FaWhatsapp, FaShare } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';
import ListaDePedidosPreVenda from './ListaDePedidosPreVenda';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
//import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';
import { Typography, Tooltip } from '@material-ui/core/';
import InputAdornment from '@material-ui/core/InputAdornment';

// import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Close';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { FaDownload, FaCopy } from 'react-icons/fa';
import { format } from 'date-fns/esm';
import QRCode from 'qrcode.react';
import { SelectEstabelecimento } from '../Estabelecimentos/SelectEstabelecimento';
import saveSvgAsPng from 'save-svg-as-png';

import ValorBruto from '../Calculadoras/ValorBruto';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Axios from 'axios';
import ZModalWarning from '../system/ZModalWarning';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

//Link de pagamento nova venda
const useStyles = makeStyles(theme => ({
	buttonCopy: {
		color: '#040633',
		// border: `solid 1px #040633`,
		'&:hover': {
			color: '#FFF',
			backgroundColor: '#011d29',
			'& svg': {
				color: '#FFF',
			},
		},
		[theme.breakpoints.down('xs')]: {
			width: '90%',
			marginTop: 16,
		},
		'& svg': {
			margin: 0,
		},
	},
	iconButton: {
		minWidth: 0,
	},
	copy: {
		color: '#011D29',
		fontSize: 16,
		marginLeft: 8,
	},
	layout: {
		width: 'auto',
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		[theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
			width: 960,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	paper: {
		marginBottom: theme.spacing(3),
		padding: theme.spacing(2),
		[theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
			marginBottom: theme.spacing(6),
			padding: theme.spacing(3),
		},
	},
	menuIcon: {
		fontSize: 24,
		cursor: 'pointer',
		marginRight: 4,
	},
	estabelecimento: {
		padding: '6px 6px',
		marginBottom: 10,
	},
	estabelecimentoValor: {
		'&.porcentagem input': {
			textAlign: 'right',
		},
	},
	estabelecimentoIcone: {
		border: 'solid 1px #abc',
		marginRight: 2,
		marginLeft: 2,
		marginTop: 16,
		'&.selected': {
			backgroundColor: `${theme.palette.primary.main}`,
			color: theme.palette.getContrastText(theme.palette.primary.main),
		},
	},
	estabelecimentoError: {
		fontSize: 12,
		color: '#e53935',
	},
	label: {
		textAlign: 'center',
		fontSize: 11,
		textTransform: 'uppercase',
		fontWeight: 700,
	},
	labelResumo: {
		// marginTop: 16,
	},
	removeButton: {
		minHeight: 26,
		height: 26,
		width: 26,
		backgroundColor: '#e53935',
		color: 'white',
	},
	novaRegraSplit: {
		textAlign: 'center',
		textTransform: 'uppercase',
		margin: 8,
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
	},
	box: {
		textTransform: 'uppercase',
		fontSize: 12,
		position: 'relative',
		padding: 10,
		height: 110,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		cursor: 'pointer',
		'&.selected': {
			backgroundColor: `${theme.palette.primary.main}55`,
		},
		'& .value': {
			fontWeight: 700,
			fontSize: 13,
		},
	},
	splitAtivo: {
		position: 'absolute',
		top: -8,
		right: 10,
		fontWeight: 700,
		color: '#e53935',
		'&.ativo': {
			color: 'green',
		},
	},
	divider: {
		margin: 4,
	},
	span: {
		color: '#777',
		fontSize: 14,
	},
	removerButton: {
		backgroundColor: '#e53935',
		color: 'white',
	},
	statusButton: {
		backgroundColor: '#e53935',
		color: 'white',
		'&.ativar': {
			backgroundColor: 'green',
		},
	},
	divButton: {
		marginLeft: 'auto',
		marginTop: 64,
		[theme.breakpoints.down('xs')]: {
			margin: '64px auto 0',
			width: '100%',
		},
	},
	error: {
		padding: 8,
		borderRadius: 5,
		backgroundColor: '#e53935',
		color: 'white',
		marginBottom: 10,
	},
	subheader: {
		fontWeight: 700,
		fontSize: 18,
		fontVariant: 'small-caps',
		letterSpacing: '0.02em',
		margin: '20px 8px',
		marginTop: -30,
	},
	valorLiquido: {
		'& .MuiFormControlLabel-label': {
			fontSize: 11,
			fontWeight: 'bold',
			textAlign: 'center',
		},
	},
	qrcodeHover: {
		transition: theme.transitions.create(['all'], {
			duration: theme.transitions.duration.complex,
		}),
		opacity: 0,
		height: '100%',
		position: 'absolute',
		width: '100%',
		background: '#fafafa',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		zIndex: 100,
		top: 0,
		left: 0,
		'&:hover': {
			opacity: 0.7,
		},
	},
	button: {
		width: '100%',
		height: 44,
		backgroundColor: isWhite(theme.palette.primary.main) ? '#EEEEEE' : theme.palette.primary.main,
		color: isWhite(theme.palette.primary.main)
			? '#606060'
			: theme.palette.getContrastText(theme.palette.primary.main),
		'&:hover': {
			backgroundColor: isWhite(theme.palette.primary.main),
		},
	},
	backButton: {
		color: isWhite(theme.palette.primary.main) ? '#B4B4B4' : theme.palette.primary.main,
		border: `solid 1px `,
		borderColor: isWhite(theme.palette.primary.main),
		width: '100%',
		height: 44,
		marginRight: 10,
		'&:hover': {
			color: isWhite(theme.palette.primary.main)
				? '#313131'
				: theme.palette.primary.main
				? '#fff'
				: theme.palette.getContrastText(theme.palette.primary.main),
			backgroundColor: isWhite(theme.palette.primary.main) ? '#ccc' : theme.palette.primary.dark,
		},
	},
	btnCalculadora: {
		width: 171,
		height: 44,
		backgroundColor: theme.palette.primary.main,
		color: '#FFF',
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
		},
		marginLeft: 16,
		'& .MuiButton-label': {
			display: 'flex',
			flexDirection: 'column',
		},
	},
	stepTitle: {
		color: '#333',
		marginBottom: 42,
	},
	modalSelectEstabelecimento: {
		minWidth: '400px',
	},
	SelectEstabelecimento: {
		width: '100%',
	},
	formControl: {
		width: '100%',
	},
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmDialog = memo(props => {
	return (
		<Dialog
			disableBackdropClick
			disableEscapeKeyDown
			open={props.open}
			TransitionComponent={Transition}
			keepMounted
			onClose={props.handleClose}
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
		>
			<DialogTitle id="alert-dialog-slide-title">{'Atenção!!!'}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-slide-description">{props.dialogContent}</DialogContentText>
			</DialogContent>
			<DialogActions style={{ textAlign: 'center' }}>
				<Button onClick={props.handleClose} color="primary">
					OK
				</Button>
			</DialogActions>
		</Dialog>
	);
});

const ShareDialog = memo(props => {
	const history = useHistory();
	return (
		<Dialog
			open={props.open}
			TransitionComponent={Transition}
			keepMounted
			onClose={props.handleClose}
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
		>
			<DialogTitle id="alert-dialog-slide-title">{'Sucesso!'}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-slide-description">
					Deseja compartilhar o link no WhatsApp?
				</DialogContentText>
			</DialogContent>
			<DialogActions style={{ textAlign: 'center' }}>
				<Button onClick={props.handleSuccess} color="#666666">
					Sim
				</Button>
				<Button onClick={props.handleClose} color="#666666">
					Não
				</Button>
			</DialogActions>
		</Dialog>
	);
});

export default props => {
	const split = props.split;

	const classes = useStyles();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('xs'));
	const ip = useClientIp();

	// const { history, match } = useReactRouter();
	const history = useHistory();
	const match = useRouteMatch();

	const dispatch = useDispatch();
	const url = useSelector(store => store.system.config.hostname);
	const token = useSelector(store => store.auth.token);
	const usuario = useSelector(store => store.auth.usuario);
	const canSplit = usuario.can_split;
	// const isModerador = [1, 10].includes(usuario.grupo_id);
	const isNewLink = [1, 14, 17, 151].includes(usuario.estabelecimento.marketplace.id) ? true : false;
	const [dialogContent, setDialogContent] = useState(`
	As condições de antecipação para esse tipo de venda devem ser verificadas.
	Normalmente essas vendas não podem ser antecipadas.`);

	const qrCodeRef = React.createRef();

	// const [loadingToken, setLoadingToken] = useState(false);
	const loadingToken = false;
	const [loading, setLoading] = useState(false);
	const [onError, setOnError] = useState(false);
	const [activeStep, setActiveStep] = useState(0);
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [showShareModal, setShowShareModal] = useState(false);
	const [successPreVenda, setSuccessPreVenda] = useState(null);

	const estabelecimentoAdmin = useSelector(state => state.admin.estabelecimento);
	const ecIdViaSelect = Isset(() => estabelecimentoAdmin.id) ? estabelecimentoAdmin.id : null;

	const [data, setData] = useState({
		id: null,
		titulo: '',
		descricao: '',
		email: '',
		estabelecimento_id: ecIdViaSelect,
		amount: 0.0,
		parcelamento_ate: 1,
		meio_pagamento: 3,
		data_expiracao: moment().add(30, 'days'),
		logo: true,
		split: false,
		token: null,
		nome_fantasia: '',
		pedidos: [],
		juros: false,
		percentual: 0,
		juros_a_partir: 2,
		chargeProcessingFee: true,
		url_redirect: null,
		splits: [
			{
				estabelecimentoId: false,
				cpfcnpj: '',
				nome: '',
				email: '',
				value: 0,
				tipoSplit: 2,
				chargeProcessingFee: true,
			},
		],
		repassarTaxaCliente: false,
	});

	const [showCalculadora, setShowCalculadora] = useState(false);

	const [errors, setErrors] = useState({});

	const validateFields = {
		titulo: { required: true },
		// description: { required: true },
		amount: { required: true, min: 10 },
		parcelamento_ate: { required: true },
	};
	let { estabelecimentoId } = match.params;

	if (!estabelecimentoId) {
		({ estabelecimentoId } = props);
	}

	if (!estabelecimentoId) {
		estabelecimentoId = usuario.estabelecimento.id;
	}

	if (ip) {
		data.ip = ip;
	}

	const getPreVenda = async () => {
		// setLoadingToken(true);

		setActiveStep(3);

		const res = await axios.get(config.get('apiUrl') + 'pre_venda/' + match.params.preVendaId, {
			headers: { Authorization: `Bearer ${token}` },
		});

		if (res.data.success) {
			const splits = res.data.preVenda.split
				? res.data.preVenda.split.splits_estabelecimentos.map(split => ({
						tipoSplit: split.tipo_split,
						valor: split.valor,
						nome_fantasia: split.estabelecimento.nome_fantasia,
				  }))
				: [];

			setData({
				...data,
				url_redirect: res.data.preVenda.url_redirect,
				id: res.data.preVenda.id,
				titulo: res.data.preVenda.titulo,
				descricao: res.data.preVenda.descricao,
				email: res.data.preVenda.email,
				amount: res.data.preVenda.amount,
				parcelamento_ate: res.data.preVenda.parcelamento_ate,
				data_expiracao: new Date(res.data.preVenda.data_expiracao),
				logo: !!res.data.preVenda.logo,
				split: res.data.preVenda.split ? !!res.data.preVenda.split : false,
				token: res.data.preVenda.token,
				nome_fantasia: res.data.preVenda.estabelecimento.nome_fantasia,
				pedidos: res.data.preVenda.pre_venda_pedidos,
				juros: res.data.preVenda.juros ? true : false,
				percentual: res.data.preVenda.juros,
				meio_pagamento: res.data.preVenda.tipo_pagamento_id,
				juros_a_partir: res.data.preVenda.juros_a_partir,
				splits,
				repassarTaxaCliente: res.data.preVenda.repassar_taxa_cliente,
			});
		} else {
			dispatch(addMessage({ type: 'error', message: res.data.error }));
			setLoading(false);
		}
		// setLoadingToken(false);
	};

	useEffect(() => {
		if (match.params.preVendaId) {
			getPreVenda();
		} else {
			// setShowConfirmModal(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [match.params.preVendaId]);

	const handleSubmitForm = async () => {
		setLoading(true);
		try {
			ValidatorForm(validateFields, data);
		} catch (erro) {
			dispatch(addMessage({ type: 'error', message: 'Existem campos inválidos no formulário' }));

			setLoading(false);

			setOnError(true);

			setActiveStep(0);

			return false;
		}

		let res = '';
		data.estabelecimento_id = ecIdViaSelect;
		let dataLinkPagamento = data;
		let splitsLink = data.splits.map((value, key) => {
			return { ...value, chargeProcessingFee: true }; //data.chargeProcessingFee }
		});

		dataLinkPagamento.splits = splitsLink;
		if (match.params.planoId) {
			res = await axios.put(config.get('apiUrl') + 'pre_venda/' + match.params.planoId, dataLinkPagamento, {
				headers: { Authorization: `Bearer ${token}` },
			});
		} else {
			res = await axios.post(config.get('apiUrl') + 'pre_venda', dataLinkPagamento, {
				headers: { Authorization: `Bearer ${token}` },
			});
		}
		setLoading(false);

		if (res.data.error) {
			dispatch(addMessage({ type: 'error', message: res.data.error }));
			// dispatch(addMessage({ type: 'error', message: 'Ocorreu um erro ao salvar' }));
		}

		if (Isset(() => res.data.success) && res.data.success === true) {
			dispatch(addMessage({ type: 'success', message: 'Operação realizada com sucesso' }));
			setSuccessPreVenda(res.data.preVenda);
			setShowShareModal(true);
		}
	};

	const handleChange = name => event => {
		var value = event.target.value;

		if (name === 'parcelamento_ate') {
			setParcelamentoPartirDe(gerarParcelasJuros(event.target.value));
		}

		switch (name) {
			case 'amount':
				value = Mask.Real(value);
				break;
			default:
				break;
		}

		setData({
			...data,
			[name]: value,
		});
	};

	const handleSuccess = () => {
		if (successPreVenda) {
			// const text = encodeURIComponent(
			// 	`Link de Pagamento - ${
			// 		successPreVenda.estabelecimento.nome_fantasia
			// 	}\n\n*${data.titulo.trim()}*\nhttps://${url}${config.get('baseName')}/venda/token/${
			// 		successPreVenda.token
			// 	}`
			// );
			let text;
			if (isNewLink) {
				text = encodeURIComponent(
					`Link de Pagamento - ${
						successPreVenda.estabelecimento.nome_fantasia
					}\n\n*${data.titulo.trim()}*\nhttps://${url}/link?${successPreVenda.token}`
				);
			} else {
				text = encodeURIComponent(
					`Link de Pagamento - ${
						successPreVenda.estabelecimento.nome_fantasia
					}\n\n*${data.titulo.trim()}*\nhttps://${url}${config.get('baseName')}/lp/${successPreVenda.token}`
				);
			}

			window.open(`https://web.whatsapp.com/send?text=${text}`, '_blank');
			history.push('/venda-digitada/' + successPreVenda.id);
		}
	};
	const getMaxInstallments = useCallback(async () => {
		const response = await Axios.get(`${config.get('apiUrl')}planos_de_vendas/get-parcelamento-maximo`, {
			headers: { Authorization: `Bearer ${token}` },
		});

		setMaxInstallments(response.data.max);
	}, [token]);
	// const token = useSelector(state => state.auth.token);
	const [maxInstallments, setMaxInstallments] = useState(12);
	useEffect(() => {
		getMaxInstallments();
	}, [getMaxInstallments]);

	const parcelas = useCallback(() => {
		const result = [];
		const valorLink = data.amount / 100;

		for (let parcela = 1; parcela <= maxInstallments; parcela++) {
			const text = valorLink !== 0 ? `${parcela}x de ${Formatter.Real(valorLink / parcela)}` : `${parcela}x`;

			result.push({ id: parcela, name: text });
		}

		return result;
	}, [data.amount, maxInstallments]);

	if (loadingToken) {
		return <ZLoader height={200} />;
	}

	const disableParcela = () => {
		return ![3, 8, 12].includes(data.meio_pagamento) || data.id || valorDaVenda <= 10 ? true : false;
	};

	const handleRemoveSplit = position => {
		const splits = [...data.splits];
		splits.splice(position, 1);

		setData({
			...data,
			splits,
		});
	};

	const valorDaVenda = useMemo(() => {
		return data.amount / 100;
	}, [data.amount]);

	const valorLiquido = useMemo(() => {
		return data.chargeProcessingFee;
	}, [data.chargeProcessingFee]);

	const updateSplit = async (index, name, value) => {
		let splits = [...data.splits];
		let split = splits.find((_, i) => i === index);

		if (split) {
			split = Object.assign({}, split);
			split[name] = value;

			if (name === 'cpfcnpj') {
				delete errors[`split${index}`];
				setErrors(errors);

				if ([11, 14].includes(value.length)) {
					const result = await axios.get(`${config.get('apiUrl')}estabelecimentos/por_documento/${value}`, {
						headers: { Authorization: `Bearer ${token}` },
					});

					if (result.data.success) {
						const e = result.data.estabelecimento;

						if (!e.length) {
							if (+estabelecimentoId === +e.id) {
								setErrors({
									...errors,
									[`split${index}`]: 'Você não pode selecionar o mesmo estabelecimento.',
								});
								return;
							}

							const email = e.estabelecimentos_contatos.find(a => a.tipo_contato_id === 3);

							const estabelecimentoExiste = data.splits.find(
								(a, i) => a.estabelecimentoId === e.id && index !== i
							);

							if (estabelecimentoExiste) {
								setErrors({
									...errors,
									[`split${index}`]: 'Você já selecionou este estabelecimento.',
								});
								return;
							} else {
								split['estabelecimentoId'] = e.id;
								split['nome'] = e.nome_fantasia;
								split['email'] = Isset(() => email.contato) ? email.contato : '';
							}
						} else {
							openEstablishmentSelectionModal(e, index);
						}
					} else {
						setErrors({ ...errors, [`split${index}`]: 'Estabelecimento Não Encontrado.' });
					}
				} else {
					split['estabelecimentoId'] = null;
					split['nome'] = '';
					split['email'] = '';
				}
			}

			if (name === 'tipoSplit' && value === 2) {
				if (split.value > 100) {
					split.value = 100;
				}
			}

			splits[index] = split;
			const splitMaximo = !valorLiquido ? valorDaVenda * 0.9 : valorDaVenda;
			let totalSplitado = 0;

			splits.forEach(split => {
				if (split.tipoSplit === 2) {
					totalSplitado += (split.valor / 100) * valorDaVenda;
				} else {
					totalSplitado += split.valor;
				}
			});

			if (totalSplitado > splitMaximo) {
				setDialogContent(
					`${
						!valorLiquido
							? 'Para ultrapassar 90% do valor selecione Valor Líquido'
							: 'Você não pode splitar mais que o valor total'
					}`
				);

				setShowConfirmModal(true);
			} else {
				setData({ ...data, splits });
			}
		}
	};

	const [showModalEstablishmentSelection, setShowModalEstablishmentSelection] = useState(false);
	const [ecsOfTheDocument, setEcsOfTheDocument] = useState();
	const [ecSelected, setEcSelected] = useState('Estabelecimento');
	const [indexEc, setIndexEc] = useState();
	const [parcelamentoPartirDe, setParcelamentoPartirDe] = useState([]);

	const openEstablishmentSelectionModal = (estabelecimentos, index) => {
		setEcsOfTheDocument(estabelecimentos);
		setIndexEc(index);
		setShowModalEstablishmentSelection(true);
	};

	const handleSetEstabelecimento = () => {
		let estabelecimento;
		let estabelecimentoFormatoParaData = {};
		let splits = [...data.splits];

		estabelecimento = ecsOfTheDocument.find(e => e.id === ecSelected);

		const email = estabelecimento.estabelecimentos_contatos.find(a => a.tipo_contato_id === 3);

		estabelecimentoFormatoParaData['estabelecimentoId'] = estabelecimento.id;
		estabelecimentoFormatoParaData['nome'] = estabelecimento.nome_fantasia;
		estabelecimentoFormatoParaData['email'] = Isset(() => email.contato) ? email.contato : '';
		estabelecimentoFormatoParaData['cpfcnpj'] = estabelecimento.estabelecimentos_documentos[0].documento;
		estabelecimentoFormatoParaData['tipoSplit'] = 2;

		splits[indexEc] = estabelecimentoFormatoParaData;

		setData({ ...data, splits });
		setShowModalEstablishmentSelection(false);
	};

	const handleNewSplit = () => {
		var splits = [...data.splits];

		splits.push({
			cpfcnpj: '',
			estabelecimentoId: null,
			nome: '',
			email: '',
			tipoSplit: 2, // 1 - Valor, 2 - Percentual
			valor: '',
			chargeProcessingFee: data.chargeProcessingFee,
		});

		setData({
			...data,
			splits,
		});
	};

	// const handleNext = () => {
	// 	console.log('proximo syrp');
	// 	// if (split !== 0 && activeStep === 1) {
	// 	if (activeStep === 1 && (split !== 0 || !canSplit)) {
	// 		console.log('proximo syrp');
	// 		setActiveStep(prevStep => prevStep + 2);
	// 	} else {
	// 		setActiveStep(prevStep => prevStep + 1);
	// 	}
	// };
	const handleNext = () => {
		try {
			let quantidadeSteps = 1;

			switch (activeStep) {
				case 0:
					if (data.titulo.length === 0) {
						ValidatorForm(validateFields.titulo, data);
						dispatch(addMessage({ type: 'error', message: 'O campo título esta vazio.' }));
						return false;
					}
					break;
				case 1:
					// console.log(data.amount);
					// console.log(data.amount / 100);

					if (data.amount / 100 < 10) {
						ValidatorForm(validateFields.amount, data);
						dispatch(addMessage({ type: 'error', message: 'Valor menor que o permitido.' }));
						if (split !== 0 || !canSplit) {
							quantidadeSteps = 2;
						}
						return false;
					}
					break;
				default:
					break;
			}
			setActiveStep(prevStep => prevStep + quantidadeSteps);
		} catch (error) {
			setOnError(true);
			dispatch(addMessage({ type: 'error', message: error.message }));
			setLoading(false);
		}
	};

	const handleBack = () => {
		if (activeStep === 0 && props.inNovaVenda) {
			props.setActiveStepNovaVenda(0);
		} else {
			// if (split !== 0 && activeStep === 3) {
			if (activeStep === 3 && (split !== 0 || !canSplit)) {
				setActiveStep(prevStep => prevStep - 2);
			} else {
				setActiveStep(prevStep => prevStep - 1);
			}
		}
	};
	const canUseNupay = useSelector(store =>
		Isset(() => store.system.params['49'].valor) ? store.system.params['49'].valor : null
	);
	const getFormaPagamentoNovo = canUseNupay => {
		switch (canUseNupay) {
			case true:
				return 'Cartão de Crédito ou Boleto ou NuPay';
			default:
				return 'Cartão de Crédito ou Boleto';
		}
	};
	const getFormaPagamento = status => {
		let formato = '';
		switch (status) {
			case 1:
				formato = 'Boleto Bancário';
				break;
			case 3:
				formato = 'Cartão de Crédito';
				break;
			case 6:
				formato = 'Pix';
				break;
			case 8:
				formato = getFormaPagamentoNovo(canUseNupay);
				break;
			case 9:
				formato = 'NuPay';
				break;
			case 12:
				formato = 'Cartão de Crédito sem Cliente';
				break;
			default:
				break;
		}
		return formato;
	};
	const baseUrl = `https://${url}${config.get('baseName')}/link?${data.token}`;
	const cleanedUrl = baseUrl.replace(/\/app/g, '');
	const formattedData = useMemo(() => {
		return {
			date: format(data.data_expiracao, 'DD/MM/YYYY'),
			amount: Mask.Real(data.amount / 100),
			logo: data.logo ? 'Sim' : 'Não',
			payment: getFormaPagamento(data.meio_pagamento),
			juros_a_partir: data.juros_a_partir < 2 ? 'N/A' : `${data.juros_a_partir} vezes`,
			parcel: data.parcelamento_ate === 1 ? `${data.parcelamento_ate} vez` : `${data.parcelamento_ate} vezes`,
			percentual: `${data.percentual}%`,
			repassarTaxaCliente: data.repassarTaxaCliente ? 'Sim' : 'Não',
		};
	}, [data]);

	const downloadQRCode = () => {
		const svg = qrCodeRef.current.getElementsByTagName('svg')[0];
		saveSvgAsPng.saveSvgAsPng(svg, 'qrcode.png', {
			scale: 5,
			encoderOptions: 1,
			backgroundColor: 'white',
		});
	};

	const options = [
		{
			id: 1,
			name: 'Boleto Bancário',
		},
		{
			id: 3,
			name: 'Cartão de Crédito',
		},
		{
			id: 12,
			name: 'Cartão de Crédito sem Cliente',
		},
		{
			id: 6,
			name: 'Pix',
		},
		{
			id: 8,
			name: isNewLink ? 'Todos' : 'Boleto ou Cartão de Crédito',
		},
	];
	if (canUseNupay) {
		options.push({ id: 9, name: 'NuPay' });
	}

	const gerarParcelasJuros = maximo => {
		const options = [];
		for (let i = 2; i <= maximo; i++) {
			options.push({ id: i, name: i.toString() });
		}
		return options;
	};

	return (
		<Container maxWidth="md">
			<Grid container>
				{(() => {
					if (props.disableTitle) {
						return null;
					}

					if (!match.params.preVendaId) {
						return <ZTitle title={'Link de Pagamento'} variant={props.variant} />;
					}
				})()}

				{match.params.preVendaId && (
					<ZTitle
						title="Link de Pagamento"
						button="add"
						handleOnClick={() => history.push(`/venda-digitada`)}
					/>
				)}

				<Grid item xs={12}>
					<Grid container item>
						<Grid item xs={12} md={12} lg={12}>
							<Paper className={classes.paper} elevation={props.disablePaper}>
								{activeStep === 0 && (
									<>
										<Typography variant="h5" component="h6" className={classes.stepTitle}>
											Informações do Link de pagamento
										</Typography>

										<Grid container spacing={3}>
											<Grid item xs={12} md={6}>
												<ZText
													label="Título"
													onError={onError}
													required={true}
													value={data.titulo}
													onBlur={handleChange('titulo')}
												/>
											</Grid>
											<Grid item xs={12} md={6}>
												<ZText
													label="Descrição"
													// multiline
													// rows="3"
													value={data.descricao}
													onBlur={handleChange('descricao')}
												/>
											</Grid>

											<Grid item xs={12} md={6}>
												<ZText
													label="E-mail"
													// multiline
													// rows="3"
													value={data.email}
													onBlur={handleChange('email')}
												/>
											</Grid>

											<Grid item xs={12} sm={6} md={6} style={{ marginTop: 15 }}>
												<ZDatePicker
													// error={errors.dataInicio}
													textField
													label="Data de expiração"
													disabled={data.id ? true : false}
													minDate={new Date()}
													date={data.data_expiracao}
													onChange={date => setData({ ...data, data_expiracao: date })}
												/>
											</Grid>

											<Grid item xs={12} md={6}>
												<ZText
													value={data.url_redirect}
													label="Url de redirecionamento"
													onBlur={handleChange('url_redirect')}
												/>
											</Grid>

											<Grid item xs={12} md={6} style={{ marginTop: 15 }}>
												<ZSwitch
													onChange={() => setData({ ...data, logo: !data.logo })}
													label="Exibir logo do estabelecimento?"
													checked={data.logo}
												/>
											</Grid>
										</Grid>
									</>
								)}

								{activeStep === 1 && (
									<>
										<Typography variant="h5" component="h6" className={classes.stepTitle}>
											Detalhes do Pagamento
										</Typography>

										<Grid container spacing={4}>
											<Grid item xs={12} sm={6} md={4}>
												<ZSelect
													options={options}
													label="Meio de pagamento"
													disabled={data.id ? true : false}
													onError={onError}
													width="100%"
													value={data.meio_pagamento}
													onChange={handleChange('meio_pagamento')}
												/>
											</Grid>
											<Grid item xs={12} sm={6} md={4}>
												<ZText
													fullWidth
													label="Valor"
													margin="normal"
													realMask
													disabled={data.id ? true : false}
													onError={onError}
													min={validateFields.amount.min}
													placeholder="Valor a ser pago"
													value={data.amount / 100}
													onBlur={e => {
														setData({ ...data, amount: Math.round(e.target.value * 100) });
													}}
												/>
											</Grid>

											<Grid item xs={12} sm={6} md={4}>
												<ZSelect
													disabled={disableParcela()}
													options={parcelas()}
													label="Máximo de parcelas"
													onError={onError}
													width="100%"
													value={data.parcelamento_ate}
													onBlur={handleChange('parcelamento_ate')}
												/>
											</Grid>

											{![1, 6].includes(data.meio_pagamento) && (
												<Grid item xs={12} md={4}>
													{!data.repassarTaxaCliente && (
														<ZSwitch
															onChange={() => {
																setData({
																	...data,
																	juros: !data.juros,
																	percentual: 0,
																	juros_a_partir: 2,
																});
															}}
															label="Adicionar Juros"
															checked={data.juros}
														/>
													)}
													{!data.juros && (
														<ZSwitch
															onChange={() =>
																setData({
																	...data,
																	repassarTaxaCliente: !data.repassarTaxaCliente,
																})
															}
															label="Repassar Taxa ao Cliente"
															checked={data.repassarTaxaCliente}
														/>
													)}
												</Grid>
											)}

											{data.juros && (
												<>
													<Grid item xs={12} sm={6} md={4}>
														<ZText
															label="Juros ao mês"
															disabled={data.id ? true : false}
															onError={onError}
															required={true}
															value={data.percentual}
															endAdornment={
																<InputAdornment position="end">%</InputAdornment>
															}
															onChange={(value, setValue) => {
																value = Mask.OnlyNumber(value) / 100;

																setValue(
																	value > 100
																		? Mask.Porcentagem(100)
																		: Mask.Porcentagem(value)
																);
															}}
															onBlur={e =>
																setData({
																	...data,
																	percentual: e.target.value,
																})
															}
														/>
													</Grid>
													<Grid item xs={12} sm={6} md={4}>
														{parcelamentoPartirDe.length > 0 && (
															<ZSelect
																disabled={data.id ? true : false}
																options={parcelamentoPartirDe}
																label="A partir da parcela"
																onError={onError}
																width="100%"
																value={data.juros_a_partir}
																onBlur={handleChange('juros_a_partir')}
															/>
														)}
													</Grid>
												</>
											)}

											{data.repassarTaxaCliente && (
												<>
													<Button
														className={classes.btnCalculadora}
														onClick={() => setShowCalculadora(true)}
													>
														Ver Calculadora
													</Button>
													{showCalculadora && (
														<ValorBruto
															readonly={true}
															open={showCalculadora}
															estabelecimentoId={
																ecIdViaSelect ? ecIdViaSelect : estabelecimentoId
															}
															onClose={() => setShowCalculadora(false)}
															valor={data.amount / 100}
															online
														/>
													)}
												</>
											)}

											{data.id && (
												<>
													<Grid item xs={12} sm={12} md={12}>
														<ZText
															fullWidth
															label="URL"
															readOnly="readOnly"
															margin="normal"
															value={`${window.location.origin.toString()}/app/lp/${
																data.token
															}`}
															// value={`${window.location.origin.toString()}/app/venda/token/${
															// 	data.token
															// }`}
														/>
													</Grid>
												</>
											)}
										</Grid>
									</>
								)}

								{activeStep === 2 && (
									<>
										<Typography variant="h5" component="h6" className={classes.stepTitle}>
											Configurar Split
										</Typography>
										<Grid container>
											<Grid style={{ marginRight: 32, marginBottom: 32 }}>
												<Grid>
													<ZSwitch
														onChange={() => setData({ ...data, split: !data.split })}
														// onChange={() =>  true }
														label="Adicionar Split"
														checked={data.split}
													/>
												</Grid>

												{/* {data.split && (
													// <Grid>
													// 	<ZSwitch
													// 		onChange={() =>
													// 			setData(data => ({
													// 				...data,
													// 				chargeProcessingFee: !data.chargeProcessingFee,
													// 			}))
													// 		}
													// 		label={<div>Valor Líquido?</div>}
													// 		// checked={estabelecimento.chargeProcessingFee}
													// 	/>
													// </Grid>
												// )} */}
											</Grid>

											{data.split && (
												<Grid container item md={9}>
													<Grid container style={{ marginBotton: 10 }}>
														{errors.porcentagem && (
															<Grid item xs={12}>
																<div className={classes.error}>
																	O total da porcentagem deve ser menor ou igual a
																	100%
																</div>
															</Grid>
														)}
														<Grid item xs={6}></Grid>
														<Grid item xs={12} container alignItems="center">
															<Grid
																container
																direction="row"
																justifyContent={matches ? 'center' : 'flex-end'}
															>
																<Grid style={{ marginTop: 15 }}>
																	<Button
																		onClick={() => handleNewSplit()}
																		variant="contained"
																		size="small"
																		color="primary"
																		aria-label="add"
																	>
																		<AddIcon /> Estabelecimento
																	</Button>
																</Grid>
															</Grid>
														</Grid>
													</Grid>
													<Grid container spacing={3}>
														{data.splits.map((split, key) => {
															return (
																// <Paper key={key} className={classes.split}>
																// <Grid container>
																<>
																	<Grid
																		item
																		xs={12}
																		style={{ marginBottom: 10, marginTop: 15 }}
																	>
																		<Grid container spacing={3} alignItems="center">
																			<Grid item xs={12} md={3}>
																				<ZText
																					className={classes.fullWidth}
																					label="CPF / CNPJ"
																					value={
																						split.cpfcnpj.length <= 11
																							? Mask.Cpf(split.cpfcnpj)
																							: Mask.Cnpj(split.cpfcnpj)
																					}
																					onChange={(value, setValue) => {
																						value = Mask.OnlyNumber(value);
																						setValue(
																							value.length <= 11
																								? Mask.Cpf(value)
																								: Mask.Cnpj(value)
																						);
																					}}
																					onBlur={e => {
																						const v =
																							e.target.value.length <= 11
																								? Mask.Cpf(
																										e.target.value
																								  )
																								: Mask.Cnpj(
																										e.target.value
																								  );
																						updateSplit(
																							key,
																							'cpfcnpj',
																							Mask.OnlyNumber(v)
																						);
																					}}
																				/>
																			</Grid>
																			<Grid
																				style={{
																					display: 'flex',
																					alignItems: 'center',
																				}}
																				item
																				xs={12}
																				md={3}
																			>
																				{/* <IconButton
																					className={clsx(
																						classes.estabelecimentoIcone,
																						{
																							selected:
																								split.tipoSplit === 1,
																						}
																					)}
																					onClick={() =>
																						updateSplit(key, 'tipoSplit', 1)
																					}
																				>
																					<FaDollarSign
																						style={{ fontSize: 14 }}
																					/>
																				</IconButton> */}
																				<ZText
																					error={errors[`split${key}valor`]}
																					endAdornment={
																						<InputAdornment position="end">
																							%
																						</InputAdornment>
																					}
																					className={clsx(
																						classes.fullWidth,
																						classes.estabelecimentoValor,
																						{
																							porcentagem:
																								split.tipoSplit === 2,
																						}
																					)}
																					label={
																						split.tipoSplit === 1
																							? 'Valor'
																							: 'Porcentagem'
																					}
																					value={
																						split.tipoSplit === 1
																							? Mask.Real(split.valor)
																							: Mask.Porcentagem(
																									split.valor
																							  )
																					}
																					onChange={(value, setValue) => {
																						value =
																							Mask.OnlyNumber(value) /
																							100;
																						setValue(
																							split.tipoSplit === 1
																								? Mask.Real(value)
																								: value > 100
																								? Mask.Porcentagem(100)
																								: Mask.Porcentagem(
																										value
																								  )
																						);
																					}}
																					onBlur={e =>
																						updateSplit(
																							key,
																							'valor',
																							Mask.OnlyNumber(
																								e.target.value
																							) / 100
																						)
																					}
																					onFocus={e => {
																						const length =
																							e.target.value.length * 2;
																						e.target.setSelectionRange(
																							0,
																							length
																						);
																					}}
																				/>
																				{/* <IconButton
																					className={clsx(
																						classes.estabelecimentoIcone,
																						{
																							selected:
																								split.tipoSplit === 2,
																						}
																					)}
																					onClick={() => {
																						updateSplit(
																							key,
																							'tipoSplit',
																							2
																						);
																					}}
																				>
																					<FaPercentage
																						style={{ fontSize: 14 }}
																					/>
																				</IconButton> */}
																			</Grid>

																			<Grid
																				style={{
																					display: 'flex',
																					alignItems: 'center',
																					marginTop: 27,
																				}}
																				item
																				xs={12}
																				md={3}
																			>
																				<SelectEstabelecimento
																					estabelecimentoId={
																						split.estabelecimentoId
																					}
																					value={Mask.OnlyNumber(
																						split.cpfcnpj
																					)}
																					setEstabelecimento={estabelecimento => {
																						const hasEstabelecimento = Boolean(
																							estabelecimento
																						);
																						const documento = hasEstabelecimento
																							? estabelecimento.documento
																							: '';

																						updateSplit(
																							key,
																							'cpfcnpj',
																							Mask.OnlyNumber(documento)
																						);
																					}}
																				/>
																			</Grid>
																			<Grid
																				className={classes.valorLiquido}
																				style={{
																					display: 'flex',
																					alignItems: 'center',
																				}}
																				item
																				xs={12}
																				md={2}
																			>
																				{key > 0 && (
																					<Grid item lg={1} sm={1} xs={1}>
																						<Fab
																							size="small"
																							className={
																								classes.removeButton
																							}
																							onClick={() =>
																								handleRemoveSplit(key)
																							}
																						>
																							<RemoveIcon
																								size="small"
																								style={{ fontSize: 15 }}
																							/>
																						</Fab>
																					</Grid>
																				)}
																			</Grid>

																			{split.estabelecimentoId !== null && (
																				<>
																					<Grid
																						item
																						xs={6}
																						style={{
																							padding: 0,
																							marginTop: -10,
																						}}
																					>
																						<div className={classes.label}>
																							{split.nome}
																						</div>
																					</Grid>
																					<Grid
																						item
																						xs={6}
																						style={{
																							padding: 0,
																							marginTop: -10,
																						}}
																					>
																						<div className={classes.label}>
																							{split.email}
																						</div>
																					</Grid>
																				</>
																			)}
																			{Isset(() => errors[`split${key}`]) && (
																				<Grid
																					item
																					xs={6}
																					style={{
																						padding: 0,
																						marginTop: -10,
																					}}
																				>
																					<div
																						className={clsx(
																							classes.label,
																							classes.estabelecimentoError
																						)}
																					>
																						{errors[`split${key}`]}
																					</div>
																				</Grid>
																			)}
																		</Grid>
																	</Grid>
																</>
															);
														})}
													</Grid>
												</Grid>
											)}
										</Grid>
									</>
								)}

								{activeStep === 3 && (
									<Grid container style={{ marginLeft: 32, position: 'relative' }}>
										{match.params.preVendaId && (
											<div
												ref={qrCodeRef}
												style={{
													position: 'absolute',
													right: 20,
													height: 128,
													cursor: 'pointer',
												}}
												onClick={downloadQRCode}
											>
												<QRCode
													renderAs="svg"
													value={
														isNewLink
															? cleanedUrl
															: `https://${url}${config.get('baseName')}/lp/${data.token}`
													}
												/>
												<div className={classes.qrcodeHover}>
													<FaDownload />
												</div>
											</div>
										)}
										<Grid item style={{ marginBottom: 16 }}>
											<Typography variant="h5" component="h5" gutterBottom>
												{data.id ? `ID - ${data.id} ` : `Resumo`}
											</Typography>
										</Grid>
										<Grid container spacing={2} item>
											<Grid item md={6} xs={12} className={classes.labelResumo}>
												<Typography variant="body1" component="h5" gutterBottom>
													Titulo <br /> <span className={classes.span}>{data.titulo}</span>
												</Typography>
											</Grid>
											<Grid item md={6} xs={12} className={classes.labelResumo}>
												<Typography variant="body1" component="h5" gutterBottom>
													Valor <br />{' '}
													<span className={classes.span}>{formattedData.amount}</span>
												</Typography>
											</Grid>
											<Grid item md={6} xs={12} className={classes.labelResumo}>
												<Typography variant="body1" component="h5" gutterBottom>
													Descrição <br />{' '}
													<span className={classes.span}>{data.descricao}</span>
												</Typography>
											</Grid>
											<Grid item md={6} xs={12} className={classes.labelResumo}>
												<Typography variant="body1" component="h5" gutterBottom>
													E-mail <br /> <span className={classes.span}>{data.email}</span>
												</Typography>
											</Grid>
											<Grid item md={6} xs={12} className={classes.labelResumo}>
												<Typography variant="body1" component="h5" gutterBottom>
													Meio de pagamento <br />{' '}
													<span className={classes.span}>{formattedData.payment}</span>
												</Typography>
											</Grid>
											<Grid item md={6} xs={12} className={classes.labelResumo}>
												<Typography variant="body1" component="h5" gutterBottom>
													Data de expiração <br />{' '}
													<span className={classes.span}>{formattedData.date}</span>
												</Typography>
											</Grid>
											{data.juros && (
												<Grid item md={6} xs={12} className={classes.labelResumo}>
													<Typography variant="body1" component="h5" gutterBottom>
														Percentual de juros <br />{' '}
														<span className={classes.span}>{formattedData.percentual}</span>
													</Typography>
												</Grid>
											)}
											<Grid item md={6} xs={12} className={classes.labelResumo}>
												<Typography variant="body1" component="h5" gutterBottom>
													Exibir Logo <br />{' '}
													<span className={classes.span}>{formattedData.logo}</span>
												</Typography>
											</Grid>

											{data.juros && (
												<Grid item md={6} xs={12} className={classes.labelResumo}>
													<Typography variant="body1" component="h5" gutterBottom>
														Juros a partir <br />{' '}
														<span className={classes.span}>
															{formattedData.juros_a_partir}
														</span>
													</Typography>
												</Grid>
											)}

											<Grid item md={6} xs={12} className={classes.labelResumo}>
												<Typography variant="body1" component="h5" gutterBottom>
													Máximo de parcelas <br />{' '}
													<span className={classes.span}>{formattedData.parcel}</span>
												</Typography>
											</Grid>

											<Grid item md={6} xs={12} className={classes.labelResumo}>
												<Typography variant="body1" component="h5" gutterBottom>
													Repassar Taxa ao Cliente? <br />{' '}
													<span className={classes.span}>
														{formattedData.repassarTaxaCliente}
													</span>
												</Typography>
											</Grid>
											{data.url_redirect && (
												<Grid item md={6} xs={12} className={classes.labelResumo}>
													<Typography variant="body1" component="h5" gutterBottom>
														Url de redirecionamento <br />{' '}
														<span className={classes.span}>
															<a href={data.url_redirect}>{data.url_redirect}</a>
														</span>
													</Typography>
												</Grid>
											)}

											{data.token && (
												<Grid item md={12} xs={12} className={classes.labelResumo}>
													<Typography variant="body1" component="h4" gutterBottom>
														Link <br />{' '}
														<span className={classes.span}>
															{isNewLink
																? `https://${url}/link?${data.token}`
																: `https://${url}${config.get('baseName')}/lp/${
																		data.token
																  }`}{' '}
														</span>
														<Tooltip title="Copiar">
															<Button
																className={clsx(classes.buttonCopy, classes.iconButton)}
																onClick={() => {
																	let copyText;
																	if (isNewLink) {
																		copyText = `https://${url}/link?${data.token}`;
																	} else {
																		copyText = `https://${url}${config.get(
																			'baseName'
																		)}/lp/${data.token}`;
																	}
																	if (navigator.clipboard) {
																		navigator.clipboard.writeText(copyText).then(
																			() => {
																				console.log('copy success');
																			},
																			error => {
																				console.log('Error: ' + error);
																				document.execCommand('copy');
																			}
																		);
																	} else {
																		console.log(document.execCommand('copy'));
																		document.execCommand('copy');
																	}
																}}
															>
																<FaCopy className={classes.copy} />
															</Button>
														</Tooltip>
													</Typography>
												</Grid>
											)}
										</Grid>

										{data.split && (
											<>
												<Grid item xs={12} style={{ marginTop: 32, marginBottom: 16 }}>
													<Typography variant="h5" component="h5" gutterBottom>
														Splits
													</Typography>
												</Grid>
												<Grid container spacing={2} md={12}>
													{data.splits.map((split, key) => (
														<>
															<Grid
																key={key}
																item
																md={6}
																xs={12}
																className={classes.labelResumo}
															>
																<Typography variant="body1" component="h5" gutterBottom>
																	{split.nome_fantasia
																		? 'Estabelecimento'
																		: 'CPF/CNPJ'}{' '}
																	<br />{' '}
																	<span className={classes.span}>
																		{split.nome_fantasia
																			? split.nome_fantasia
																			: split.cpfcnpj.length <= 11
																			? Mask.Cpf(split.cpfcnpj)
																			: Mask.Cnpj(split.cpfcnpj)}
																	</span>
																</Typography>
															</Grid>
															<Grid
																key={key}
																item
																md={6}
																xs={12}
																className={classes.labelResumo}
															>
																<Typography variant="body1" component="h5" gutterBottom>
																	Percentual de split
																	<br />{' '}
																	<span className={classes.span}>
																		{split.tipoSplit === 1
																			? Mask.Real(split.valor)
																			: `${Mask.Porcentagem(split.valor)}%`}
																	</span>
																</Typography>
															</Grid>
														</>
													))}
												</Grid>
											</>
										)}
									</Grid>
								)}
								<Grid container item spacing={3} xs={12} md={6} lg={6} className={classes.divButton}>
									<Grid item xs={12} md={6} lg={6}>
										{activeStep === 3 && data.id ? (
											<Button
												className={classes.button}
												onClick={() => {
													let text;
													if (isNewLink) {
														text = encodeURIComponent(
															`Link de Pagamento - ${
																data.nome_fantasia
															}\n\n*${data.titulo.trim()}*\nhttps://${url}/link?${
																data.token
															}`
														);
													} else {
														text = encodeURIComponent(
															`Link de Pagamento - ${
																data.nome_fantasia
															}\n\n*${data.titulo.trim()}*\nhttps://${url}${config.get(
																'baseName'
															)}/lp/${data.token}`
														);
													}

													window.open(`https://web.whatsapp.com/send?text=${text}`, '_blank');
												}}
												variant="contained"
												fullWidth
											>
												Enviar Via Whatsapp
											</Button>
										) : (
											<Button
												className={classes.backButton}
												disabled={!props.inNovaVenda && activeStep === 0}
												onClick={handleBack}
												variant="outlined"
												fullWidth
											>
												Voltar
											</Button>
										)}
									</Grid>
									{activeStep < 3 && (
										<Grid item xs={12} md={6}>
											<Button
												className={classes.button}
												onClick={handleNext}
												fullWidth
												color={'primary'}
												variant="contained"
											>
												Continuar
											</Button>
										</Grid>
									)}
									{activeStep === 3 && !data.id && (
										<Grid item xs={12} md={6}>
											<Button
												className={classes.button}
												onClick={() => handleSubmitForm()}
												fullWidth
												color={'primary'}
												variant="contained"
												disabled={loading}
											>
												Salvar
											</Button>
										</Grid>
									)}
									{activeStep === 3 && data.id && (
										<Grid item xs={12} md={6}>
											<Button
												className={classes.button}
												onClick={() => {
													if (isNewLink) {
														window.open(`/link?${data.token}`, '_blank');
													} else {
														window.open(`/lp/${data.token}`, '_blank');
													}
												}}
												fullWidth
												color={'primary'}
												variant="contained"
											>
												Realizar Pagamento
											</Button>
										</Grid>
									)}
								</Grid>
							</Paper>
						</Grid>
					</Grid>
				</Grid>

				{/* <Grid item xs={12} style={{ marginTop: 10 }}>
					<Grid container justifyContent="center">
						{data.id && (
							<Grid item xs={12} sm={4} md={4}>
								<div style={{ textAlign: 'center' }}>
									<Tooltip title="Compartilhar no Whatsapp">
										<span>
											<FaWhatsapp
												className={classes.menuIcon}
												onClick={() => {
													const text = encodeURIComponent(
														`Link de Pagamento - ${
															data.nome_fantasia
														}\n\n*${data.titulo.trim()}*\nhttps://${url}${config.get(
															'baseName'
														)}/venda/token/${data.token}`
													);
													window.open(`https://web.whatsapp.com/send?text=${text}`, '_blank');
												}}
											/>
										</span>
									</Tooltip>
									<Tooltip title="Enviar por E-mail">
										<span>
											<FaShare
												className={classes.menuIcon}
												onClick={() => {
													const text = encodeURIComponent(
														`Link de Pagamento - ${
															data.nome_fantasia
														}\n\n*${data.titulo.trim()}*\nhttps://${url}${config.get(
															'baseName'
														)}/venda/token/${data.token}`
													);
													window.open(`mailto:?subject=${text}`, '_blank');
												}}
											/>
										</span>
									</Tooltip>
								</div>
							</Grid>
						)}
					</Grid>
				</Grid> */}

				{data.id && data.pedidos.length > 0 && <ListaDePedidosPreVenda preVendaId={data.id} />}
			</Grid>

			<ZModalWarning
				title={`Atenção`}
				description={'Selecione um estabelecimento abaixo.'}
				content={
					<div className={classes.modalSelectEstabelecimento}>
						<FormControl className={classes.formControl}>
							<InputLabel id="demo-simple-select-label">Estabelecimentos</InputLabel>
							<Select
								className={classes.SelectEstabelecimento}
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={ecSelected}
								onChange={e => setEcSelected(e.target.value)}
							>
								{ecsOfTheDocument &&
									ecsOfTheDocument.map(e => {
										return (
											<MenuItem key={e.id} value={e.id}>
												{e.nome_fantasia}
											</MenuItem>
										);
									})}
							</Select>
						</FormControl>
					</div>
				}
				open={showModalEstablishmentSelection}
				handleClose={() => setShowModalEstablishmentSelection(false)}
				button={
					<Button onClick={handleSetEstabelecimento} color="primary">
						Confirmar
					</Button>
				}
			/>

			<ConfirmDialog
				open={showConfirmModal}
				dialogContent={dialogContent}
				handleClose={() => setShowConfirmModal(false)}
			/>
			<ShareDialog
				open={showShareModal}
				handleClose={() => {
					setShowShareModal(false);
					history.push('/venda-digitada/' + successPreVenda.id);
				}}
				handleSuccess={handleSuccess}
			/>
		</Container>
	);
};
