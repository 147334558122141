/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ZLoader from '../system/ZLoader';
import ZFiltros from '../system/ZFiltros';
import ZEmpty from '../system/ZEmpty';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import config from 'react-global-configuration';
import { Isset, Formatter } from '../../utils/';
import FileDownload from 'js-file-download';
import TablePagination from '@material-ui/core/TablePagination';
import { makeStyles } from '@material-ui/core/styles';
import { addMessage } from '../../store/alert';
import { useHistory, useLocation } from 'react-router-dom';

import { FaDownload } from 'react-icons/fa';
import { Button, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import LastExport from '../Export/LastExport';

const useStyles = makeStyles(theme => ({
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
	},
	export: {
		position: 'absolute',
		right: 10,
		opacity: 0.5,
		cursor: 'pointer',
		color: '#464ae6',
		fontSize: 16,
		transition: theme.transitions.create(['all'], {
			duration: theme.transitions.duration.complex,
		}),
		'&:hover': {
			fontSize: 18,
			opacity: 1,
		},
	},
	tableHeader: {
		textAlign: 'center',
		fontWeight: 700,
		fontSize: 18,
	},
	tableSubHeader: {
		textAlign: 'center',
		fontWeight: 700,
		fontSize: 16,
	},
	tableDateRow: {
		fontWeight: 700,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 10,
		backgroundColor: `${theme.palette.primary.main}aa`,
		color: theme.palette.getContrastText(theme.palette.primary.main),
		paddingLeft: 20,
	},
	tableRow: {
		textAlign: 'center',
		fontWeight: 700,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 20,
		cursor: 'pointer',
		marginBottom: 2,
		backgroundColor: `${theme.palette.primary.main}1a`,
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}2b`,
		},
	},
	exportDownload: {
		color: '#011D29',
		fontSize: 16,
		marginLeft: 8,
	},
	iconButton: {
		minWidth: 0,
	},
	buttonExport: {
		color: '#040633',
		border: `solid 1px #040633`,
		'&:hover': {
			color: '#FFF',
			backgroundColor: '#011d29',
			'& svg': {
				color: '#FFF',
			},
		},
		[theme.breakpoints.down('xs')]: {
			width: '90%',
			marginTop: 16,
		},
		'& svg': {
			margin: 0,
		},
	},
	tableSubRow: {
		textAlign: 'center',
		fontWeight: 700,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 20,
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}2b`,
		},
	},
	tableRowSelected: {
		backgroundColor: `${theme.palette.primary.main}3c`,
	},
	vendas: {
		marginBottom: 10,
	},
}));

export default props => {
	const classes = useStyles();

	const dispatch = useDispatch();

	const history = useHistory();
	const token = useSelector(store => store.auth.token);
	const usuario = useSelector(store => store.auth.usuario);
	const [exportInProgress, setExportInProgress] = useState(false);

	const { pathname } = useLocation();
	const pathnameSlug = pathname.toLowerCase().replace(/[^a-z0-9]/gi, '');

	const filtersCache = useSelector(store => store.filters[pathnameSlug] || {});

	let { id: estabelecimentoId } = useSelector(state => state.admin.estabelecimento) || {};

	const queryFilter = {
		data_venda: { de: new Date(), ate: new Date() },
		estabelecimentoId: undefined, //estabelecimentoId ? [estabelecimentoId] : [],
		omniEstabelecimento: '',
		...filtersCache,
	};

	const [filtros, setFiltros] = useState(queryFilter);
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [showLastExport, setShowLastExport] = useState(false);

	const filtrosChange = async data => {
		data.data_venda = {
			de: data.date.start,
			ate: data.date.end,
		};
		data.estabelecimentoId =
			Isset(() => data.estabelecimento) && Array.isArray(data.estabelecimento) && data.estabelecimento.length > 0
				? [...data.estabelecimento]
				: [];

		// if(data.omniEstabelecimento){
		// 	data.omniEstabeelciemnto = data.omniEstabelecimento;
		// }
		// if (estabelecimentoId) {
		// 	data.estabelecimentoId.push(estabelecimentoId);
		// }
		setPagination({
			...pagination,
			current: 0,
		});
		setFiltros(data);
	};

	const [pagination, setPagination] = useState({
		limit: 100,
		current: 0,
		totalRows: 0,
	});

	useEffect(() => {
		if (estabelecimentoId !== filtros.estabelecimentoId) {
			setFiltros({
				...filtros,
				estabelecimentoId,
			});
		}
	}, [estabelecimentoId]);

	useEffect(() => {
		const getData = async () => {
			setLoading(true);
			setData([]);
			const filters = {};

			if (filtros.estabelecimentoId && filtros.estabelecimentoId.length > 0) {
				filters.estabelecimentoId = filtros.estabelecimentoId;
			}
			if (filtros.data_venda.de) {
				filters.startDate = filtros.data_venda.de;
			}
			if (filtros.data_venda.ate) {
				filters.endDate = filtros.data_venda.ate;
			}

			if (filtros.omniEstabelecimento) {
				filters.omniEstabelecimento = filtros.omniEstabelecimento;
			}

			const res = await axios.post(
				`${config.get('apiUrl')}graphql`,
				{
					query: `
                    query vendasPorEC($filters: Filter, $pagination: Pagination) {
						vendasPorEC(filters: $filters, pagination: $pagination) {
							estabelecimentos {
								id, nomeFantasia, quantidadeVendas, valorTotalVendas, valorTotalVendasRecebido, 
							}
							pagination {
								limit, current, totalRows
							}
							
						},

					}
                `,
					variables: {
						filters,
						pagination,
					},
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			if (res.status === 200 && Isset(() => res.data.data.vendasPorEC)) {
				let ecs = res.data.data.vendasPorEC.estabelecimentos;
				let pagination = res.data.data.vendasPorEC.pagination;
				console.log(pagination);
				let vendas = [];

				if (Isset(() => res.data.data.vendas)) {
					vendas = res.data.data.vendas;
				}

				ecs = ecs.map(a => {
					a.vendas = vendas.filter(b => +b.estabelecimentoId === +a.id);
					return a;
				});

				setData(ecs);
				setPagination({ ...pagination });
			}
			setLoading(false);
		};

		getData();
		console.log('getting data');
	}, [filtros, pagination.current, pagination.limit]);

	const exportXlsClick = () => {
		setExportInProgress(true); // Bloqueia o botão de exportação
		setShowLastExport(true); // Abre o modal de exportação
	};

	const exportXls = async getLastExports => {
		const filters = {};

		if (filtros.estabelecimentoId && filtros.estabelecimentoId.length > 0) {
			filters.estabelecimentoId = filtros.estabelecimentoId;
		}
		if (filtros.data_venda.de) {
			filters.startDate = filtros.data_venda.de;
		}
		if (filtros.data_venda.ate) {
			filters.endDate = filtros.data_venda.ate;
		}

		if (filtros.omniEstabelecimento) {
			filters.omniEstabelecimento = filtros.omniEstabelecimento;
		}

		try {
			dispatch(addMessage({ type: 'warning', message: 'Sua nova exportação está sendo processada.' }));

			const vendas = await axios.get(`${config.get('apiUrl')}export/relatorios/vendasPorEstabelecimento`, {
				headers: { Authorization: `Bearer ${token}` },
				responseType: 'blob',
				params: {
					filters,
				},
			});
		} catch (error) {
			console.error('Erro ao exportar os dados:', error);
			dispatch(
				addMessage({
					type: 'error',
					message: 'Erro ao exportar os dados. Tente novamente ou contate o suporte.',
				})
			);
		}
	};

	const newExport = async getLastExports => {
		try {
			await exportXls(getLastExports); // Executa a exportação
		} catch (error) {
			console.error('Erro ao processar nova exportação:', error);
		} finally {
			setExportInProgress(false); // Libera o botão após finalizar as operações
		}
	};

	return (
		<Grid className={classes.vendas}>
			<div className={classes.header}>Vendas Por Estabelecimento</div>

			<Paper
				style={{
					position: 'relative',
					marginBottom: 10,
					borderRadius: 4,
					padding: '5px 10px',
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<ZFiltros
					onChange={filtrosChange}
					dateRange={{
						default: 'custom',
						startDate: filtros.data_venda.de,
						endDate: filtros.data_venda.ate,
					}}
					input={[
						{
							key: 'omniEstabelecimento',
							defaultValue: filtros.omniEstabelecimento,
							placeholder: 'Estabelecimento',
							modal: false,
							md: 3,
						},
					]}
				/>
				{usuario.grupo_id !== 7 && (
					<Tooltip title="Exportar">
						<Button className={clsx(classes.buttonExport, classes.iconButton)} onClick={exportXlsClick}>
							<FaDownload className={classes.exportDownload} />
						</Button>
					</Tooltip>
				)}
			</Paper>

			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper style={{ padding: 15 }}>
						{loading ? (
							<ZLoader height={200} />
						) : (
							<>
								{data.length > 0 ? (
									<Grid container className={classes.vendas}>
										<Grid container item className={classes.tableHeader}>
											<Grid item xs={4}>
												Estabelecimento
											</Grid>
											<Grid item xs={2}>
												Quantidade
											</Grid>
											<Grid item xs={3}>
												Valor
											</Grid>
											<Grid item xs={3}>
												Valor Recebido
											</Grid>
										</Grid>
										{data.map((ec, key) => (
											<React.Fragment key={key}>
												<Grid
													container
													item
													className={classes.tableRow}
													onClick={() => {
														history.push(
															`/vendas?estabelecimentoNome=${encodeURIComponent(
																ec.nomeFantasia
															)}`
														);
													}}
												>
													<Grid item xs={4}>
														{ec.nomeFantasia}
													</Grid>
													<Grid item xs={2}>
														{ec.quantidadeVendas}
													</Grid>
													<Grid item xs={3}>
														{Formatter.Real(ec.valorTotalVendas)}
													</Grid>
													<Grid item xs={3}>
														{Formatter.Real(ec.valorTotalVendasRecebido)}
													</Grid>
												</Grid>
											</React.Fragment>
										))}
										<Grid item xs={12}>
											<TablePagination
												rowsPerPageOptions={[25, 50, 100]}
												component="div"
												count={pagination.totalRows}
												rowsPerPage={pagination.limit}
												page={pagination.current}
												onChangePage={(e, page) =>
													setPagination({ ...pagination, current: page })
												}
												onChangeRowsPerPage={e => {
													const totalRows = e.target.value * pagination.limit;
													if (totalRows > pagination.totalRows) {
														setPagination({
															...pagination,
															limit: e.target.value,
															current: 0,
														});
													} else {
														setPagination({ ...pagination, limit: e.target.value });
													}
												}}
												labelRowsPerPage={'Linhas por página:'}
												labelDisplayedRows={({ from, to, count }) =>
													`${from}-${to === -1 ? count : to} de ${count}`
												}
											/>
										</Grid>
									</Grid>
								) : (
									<ZEmpty height={200} text="Nenhuma Venda Encontrada." />
								)}
							</>
						)}
						{showLastExport && (
							<LastExport
								show={showLastExport}
								onClose={() => setShowLastExport(false)}
								url={'/export/relatorios/vendasPorEstabelecimento'}
								newExport={newExport}
								loadingProp={exportInProgress}
							/>
						)}
					</Paper>
				</Grid>
			</Grid>
		</Grid>
	);
};
