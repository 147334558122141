/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import useClientIp from '../../hooks/useClientIp';
import { makeStyles } from '@material-ui/core';
import axios from 'axios';
import config from 'react-global-configuration';
import { addMessage } from '../../store/alert';

import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import format from 'date-fns/format';
import addDays from 'date-fns/addDays';
import parse from 'date-fns/parse';

import { Isset } from '../../utils';
import ZTitle from '../system/ZTitle';

import { FormaPagamento, MetodoPagamento } from './FormaPagamento';
import DadosCliente from './DadosCliente';
import ConfigurarSplit from './ConfigurarSplit';
import DescontoBoleto from './DescontoBoleto';
import Resumo from './Resumo';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';
import { isValid } from 'date-fns';

const useStyles = makeStyles(theme => ({
	novaVenda: {
		width: 'auto',
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		[theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
			width: 1280,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	paper: {
		marginBottom: theme.spacing(3),
		padding: theme.spacing(2),
		[theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
			marginBottom: theme.spacing(6),
			padding: theme.spacing(3),
		},
	},
	content: {
		padding: 10,
	},
}));

const initialState = {
	// Pix
	valorPix: 0,
	// Boleto
	valorBoleto: 0,
	dataVencimento: addDays(new Date(), 5),
	descricao: '',
	// Cartão
	valorCartao: 0,
	numeroCartao: '',
	titularCartao: '',
	vencimentoCartao: '',
	cvcCartao: '',
	parcelasCartao: '',
	// Cliente
	id: null,
	cpf: '',
	nome: '',
	email: '',
	dataNascimento: '',
	celular: '',
	// Endereco
	logradouro: '',
	numero: '',
	cep: '',
	cidade: '',
	estado: '',
	bairro: '',
	complemento: '',
	chargeProcessingFee: false,
	splits: [
		{
			estabelecimentoId: false,
			cpfcnpj: '',
			nome: '',
			email: '',
			value: 0,
			tipoSplit: 2,
		},
	],
	descontos: [
		{
			mode: '',
			value: 0,
			limitDate: '',
		},
	],
};

export default props => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const history = useHistory();
	const ip = undefined; //useClientIp();
	const usuario = useSelector(store => store.auth.usuario);
	const isElo7 = usuario.marketplace.id === 129;
	const canSplit = usuario.can_split;
	const estabelecimento = useSelector(state => state.auth.estabelecimento);
	const estabelecimentoAdmin = useSelector(state => state.admin.estabelecimento);
	const token = useSelector(state => state.auth.token);
	const url = useSelector(store => store.system.config.hostname);

	const estabelecimentoId = Isset(() => estabelecimentoAdmin.id) ? estabelecimentoAdmin.id : estabelecimento.id;

	const [sending, setSending] = useState(false);
	const [activeStep, setActiveStep] = useState(0);
	const [data, setData] = useState({
		// Pix
		valorPix: 0,
		// Boleto
		valorBoleto: 0,
		dataVencimento: addDays(new Date(), 5),
		dataLimite: addDays(new Date(), 60),
		descricao: '',
		bolepix: false,
		sendEmail: false,
		// Cartão
		valorCartao: 0,
		numeroCartao: '',
		titularCartao: '',
		vencimentoCartao: '',
		cvcCartao: '',
		parcelasCartao: '',
		// Cliente
		id: null,
		cpf: '',
		nome: '',
		email: '',
		dataNascimento: '',
		celular: '',
		// Endereco
		logradouro: '',
		numero: '',
		cep: '',
		cidade: '',
		estado: '',
		complemento: '',
		bairro: '',
		chargeProcessingFee: false,
		splits: [
			{
				estabelecimentoId: false,
				cpfcnpj: '',
				nome: '',
				email: '',
				value: 0,
				tipoSplit: 2,
			},
		],
		descontos: [
			{
				mode: '',
				value: 0,
				limitDate: '',
			},
		],
	});

	const repasseSindicato = useSelector(state =>
		Isset(() => state.auth.estabelecimento)
			? state.auth.estabelecimento.marketplace.configuracoes.repasse_sindicato
			: null
	);

	const [errors, setErrors] = useState({});
	const [clientes, setClientes] = useState([]);
	const [split, setSplit] = useState();

	useEffect(() => {
		const getCliente = async () => {
			const response = await axios.get(`${config.get('apiUrl')}clientes/ultimas-vendas`, {
				headers: { Authorization: `Bearer ${token}` },
			});

			const { success, clientes } = response.data;

			if (success && clientes) {
				setClientes(clientes);
			}
		};

		checkSplit();
		getCliente();
	}, []);

	const validate = step => {
		const e = {};

		if (['all', 0].includes(step)) {
			if (data.formaPagamento === 1) {
				if (!data.valorBoleto || data.valorBoleto < 5) {
					dispatch(addMessage({ type: 'error', message: 'O valor mínimo é R$5,00' }));
					e.valorBoleto = true;
				}
				if (!data.dataVencimento) {
					e.dataVencimento = true;
				}
			} else if ([3, 6, 12].includes(data.formaPagamento)) {
				if (!data.valorCartao || data.valorCartao <= 0) e.valorCartao = true;
				if (!data.numeroCartao) e.numeroCartao = true;
				if (!data.titularCartao) e.titularCartao = true;
				if (!data.vencimentoCartao) e.vencimentoCartao = true;
				if (!data.cvcCartao) e.cvcCartao = true;
				if (!data.parcelasCartao) e.parcelasCartao = true;
			} else if (data.formaPagamento === 5 && !data.valorPix) {
				e.valorPix = true;
			}
		}

		if (['all', 1].includes(step) && data.formaPagamento !== 12) {
			if (data.formaPagamento !== 5 || (data.formaPagamento === 5 && data.cpf)) {
				if (!data.cpf) e.cpf = true;
				if (!data.celular) e.celular = true;
				if (!data.nome) e.nome = true;
				if (!data.email) e.email = true;

				if (
					!isValid(
						new Date(
							data.dataNascimento
								.split('/')
								.reverse()
								.join('-')
						)
					)
				) {
					e.dataNascimento = true;
				}

				if (!data.logradouro) e.logradouro = true;
				if (!data.numero) e.numero = true;
				if (!data.cep) e.cep = true;
				if (!data.cidade) e.cidade = true;
				if (!data.estado) e.estado = true;
				if (!data.bairro) e.bairro = true;
			}
		}
		setErrors(e);
		return Object.keys(e).length > 0;
	};

	const changeStep = step => {
		if (data.formaPagamento === 9 && activeStep === 1) {
			setActiveStep(3);
		}
		if (step === 0) {
			setActiveStep(0);
		}
		if (step === 4 && !validate(0)) {
			if (split !== 0 || !canSplit) {
				setActiveStep(5);
			} else {
				setActiveStep(4);
			}
		}
		if (step === 2 && !validate(1)) {
			setActiveStep(2);
		}
	};

	const getCountryFromIp = async () => {
		try {
			const response = await fetch('https://ipapi.co/json/');
			const data = await response.json();
			return data.country;
		} catch (error) {
			console.error('Erro ao detectar a região', error);
			return 'undefined';
		}
	};
	const handleSubmit = async props => {
		const country = await getCountryFromIp();

		setSending(true);

		if (!validate('all')) {
			let vendaData = {
				estabelecimentoId,
				tipoPagamentoId: data.formaPagamento,
				splits: data.splits,
				descontos: data.descontos,
			};

			let newSplits = vendaData.splits.map((value, key) => {
				return { ...value, chargeProcessingFee: true }; //data.chargeProcessingFee };
			});
			if (data.formaPagamento === 1) {
				vendaData = {
					...vendaData,
					email: props,
					valor: data.valorBoleto,
					dataVencimento: format(data.dataVencimento, 'YYYY-MM-DD'),
					dataLimite: format(data.dataLimite, 'YYYY-MM-DD'),
					descricao: data.descricao,
					bolepix: data.bolepix,
				};
			} else if (data.formaPagamento === 3) {
				vendaData = {
					...vendaData,
					valor: data.valorCartao,
					parcelas: data.parcelasCartao,
					cartao: {
						titular: data.titularCartao,
						numero: data.numeroCartao,
						codigoSeguranca: data.cvcCartao,
						validade: format(parse(data.vencimentoCartao, 'MMYY', new Date()), 'MM/YYYY'),
					},
				};
			} else if (data.formaPagamento === 12) {
				const expirationDate = parse(data.vencimentoCartao, 'MMYY', new Date());
				const expirationMonth = format(expirationDate, 'MM');
				const expirationYear = format(expirationDate, 'YYYY');

				vendaData = {
					description: data.descricao,
					amount: data.valorCartao * 100, //centavos
					card: {
						brand: data.brand || '',
						cardNumber: data.numeroCartao,
						holderName: data.titularCartao,
						expirationMonth,
						expirationYear,
						securityCode: data.cvcCartao,
					},
					installments: data.parcelasCartao,
					splits:
						data.splits && data.splits.length > 0
							? data.splits.map(split => ({
									cpfcnpj: split.cpfcnpj,
									estabelecimentoId: split.estabelecimentoId,
									email: split.email,
									nome: split.nome,
									tipoSplit: split.tipoSplit,
									valor: split.valor || split.value,
							  }))
							: [],
				};
			} else if (data.formaPagamento === 5) {
				vendaData = {
					...vendaData,
					valor: data.valorPix,
					descricao: data.descricao,
				};
			} else if (data.formaPagamento === 9) {
				vendaData = {
					...vendaData,
					valor: data.valorPix,
					descricao: data.descricao,
				};
			}
			if (Isset(() => data.id) && data.id !== null) {
				vendaData.clienteId = data.id;
			} /*else {*/
			if (data.formaPagamento !== 12) {
				vendaData = {
					...vendaData,
					cliente: {
						nome: data.nome,
						cpf: data.cpf,
						dataNascimento: moment(data.dataNascimento, 'DD/MM/YYYY').format('YYYY-MM-DD'),
						email: data.email,
						celular: data.celular,
					},
					endereco: {
						logradouro: data.logradouro,
						numero: data.numero,
						cep: data.cep,
						cidade: data.cidade,
						estado: data.estado,
						bairro: data.bairro,
						complemento: data.complemento,
					},
					ip,
				};
			}
			if (repasseSindicato && data.formaPagamento !== 12) {
				let splits = [];
				let valorFinal = 0;
				const repasses = await axios.get(
					`${config.get('apiV2Url')}transaction/check-split-pass-fees/${data.valorBoleto * 100}`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				);

				splits = repasses.data.splits.map(value => {
					return {
						estabelecimentoId: value.establishmentId,
						tipoSplit: value.tipo,
						valor: value.valor,
						chargeProcessingFee: value.chargeProcessingFee,
					};
				});
				valorFinal = repasses.data.amount;

				if (newSplits[0].estabelecimentoId) {
					splits.map(value => {
						newSplits.push(value);
					});
				} else {
					newSplits = splits;
				}

				if (valorFinal > vendaData.valor) {
					vendaData.valor = valorFinal;
				}

				vendaData.splits = newSplits;
			}

			let uri =
				data.formaPagamento !== 12
					? `${config.get('apiUrl')}vendas`
					: `${config.get('apiV2Url')}transaction/direct-authorization`;

			if (data.formaPagamento === 1) {
				uri = `${config.get('apiV2Url')}transaction`;
				vendaData = {
					bolepix: data.bolepix,
					establishmentSelect: estabelecimentoId,
					gatewayProvider: 'zoop',
					amount: Math.round(data.valorBoleto * 100),
					description: data.descricao,
					installments: 1,
					expirationDate: format(data.dataVencimento, 'YYYY-MM-DD'),
					paymentLimitDate: format(data.dataLimite, 'YYYY-MM-DD'),
					paymentType: 'bankslip',
					discounts:
						data.descontos &&
						data.descontos.map(des => {
							if (des.mode.length > 0) {
								return {
									mode: des.mode,
									value: des.value,
									limitDate: format(des.limitDate, 'YYYY-MM-DD'),
								};
							}
						}),
					client: {
						dbId: data.id,
						name: data.nome,
						email: data.email,
						phone: data.celular,
						identificationDocument: data.cpf,
						typeDocument: 2,
						contact: data.celular,
						typeContact: 2,
						birthDate: moment(data.dataNascimento, 'DD/MM/YYYY').format('YYYY-MM-DD'),
						address: {
							street: data.logradouro,
							number: data.numero,
							neighborhood: data.bairro,
							postalCode: data.cep,
							complement: data.complemento,
							state: data.estado,
							city: data.cidade,
						},
					},
					splits: vendaData.splits,
					email: props,
				};
			}
			try {
				const result = await axios.post(uri, vendaData, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				if (result.data.success) {
					let paramsId = data.formaPagamento === 1 ? result.data.transaction?.dbId : result.data.pedido?.id;

					dispatch(addMessage({ type: 'success', message: 'Operação realizada com sucesso' }));
					setData(initialState);
					history.push('/vendas/detalhes/' + paramsId);
				} else {
					setSending(false);
					if (result.data.message === 'Sorry, the card zero dollar validation has failed.') {
						dispatch(
							addMessage({
								type: 'error',
								message: 'Desculpe, a validação do cartão de zero dólar falhou.',
							})
						);
					} else {
						dispatch(addMessage({ type: 'error', message: result.data.message }));
					}
				}
			} catch (err) {
				console.error(err);
				setSending(false);
				if (data.formaPagamento === 1) {
					return dispatch(addMessage({ type: 'error', message: err.response.data.error }));
				}
				dispatch(
					addMessage({
						type: 'error',
						message: err.message ? err.message : 'Houve um erro ao realizar a operação',
					})
				);
			}
		}
		setSending(false);
	};

	const checkSplit = async () => {
		const res = await Axios.get(`${config.get('apiUrl')}vendas/${estabelecimentoId}/verify-splits`, {
			headers: { Authorization: `Bearer ${token}` },
		});
		if (res.data.success) {
			setSplit(res.data.splits);
		}
	};
	return (
		<div className={classes.novaVenda}>
			<ZTitle title={isElo7 ? 'Depósito' : 'Nova Venda'} marginBottom={40} />

			<Paper className={classes.paper}>
				<div className={classes.content}>
					{activeStep === 0 && <FormaPagamento data={data} setData={setData} setActiveStep={setActiveStep} />}

					{activeStep === 1 && (
						<DadosCliente
							clientes={clientes}
							data={data}
							setData={setData}
							errors={errors}
							changeStep={changeStep}
						/>
					)}

					{activeStep === 2 && (
						<MetodoPagamento
							split={split}
							data={data}
							setData={setData}
							errors={errors}
							changeStep={changeStep}
							setActiveStep={setActiveStep}
							activeStep={activeStep}
						/>
					)}

					{activeStep === 3 && data.formaPagamento === 1 && (
						<DescontoBoleto
							data={data}
							setData={setData}
							errors={errors}
							setErrors={setErrors}
							setActiveStep={setActiveStep}
							split={split}
							canSplit={canSplit}
						/>
					)}

					{activeStep === 4 && (
						// <>hello step 4</>
						<ConfigurarSplit
							data={data}
							setData={setData}
							estabelecimentoId={estabelecimentoId}
							errors={errors}
							setErrors={setErrors}
							changeStep={changeStep}
							setActiveStep={setActiveStep}
						/>
					)}

					{activeStep === 5 && (
						<Resumo
							data={data}
							handleSubmit={handleSubmit}
							sending={sending}
							activeStep={activeStep}
							setActiveStep={setActiveStep}
							url={url}
							split={split}
							canSplit={canSplit}
							setData={setData}
						/>
					)}
				</div>
			</Paper>
		</div>
	);
};
