/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import axios from 'axios';
import config from 'react-global-configuration';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import { Formatter, Isset } from '../../utils';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';

import ZLoader from '../system/ZLoader';
import DashboardFiltros from './DashboardFiltros';
import ExtratoChart from './DashboardGraficos/ExtratoChart';
import VendasChart from './DashboardGraficos/VendasChart';
import LancamentosFuturosChart from './DashboardGraficos/LancamentosFuturosChart';
import UltimasVendas from './Dashboard/UltimasVendas';
import UltimasTransferencias from './Dashboard/UltimasTransferencias';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import NovaTransferencia from '../Transferencias/NovaTransferencia';
import { differenceInDays } from 'date-fns';
import ZModalCookie from '../system/ZModalCookie';

//removendo warnings

const originalWarn = console.warn;
console.warn = message => {
	if (!message.includes('componentWillReceiveProps')) {
		originalWarn(message);
	}
};

moment.suppressDeprecationWarnings = true;
// -- //

const useStyles = makeStyles(theme => ({
	dashboard: {
		padding: 10,
	},
	buttons: {
		display: 'none',
		[theme.breakpoints.down('xs')]: {
			display: 'flex',
		},
	},
	button: {
		background: theme.palette.primary.main,
		margin: '8px',
		padding: '8px',
		color: theme.palette.getContrastText(theme.palette.primary.main),
	},
	box: {
		position: 'relative',
		padding: 10,
		height: '100%',
	},
	boxTitle: {
		fontWeight: 700,
		fontSize: 14,
		margin: 4,
	},
	boxFiltro: {
		position: 'absolute',
		top: 10,
		right: 5,
	},
	extrato: {
		height: 419,
	},
	extratoChart: {
		width: '100%',
		height: 160,
	},
	lancamentosChart: {
		width: '100%',
		height: 230,
	},
	extratoIO: {
		marginTop: 10,
		marginBottom: 20,
		transform: 'translateY(160%)',
	},
	extratoFooter: {
		position: 'absolute',
		bottom: 4,
		right: 10,
		textAlign: 'right',
		cursor: 'pointer',
		'&:hover': {
			fontWeight: '700',
		},
	},
	progress: {
		height: 6,
		borderRadius: 6,
	},
	progressEntrada: {
		backgroundColor: `#ea606187`,
		'& .MuiLinearProgress-bar': {
			backgroundColor: `#ea6061FF`,
		},
	},
	progressSaida: {
		backgroundColor: `#00579787`,
		'& .MuiLinearProgress-bar': {
			backgroundColor: `#005797FF`,
		},
	},
	vendas: {
		minHeight: 435,
	},
	btnUltimasVendas: {
		[theme.breakpoints.down('md')]: {
			marginTop: 35,
		},
	},
	vendasChart: {
		width: '100%',
		height: 280,
		marginBottom: 10,
		[theme.breakpoints.up('md')]: {
			width: 300,
		},
	},
	webOnly: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'block',
		},
	},
	gridHeader: {
		fontWeight: 700,
		color: 'rgba(0,0,0,0.4)',
		height: 30,
	},
	ultimasVendas: {
		maxHeight: 300,
		overflowY: 'auto',
	},
	ultimasVendasFooter: {
		// position: 'absolute',
		bottom: 4,
		marginTop: 10,
		textAlign: 'right',
		cursor: 'pointer',
		'&:hover': {
			fontWeight: '700',
		},
	},
	ultimasTransferencias: {
		maxHeight: 210,
		overflowY: 'auto',
		marginBottom: 25,
	},
	ultimasTransferenciasFooter: {
		position: 'absolute',
		bottom: 4,
		right: 10,
		textAlign: 'right',
		cursor: 'pointer',
		'&:hover': {
			fontWeight: '700',
		},
	},
	tableDateRow: {
		fontWeight: 700,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 10,
		backgroundColor: `${theme.palette.primary.main}67`,
		paddingLeft: 20,
	},
	tableRow: {
		textAlign: 'center',
		fontWeight: 700,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 20,
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}2b`,
		},
	},
	tipoPagamentoIcon: {
		color: theme.palette.primary.main,
		fontSize: 20,
	},
	empty: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: 18,
		fontWeight: 700,
	},
	graficoVendasEmpty: {
		display: 'flex',
		height: '100%',
		fontSize: 22,
	},
	extratoEmpty: {
		height: 120,
	},
	vendasEmpty: {
		height: '100%',
	},
	lancamentosEmpty: {
		height: 230,
	},
	transferenciasEmpty: {
		height: '100%',
	},
	transferenciaDe: {
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	transferenciaPara: {
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		textAlign: 'left',
		overflow: 'hidden',
	},
	hide: {
		display: 'none !important',
	},
	contentText: {
		'& p': {
			color: '#424242',
			lineHeight: 1.75,
		},
	},
}));

export default props => {
	const history = useHistory();
	const classes = useStyles();
	const token = useSelector(state => state.auth.token);
	const usuario = useSelector(state => state.auth.usuario);
	const adminEstabelecimento = useSelector(state => state.admin.estabelecimento);
	const { id: estabelecimentoId } = adminEstabelecimento || {};

	const { id: marketplaceId } = usuario.marketplace;

	const [chartLoading, setChartLoading] = useState(true);

	const [openTransferencia, setOpenTransferencia] = useState(false);

	const canTransferencia = useSelector(store =>
		Isset(() => store.system.params['22'].valor) ? store.system.params['22'].valor : null
	);

	const canSale = Isset(() => usuario.estabelecimento.config.can_sale)
		? !!+usuario.estabelecimento.config.can_sale
		: true;

	const canCriarTransferencia = canTransferencia && [1, 2].includes(usuario.grupo_id);

	const canNovaVenda = [1, 2, 3, 10].includes(usuario.grupo_id) && canSale;

	const [loaderLancamentos, setLoaderLancamentos] = useState(false);
	const [loaderExtrato, setLoaderExtrato] = useState(false);

	// Dados
	const [extratoData, setExtratoData] = useState({});
	const [vendasData, setVendasData] = useState([]);
	const [lancamentosData, setLancamentosData] = useState([]);
	const [vendasValorTotal, setVendasValorTotal] = useState([]);
	const [msgErro, setMsgErro] = useState('Sem vendas.');
	const [extratoDados, setExtratoDados] = useState({
		entrada: { total: 0, porcentagem: 0 },
		saida: { total: 0, porcentagem: 0 },
	});

	// Filtros
	const [extratoFiltro, setExtratoFiltro] = useState({ startDate: addDays(new Date(), -10), endDate: new Date() });
	const [vendasFiltro, setVendasFiltro] = useState({ startDate: new Date(), endDate: new Date() });
	const [lancamentosFiltro, setLancamentosFiltro] = useState({
		startDate: moment(new Date()).format('YYYY-MM-DD 00:00:00'),
		endDate: moment(addDays(new Date(), 10)).format('YYYY-MM-DD 23:59:59'),
	});

	if (usuario.grupo_id === 5) {
		history.push('/dashboard/representante');
		return null;
	}

	if (usuario.grupo_id === 7) {
		history.push('/dashboard/backoffice');
		return null;
	}

	if (usuario.grupo_id === 8) {
		history.push('/estabelecimentos');
		return null;
	}
	if (usuario.estabelecimento.marketplace.id === 129 && usuario.grupo_id === 12) {
		history.push('/estabelecimentos');
	}

	useEffect(() => {
		setLoaderExtrato(true);
		axios
			.get(`${config.get('apiUrl')}dashboard/extrato`, {
				// cancelToken: axiosCancelSource.token,
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params: {
					...extratoFiltro,
					filters: {
						estabelecimentoId,
					},
				},
			})
			.then(res => res.data)
			.then(data => {
				if (data.success) {
					setExtratoData(data);
					const { entrada, saida } = data;
					const entradaTotal = entrada.reduce((r, v) => r + +v.valorTotal, 0);
					const saidaTotal = saida.reduce((r, v) => r + +v.valorTotal, 0);
					const total = entradaTotal + saidaTotal;

					setExtratoDados({
						entrada: { total: entradaTotal, porcentagem: ((+entradaTotal * 100) / +total).toFixed(1) },
						saida: { total: saidaTotal, porcentagem: ((+saidaTotal * 100) / +total).toFixed(1) },
					});
					setLoaderExtrato(false);
				}
			})
			.catch(err => {
				setLoaderExtrato(false);
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [extratoFiltro, estabelecimentoId]);

	useEffect(() => {
		setChartLoading(true);
		axios
			.get(`${config.get('apiUrl')}dashboard/vendas`, {
				// cancelToken: axiosCancelSource.token,
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params: {
					...vendasFiltro,
					filters: {
						estabelecimentoId,
					},
				},
			})
			.then(res => res.data)
			.then(data => {
				if (data.success) {
					const { valorTotal, formasPagamentos } = data;
					setVendasData(formasPagamentos || []);
					setVendasValorTotal(valorTotal || 0);
					setMsgErro('Sem vendas.');
				} else {
					if (data.error === 'The difference between start date and end date cannot be more than 31 days.') {
						setMsgErro('Período inválido. Máximo de 31 dias.');
					}
				}
				setChartLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [vendasFiltro, estabelecimentoId]);

	useEffect(() => {
		setLoaderLancamentos(true);
		axios
			.get(`${config.get('apiUrl')}dashboard/lancamentos`, {
				// cancelToken: axiosCancelSource.token,
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params: {
					...lancamentosFiltro,
					filters: {
						estabelecimentoId,
					},
				},
			})
			.then(res => res.data)
			.then(data => {
				if (data.success) {
					const { lancamentos } = data;
					let produtosAgrupados;
					let chartData = lancamentos.map(v => ({
						dia: format(v.dia, 'DD/MM'),
						value: v.valorTotal,
					}));

					produtosAgrupados = chartData.reduce((acc, produto) => {
						if (!acc[produto.dia]) {
							acc[produto.dia] = { mes: produto.dia, value: 0 };
						}
						acc[produto.dia].value += produto.value;
						return acc;
					}, {});

					if (differenceInDays(lancamentosFiltro.endDate, lancamentosFiltro.startDate) >= 30) {
						chartData = lancamentos.map(v => ({
							mes: format(v.dia, 'MMM/YY'),
							value: v.valorTotal,
						}));
						produtosAgrupados = chartData.reduce((acc, produto) => {
							if (!acc[produto.mes]) {
								acc[produto.mes] = { mes: produto.mes, value: 0 };
							}
							acc[produto.mes].value += +produto.value;
							return acc;
						}, {});
					}

					const resultado = Object.values(produtosAgrupados);
					setLancamentosData(resultado);
					setLoaderLancamentos(false);
				}
				setLoaderLancamentos(false);
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
		// return async () => {
		// 	axiosCancelSource.cancel('Dashboard Unmounted.');
		// };
	}, [lancamentosFiltro, estabelecimentoId]);

	let textoBotao;
	let textoBotaoSaque;
	if (usuario.estabelecimento.marketplace.id === 129 && usuario.grupo_id !== 1) {
		textoBotao = 'Depósito';
		textoBotaoSaque = 'Saque';
	} else {
		textoBotao = 'Nova Venda';
		textoBotaoSaque = 'Criar Transferência';
	}
	return (
		<div>
			<ZModalCookie userEmail={usuario.email} />
			<Grid className={classes.buttons}>
				{canCriarTransferencia && (
					<Button className={classes.button} onClick={() => setOpenTransferencia(true)}>
						{textoBotaoSaque}
					</Button>
				)}
				{canNovaVenda && (
					<Button className={classes.button} onClick={() => history.push('/vendas/nova')}>
						{textoBotao}
					</Button>
				)}
			</Grid>
			<Grid container spacing={2} direction="row" justifyContent="center" alignItems="stretch">
				<Grid xs={12} sm={6} md={4} container item>
					<Grid xs={12} className={classes.extrato} item>
						<Paper className={clsx(classes.box)}>
							<div className={classes.boxTitle}>Extrato {format(new Date(), 'MM/YYYY')}</div>
							<div className={classes.boxFiltro}>
								<DashboardFiltros
									default="last10days"
									maxDate={new Date()}
									onClose={ranges => setExtratoFiltro(ranges)}
								/>
							</div>
							{loaderExtrato ? (
								<div className={clsx(classes.empty, classes.extratoEmpty)}>
									<ZLoader height={200} size={50} />
								</div>
							) : Isset(() => extratoData.entrada[0]) ||
							  Isset(() => extratoData.saida[0]) ||
							  loaderExtrato ? (
								<ExtratoChart data={extratoData} />
							) : (
								<div className={clsx(classes.empty, classes.extratoEmpty)}>
									Nenhum Resultado Encontrado.
								</div>
							)}
							<div className={classes.extratoIO}>
								<div>
									Entrada ({Formatter.Real(extratoDados.entrada.total)})
									<div>
										<LinearProgress
											className={clsx(classes.progress, classes.progressEntrada)}
											color="secondary"
											variant="determinate"
											value={+extratoDados.entrada.porcentagem}
										/>
									</div>
								</div>
								<div>
									Saída ({Formatter.Real(extratoDados.saida.total)})
									<div>
										<LinearProgress
											className={clsx(classes.progress, classes.progressSaida)}
											color="secondary"
											variant="determinate"
											value={+extratoDados.saida.porcentagem}
										/>
									</div>
								</div>
							</div>
							{usuario.estabelecimento.marketplace.id === 129 && usuario.grupo_id !== 1 ? (
								<div></div>
							) : (
								<div className={classes.extratoFooter} onClick={() => history.push('/extrato')}>
									<Button size="small">Ver todos os lançamentos</Button>
								</div>
							)}
						</Paper>
					</Grid>
				</Grid>
				<Grid xs={12} sm={6} md={8} className={classes.vendas} item>
					<Paper className={clsx(classes.box)}>
						<div className={classes.boxTitle}>Vendas</div>
						<div className={classes.boxFiltro}>
							<DashboardFiltros
								default="today"
								maxDate={new Date()}
								onClose={ranges => setVendasFiltro(ranges)}
							/>
						</div>
						<Grid container>
							<Grid xs={12} sm={12} md={5} item>
								{chartLoading ? (
									<ZLoader height={200} size={50} />
								) : vendasValorTotal ? (
									<VendasChart data={vendasData} valorTotal={vendasValorTotal} />
								) : (
									<div className={clsx(classes.empty, classes.graficoVendasEmpty)}>{msgErro}</div>
								)}
							</Grid>
							<Grid xs={12} sm={12} md={7} item className={classes.webOnly}>
								<UltimasVendas estabelecimentoId={estabelecimentoId} />
							</Grid>
						</Grid>
						<Grid container>
							<Grid item xs={12} className={classes.btnUltimasVendas}>
								{canSale && (
									<div
										className={classes.ultimasVendasFooter}
										onClick={() => history.push('/vendas')}
									>
										<Button size="small">Ver todas as vendas</Button>
									</div>
								)}
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			</Grid>
			<Grid container spacing={2} direction="row" justifyContent="center" alignItems="stretch">
				<Grid xs={12} md={6} className={classes.transferencias} item>
					<UltimasTransferencias estabelecimentoId={estabelecimentoId} />
				</Grid>
				<Grid xs={12} md={6} className={classes.lancamentos} item>
					<Paper className={clsx(classes.box)}>
						<Grid container>
							<Grid item xs={12}>
								<div className={classes.boxTitle}>Lançamentos Futuros</div>
								<div className={classes.boxFiltro}>
									<DashboardFiltros
										default="next10days"
										minDate={new Date()}
										onClose={ranges => setLancamentosFiltro(ranges)}
									/>
								</div>

								{loaderLancamentos ? (
									<div className={clsx(classes.empty, classes.extratoEmpty)}>
										<ZLoader height={200} size={50} />
									</div>
								) : Object.keys(lancamentosData).length > 0 ? (
									<LancamentosFuturosChart data={lancamentosData} />
								) : (
									<div className={clsx(classes.empty, classes.extratoEmpty)}>
										Nenhum Resultado Encontrado.
									</div>
								)}
							</Grid>
							<Grid item xs={12} style={{ marginTop: 15 }}>
								<div
									className={classes.ultimasTransferenciasFooter}
									onClick={() => history.push('/proximos-lancamentos?tt=2&d=next6months')}
								>
									<Button size="small">Ver todos lançamentos</Button>
								</div>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
				{openTransferencia && <NovaTransferencia open={true} onClose={() => setOpenTransferencia(false)} />}
			</Grid>
		</div>
	);
};
