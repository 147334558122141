/* eslint-disable react-hooks/exhaustive-deps */
import { addMessage } from '../../store/alert';
import { FaDownload } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';
import { Mask, Formatter, Isset } from '../../utils';
import { useSelector, useDispatch } from 'react-redux';
import addDays from 'date-fns/addDays';
import axios from 'axios';
import clsx from 'clsx';
import config from 'react-global-configuration';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FileDownload from 'js-file-download';
import format from 'date-fns/format';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import React, { useState, useEffect } from 'react';
import ZLoader from '../system/ZLoader';
import ZPaginationDiv from '../system/ZPaginationDiv';
import ZTable from '../system/ZTable';
import ZFiltros from '../system/ZFiltros';
import { Tooltip, Button } from '@material-ui/core';
import LastExport from '../Export/LastExport';
import ZModalConfirm from '../system/ZModalConfirm';
import { FiRefreshCw } from 'react-icons/fi';

const useStyles = makeStyles(theme => ({
	RenovarCacheButton: {
		height: 37,
		padding: '0 6px',
		backgroundColor: theme.palette.primary.main,
		color: '#FFF',
		'&:hover': {
			color: '#FFF',
			backgroundColor: `${theme.palette.primary.main}`,
		},
		[theme.breakpoints.up('md')]: {
			width: '80%',
		},
	},
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
	},
	mesReferencia: {
		padding: 5,
		// fontVariant: 'small-caps',
		position: 'relative',
	},
	export: {
		position: 'absolute',
		top: 0,
		right: 10,
		opacity: 0.5,
		cursor: 'pointer',
		color: '#464ae6',
		fontSize: 16,
		transition: theme.transitions.create(['all'], {
			duration: theme.transitions.duration.complex,
		}),
		'&:hover': {
			fontSize: 18,
			opacity: 1,
		},
	},
	mesReferenciaAtual: {
		fontWeight: 'bold',
		fontSize: 16,
		cursor: 'pointer',
	},
	omniField: {
		'& .MuiInput-input': {
			fontSize: 14,
			padding: 0,
		},
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			bottom: -6,
			transform: 'scaleX(1)',
		},
		'& .MuiInput-underline:not(.Mui-focused):after': {
			borderBottom: 'solid 1px #abc',
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
			bottom: -6,
		},
	},
	filtroOmniHasText: {
		backgroundColor: `${theme.palette.primary.main}`,
		color: `${theme.palette.getContrastText(theme.palette.primary.main)} !important`,
		'& .MuiInput-input': {
			color: `${theme.palette.getContrastText(theme.palette.primary.main)} !important`,
		},
	},
	filtroText: {
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		padding: '5px 10px',
		border: `solid 1px ${theme.palette.primary.main}`,
		borderRadius: 30,
		marginLeft: 10,
		fontWeight: 700,
		fontSize: 12,
		color: theme.palette.primary.main,
	},
	clearBtn: {
		marginLeft: 6,
	},
	exportDownload: {
		color: '#011D29',
		fontSize: 16,
		marginLeft: 8,
	},
	iconButton: {
		minWidth: 0,
	},
	buttonExport: {
		color: '#040633',
		border: `solid 1px #040633`,
		'&:hover': {
			color: '#FFF',
			backgroundColor: '#011d29',
			'& svg': {
				color: '#FFF',
			},
		},
		[theme.breakpoints.down('xs')]: {
			width: '90%',
			marginTop: 16,
		},
		'& svg': {
			margin: 0,
		},
	},
	filtroSelect: {
		fontWeight: 700,
		fontSize: 12,
		'&:before': {
			borderBottom: 'none !important',
		},
		'&:after': {
			borderBottom: 'none !important',
		},
		'& .MuiSelect-select': {
			padding: '2px 10px !important',
			marginRight: 10,
		},
	},
	menuItem: {
		flexDirection: 'column',
	},
}));

export default props => {
	const classes = useStyles();
	const token = useSelector(store => store.auth.token);
	// const axiosCancelSource = axios.CancelToken.source();
	const usuario = useSelector(store => store.auth.usuario);
	const [showModalRenovaCache, setShowModalRenovarCache] = useState(false);
	const [isNoCacheClicked, setIsNoCacheClicked] = useState(false);

	const dispatch = useDispatch();
	const [representantes, setRepresentantes] = useState([]);
	const [loading, setLoading] = useState(true);
	const [dataReferencia, setDataReferencia] = useState({
		start: new Date(),
		end: new Date(),
		label: 'Clique para selecionar a data',
	});
	const [omni, setOmni] = useState('');
	const [tipoRepresentante, setTipoRepresentante] = useState('');
	// const [omniTypingTimeout, setOmniTypingTimeout] = useState(null);
	const [showExportMessage, setShowExportMessage] = useState(false);
	const [showLastExport, setShowLastExport] = useState(false);

	const [noCacheStatus, setNoCacheStatus] = useState(false);

	// Paginação

	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(15);
	const [totalRows, setTotalRows] = useState(0);

	const onChangePage = newPage => {
		setPage(newPage);
	};

	const onChangeRowsPerPage = rowsPerPage => {
		setRowsPerPage(rowsPerPage);
		setPage(1);
	};

	const handleChangeFilter = data => {
		const { omni, tipoRepresentante, created } = data;

		setOmni(omni || '');
		setTipoRepresentante(tipoRepresentante || '');

		if (created) {
			const startDate = created.start;
			const endDate = created.end;

			const daysDifference = Math.round((endDate - startDate) / (1000 * 60 * 60 * 24));

			if (daysDifference > 31) {
				dispatch(addMessage({ type: 'error', message: 'O intervalo de data é de no máximo 31 dias.' }));
				return;
			}

			setDataReferencia({
				...dataReferencia,
				start: startDate,
				end: endDate,
			});
		}
	};
	const [exportInProgress, setExportInProgress] = useState(false);

	const newExport = async getLastExports => {
		try {
			await exportXls(getLastExports); // Realiza a exportação
		} catch (error) {
			console.error('Erro durante a exportação:', error);
		} finally {
			setExportInProgress(false); // Libera o botão após concluir todas as requisições
		}
	};

	const exportXlsClick = async () => {
		setExportInProgress(true); // Bloqueia o botão de exportação
		setShowLastExport(true); // Abre o modal de exportação
	};

	const getRepresentantes = async () => {
		setLoading(true);
		const start = format(dataReferencia.start, 'YYYY-MM-DD');
		const end = format(dataReferencia.end, 'YYYY-MM-DD');
		//	const noCache = dataReferencia.noCache ? dataReferencia.noCache : null;
		const noCache = noCacheStatus;
		const res = await axios.get(`${config.get('apiUrl')}representantes/comissoes`, {
			// cancelToken: axiosCancelSource.token,
			params: {
				tipoRepresentante,
				'date_range[gte]': start,
				'date_range[lt]': end,
				omni: omni,
				limit: rowsPerPage,
				page: page,
				noCache: noCache,
			},
			headers: { Authorization: `Bearer ${token}` },
		});
		console.log(res.data);
		if (res.data.success) {
			if (!res.data.result) {
				setTimeout(getRepresentantes, 5000);
			} else {
				setRepresentantes(res.data.result);
				setTotalRows(res.data.totalRows);
				setLoading(false);
				setNoCacheStatus(false);
			}
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		getRepresentantes();
	}, [dataReferencia, omni, page, rowsPerPage, tipoRepresentante]);

	const exportXls = async getLastExports => {
		try {
			const start = format(dataReferencia.start, 'YYYY-MM-DD');
			const end = format(dataReferencia.end, 'YYYY-MM-DD');

			await axios.get(
				`${config.get(
					'apiUrl'
				)}export/relatorios/comissoesRepresentantes?date_range[gte]=${start}&date_range[lt]=${end}&omni=${omni}&limit=${rowsPerPage}&page=${page}`,
				{
					params: { tipoRepresentante },
					headers: { Authorization: `Bearer ${token}` },
					responseType: 'blob',
				}
			);
			getLastExports();

			dispatch(addMessage({ type: 'warning', message: 'Sua nova exportação está sendo processada.' }));
		} catch (error) {
			console.log(error.response.data.text());
			const texto = await error.response.data.text();
			console.log(texto);
			dispatch(addMessage({ type: 'error', message: texto }));
		}
	};

	// const omniChange = e => {
	// 	const value = e.target.value;
	// 	setOmni(value);

	// 	if (omniTypingTimeout) {
	// 		clearTimeout(omniTypingTimeout);
	// 	}

	// 	const timeout = setTimeout(() => {
	// 		getRepresentantes();
	// 		setOmniTypingTimeout(null);
	// 	}, 500);

	// 	setOmniTypingTimeout(timeout);
	// };

	// const omniClear = e => {
	// 	e.stopPropagation();
	// 	setOmni('');
	// };

	const handleRenovarCache = result => {
		if (result) {
			setDataReferencia({
				...dataReferencia,
				noCache: true,
			});
			setNoCacheStatus(true);
		} else {
			setNoCacheStatus(false);
		}
		setShowModalRenovarCache(false);
	};

	return (
		<div>
			<div className={classes.header}>Comissões dos Representantes</div>

			<div className={classes.mesReferencia}>
				<Paper style={{ marginBottom: 10, padding: '5px 10px' }}>
					<Grid container alignItems="center">
						<Grid item md={11}>
							<ZFiltros
								onChange={loading ? false : handleChangeFilter}
								created={{ ...dataReferencia }}
								input={[
									{
										key: 'omni',
										placeholder: 'Representante',
										// defaultValue: '',
										modal: false,
										md: 2,
									},
								]}
								selectField={[
									{
										key: 'tipoRepresentante',
										defaultValue: tipoRepresentante,
										placeholder: 'Tipo Representante',
										options: [
											{ id: '1', name: 'Master ' },
											{ id: '2', name: 'Secundário' },
										],
										modal: false,
										md: 3,
										multiple: false,
									},
								]}
							/>
						</Grid>
						<Grid container spacing={1} item md={1}>
							<Grid item align="center" xs={4} md={4}>
								{format(new Date(dataReferencia.start), 'YYYY-MM-DD') <
									format(new Date(), 'YYYY-MM-DD') && (
									<Tooltip title="Renovar Cache">
										<Button
											className={clsx(classes.buttonExport, classes.iconButton)}
											onClick={() => {
												setShowModalRenovarCache(true);
											}}
										>
											<FiRefreshCw className={classes.exportDownload} />
										</Button>
									</Tooltip>
								)}
							</Grid>
							<Grid item align="end" xs={4} md={4}>
								<Tooltip title="Exportar">
									<Button
										className={clsx(classes.buttonExport, classes.iconButton)}
										onClick={exportXlsClick}
									>
										<FaDownload className={classes.exportDownload} />
									</Button>
								</Tooltip>
								{/* <FaFileExcel className={classes.export} onClick={} /> */}
							</Grid>
						</Grid>
					</Grid>
				</Paper>
			</div>

			<Paper className={classes.paper}>
				{loading ? (
					<ZLoader height={200} />
				) : (
					<>
						<ZTable
							headCells={[
								{
									id: 'nome',
									numeric: false,
									disablePadding: false,
									label: 'Representante',
								},
								{
									id: 'taxa',
									numeric: false,
									disablePadding: false,
									label: 'Taxa',
									filter: value => `${Mask.Porcentagem(+value)} %`,
								},
								{
									id: 'taxaMedia',
									numeric: false,
									disablePadding: false,
									label: 'Taxa Média',
									filter: value => `${Mask.Porcentagem(+value)} %`,
								},
								{ id: 'quantidadeVendas', numeric: false, disablePadding: false, label: 'Vendas' },
								{
									id: 'valorVendas',
									numeric: false,
									disablePadding: false,
									label: 'Valor Total de Vendas',
									filter: Formatter.Real,
								},
								{
									id: 'markup',
									numeric: false,
									disablePadding: false,
									label: 'Valor Markup',
									filter: Formatter.Real,
								},
								{
									id: 'valorComissao',
									numeric: false,
									disablePadding: false,
									label: 'Comissão',
									filter: Formatter.Real,
								},
							]}
							tableTitle=""
							toolBar={false}
							rows={representantes}
							densePadding={false}
							checkBox={false}
							rowsPerPage={[15, 30, 60]}
							rowsPerPageDefault={rowsPerPage}
							pagination={false}
						/>

						<ZPaginationDiv
							count={totalRows}
							rowsPerPage={rowsPerPage}
							page={page - 1}
							onChangePage={onChangePage}
							onChangeRowsPerPage={onChangeRowsPerPage}
							rowsPerPageOptions={[15, 30, 60]}
						/>
					</>
				)}
			</Paper>
			{showLastExport && (
				<LastExport
					show={showLastExport}
					onClose={() => setShowLastExport(false)}
					url={'/export/relatorios/comissoesRepresentantes'}
					newExport={newExport}
					loadingProp={exportInProgress}
				/>
			)}
			<ZModalConfirm
				title={`Regeneração do resumo`}
				message={`Ao clicar em continuar, iremos recriar os dados do resumo das vendas`}
				open={showModalRenovaCache}
				onClose={result => handleRenovarCache(result)}
			/>
			<Dialog open={showExportMessage}>
				<DialogContent style={{ width: 200 }}>
					<ZLoader height={100} size={26} message={'Exportando'} />
				</DialogContent>
			</Dialog>
		</div>
	);
};
