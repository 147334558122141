import React, { useCallback, useState, useEffect } from 'react';
import {
	makeStyles,
	Grid,
	Typography,
	MenuItem,
	FormControl,
	Select,
	InputLabel,
	ListSubheader,
	Button,
	Backdrop,
	Container,
	TextField,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import config from 'react-global-configuration';
import { FiCheckCircle } from 'react-icons/fi';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { addMessage } from '../../store/alert';
import ZText from '../system/ZText';
import ZSaveButton from '../system/ZSaveButton';
import ZAlert from '../system/ZAlert';
import ModalTermoDeUso from './ModalTermoDeUso';
import { Isset, ValidarCpf, ValidatorForm, Mask } from '../../utils';

import { registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';

import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css';
// import { useHistory } from 'react-router-dom';

registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginFilePoster);

const useStyles = makeStyles(theme => ({
	layout: {
		width: 'auto',
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		[theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
			width: 1280,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	paper: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
		padding: theme.spacing(2),
		[theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
			marginTop: theme.spacing(6),
			marginBottom: theme.spacing(6),
			padding: theme.spacing(3),
		},
	},
	configHeader: {
		fontWeight: 700,
		fontSize: 16,
		margin: 4,
	},
	box: {
		position: 'relative',
		padding: 10,
		borderTop: `solid 4px transparent`,
	},
	boxAtivo: {
		borderTop: `solid 4px ${theme.palette.primary.main}`,
	},
	ativo: {
		position: 'absolute',
		right: 10,
		top: 5,
		textTransform: 'uppercase',
		fontSize: 11,
		fontWeight: 'bold',
	},
	formControl: {
		width: '100%',
		marginTop: 4,
	},
	salvarBtn: {
		textAlign: 'center',
		textTransform: 'uppercase',
		margin: 8,
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
	},
	textField: {
		width: '100%',
		marginTop: 4,
	},
	icon: {
		color: theme.palette.primary.main,
	},
	updateCheck: {
		position: 'absolute',
		top: 10,
		right: 10,
	},
	field: {
		padding: 5,
	},
	label: {
		fontSize: 11,
		textTransform: 'uppercase',
		fontWeight: 700,
	},
	configUpdateBtn: {
		textAlign: 'center',
		textTransform: 'uppercase',
		paddingBottom: 15,
		fontWeight: 700,
		fontSize: 12,
		cursor: 'pointer',
		color: theme.palette.primary.main,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	titulo: {
		display: 'flex',
		justifyContent: 'center',
		margin: '16px',
	},
	primeiroStep: {
		marginTop: '16px',
		background: '#FFFFFF',
		height: '288px',
		maxWidth: '888px',
		margin: 'auto',
	},
	botaoInicial: {
		textAlign: 'center',
		padding: '22px',
		background: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.primary.main),
	},
	botao: {
		textAlign: 'center',
		background: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.primary.main),
	},
	base: {
		minHeight: '888px',
		background: '#D5D5D5',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	segundoStep: {
		border: '64px solid white',
		background: 'white',
		margin: 'auto',
	},
}));

const validateFields = {
	faturamento_estimado: {
		required: true,
	},
	tipoEstabelecimentoId: {
		required: true,
	},
	nome: {
		required: true,
	},
	email: {
		required: true,
	},
	celular: {
		required: true,
	},
	dataNascimento: {
		required: true,
	},
	dataNascimento2: '',
	cpf: {
		required: true,
	},
	categoria: {
		required: true,
	},
	endereco: {
		logradouro: {
			required: true,
		},
		numero: {
			required: true,
		},
		cidade: {
			required: true,
		},
		estado: {
			required: true,
		},
		cep: {
			required: true,
		},
	},
};

const ValidateFieldsPessoaJuridica = {
	faturamento_estimado: {
		required: true,
	},
	tipoEstabelecimentoId: {
		required: true,
	},
	razaoSocial: {
		required: true,
	},
	nomeFantasia: {
		required: true,
	},
	telefone: {
		required: true,
	},
	email: {
		required: true,
	},
	cnpj: {
		required: true,
	},
	categoria: {
		required: true,
	},
	endereco: {
		logradouro: {
			required: true,
		},
		numero: {
			required: true,
		},
		cidade: {
			required: true,
		},
		estado: {
			required: true,
		},
		cep: {
			required: true,
		},
	},
};

export function CadastroSuccess() {
	const classes = useStyles();

	useEffect(() => {
		setTimeout(() => {
			window.location = '/';
		}, 5000);
	}, []);

	return (
		<Container style={{ background: '#1d1e26', minHeight: '100vh', minWidth: '100vw' }}>
			<Backdrop className={classes.backdrop} open={true}>
				<Grid container alignItems="center" justifyContent="center" direction="column">
					<FiCheckCircle size={80} color="#50fa7b" strokeWidth="1px" />
					<Typography style={{ marginTop: 32, color: '#f2f2f2', fontWeight: 500 }} variant="h4" gutterBottom>
						Parabéns por se cadastrar!
					</Typography>
				</Grid>
			</Backdrop>
		</Container>
	);
}

const clearAddMessage = (callback, time) => {
	setTimeout(function() {
		callback();
	}, time);
};

export default () => {
	const classes = useStyles();

	// const match = useRouteMatch();
	// const history = useHistory();

	const dispatch = useDispatch();
	const message = useSelector(store => store.alert);
	// const {
	// 	// estabelecimentoConfig: { primary_color },
	// } = useSelector(store => store.system.config);

	// const [logoData, setLogoData] = useState([]);
	const [step, setStep] = useState(0);
	const [tipoDeEstabelecimento, setTipoDeEstabelecimento] = useState();
	// const [documentos, setDocumentos] = useState([]);
	// const [documentosProprietario, setDocumentosProprietario] = useState([]);
	// const [documentosAtividade, setDocumentosAtividade] = useState([]);
	// const [documentosResidencia, setDocumentosResidencia] = useState([]);
	// const [outrosDocumentos, setOutrosDocumentos] = useState([]);
	const [openTermo, setOpenTermo] = useState(false);
	const [termosSuccess, setTermosSuccess] = useState(false);
	const [bancos, setBancos] = useState([]);

	// const [novoArquivo, setNovoArquivo] = useState(false);
	const [loading, setLoading] = useState(false);
	const [categorias, setCategorias] = useState([]);
	const [onError, setOnError] = useState(false);
	const [data, setData] = useState({
		faturamento_estimado: 0,
		tipoEstabelecimentoId: 2,
		nome: '',
		nomeFantasia: '',
		nomeComprovante: '',
		email: '',
		telefone: '',
		celular: '',
		dataNascimento: '',
		dataNascimento2: '',
		cpf: '',
		cnpj: '',
		categoria: '',
		razaoSocial: '',
		endereco: {
			logradouro: '',
			numero: '',
			cidade: '',
			estado: '',
			cep: '',
			bairro: '',
			complemento: '',
		},
		proprietario: {
			nome: '',
			sobrenome: '',
			email: '',
			celular: '',
			dataNascimento: '',
			cpf: '',
			endereco: {
				logradouro: '',
				numero: '',
				cidade: '',
				estado: '',
				cep: '',
				complemento: '',
				bairro: '',
			},
		},
		contaBancaria: {
			tipoContaBancaria: 1,
			nomeTitular: '',
			bancoId: '',
			agencia: '',
			conta: '',
		},
		termosAceito: '',
		termosAceitoData: null,
	});

	// const [backgroundColor, setBackgroundColor] = useState('#000000');
	// const [textColor, setTextColor] = useState('#FFFFFF');

	const errorMessage = useSelector(state => state.alert);

	useEffect(() => {
		if (message.message !== false) {
			clearAddMessage(() => dispatch(addMessage({ type: 'error', message: false })), 5000);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errorMessage.message]);

	useEffect(() => {
		const getSystemConfig = async () => {
			let hostName = window.location.hostname;

			if (hostName === 'localhost' && config.get('hostName')) {
				hostName = config.get('hostName');
			}

			const res = await axios.get(`${config.get('apiUrl')}system_config/${hostName}`);

			if (res.data.success) {
				setData(data => ({
					...data,
					marketplaceId: res.data.config.marketplaceId,
					estabelecimentoId: res.data.config.estabelecimentoId,
				}));
				// const termos = await axios.get(`${config.get('apiUrl')}termosCondicoes/por_marketplace/${res.data.config.marketplaceId}`);
				const termos = await axios.get(
					`${config.get('apiUrl')}termosCondicoes/por_estabelecimento/${res.data.config.estabelecimento_id}`
				);
				setTermosSuccess(termos.data.success);
				setData(data => ({ ...data, termosAceito: termos.data.success }));
				// setBackgroundColor(res.data.config.estabelecimentoConfig.primary_color);

				if (
					res.data.config.estabelecimentoConfig.primary_color === '#FFFFFF' ||
					res.data.config.estabelecimentoConfig.primary_color === '#ffffff'
				) {
					// setTextColor('#000000');
				}

				if (termos.data.success) {
					const newDate = new Date();
					setData(data => ({ ...data, termosAceitoData: moment(newDate).format() }));
				}
			}
		};

		getSystemConfig();
	}, []);

	useEffect(() => {
		const getCategorias = async () => {
			const response = await axios.get(
				`${config.get('apiUrl')}estabelecimentos/mcc/marketplaceId/${data.marketplaceId}`
			);

			if (response.data.success && Isset(() => response.data.result)) {
				const cat = [];

				Object.keys(response.data.result).forEach(a => {
					const cats = response.data.result[a];

					cat.push({ id: null, description: a });
					cats.forEach(c => cat.push({ id: c.id, description: c.description }));
				});

				setCategorias(cat);
			}
		};

		if (data.marketplaceId) {
			getCategorias();
		}

		const getBancos = async () => {
			const result = await axios.get(`${config.get('apiUrl')}bancos`);

			if (result.data.success) {
				setBancos(result.data.bancos);
			}
		};

		getBancos();
	}, [data.marketplaceId]);

	const handleSubmitForm = useCallback(async () => {
		setLoading(true);

		try {
			if (data.tipoEstabelecimentoId === 1) {
				ValidatorForm(validateFields, data);
			} else {
				ValidatorForm(ValidateFieldsPessoaJuridica, data);
			}

			const formData = new FormData();

			for (let key in data) {
				if (typeof data[key] === 'object') {
					for (let key2 in data[key]) {
						if (typeof data[key][key2] === 'object') {
							for (let key3 in data[key][key2]) {
								formData.append(`${key}[${key2}][${key3}]`, data[key][key2][key3]);
							}
						} else {
							formData.append(`${key}[${key2}]`, data[key][key2]);
						}
					}
				} else {
					formData.append(key, data[key]);
				}
			}

			// const logo = !Array.isArray(logoData) ? [logoData] : logoData;
			// const documentosFiles = !Array.isArray(documentos) ? [documentos] : documentos;
			// const documentosProprietarioFiles = !Array.isArray(documentosProprietario)
			// 	? [documentosProprietario]
			// 	: documentosProprietario;
			// const documentosAtividadeFiles = !Array.isArray(documentosAtividade)
			// 	? [documentosAtividade]
			// 	: documentosAtividade;
			// const documentosResidenciaFiles = !Array.isArray(documentosResidencia)
			// 	? [documentosResidencia]
			// 	: documentosResidencia;
			// const outrosDocumentosFiles = !Array.isArray(outrosDocumentos) ? [outrosDocumentos] : outrosDocumentos;

			// logo.forEach(a => {
			// 	if (!a.type.match('image.*')) {
			// 		return;
			// 	}
			// 	formData.append('logo', a, a.name);
			// });

			// const addFile = (name, file, fd) => {
			// 	if (!(file instanceof Blob)) {
			// 		fd.append(`documentosExistentes[]`, file.id);
			// 		return;
			// 	}
			// 	if (!file.type.match('image.*') && !file.type.match('application/pdf')) {
			// 		return;
			// 	}
			// 	fd.append(`${name}[]`, file, file.name);
			// };

			// documentosFiles.forEach(a => addFile('documentos', a, formData));
			// documentosProprietarioFiles.forEach(a => addFile('documentosProprietario', a, formData));
			// documentosAtividadeFiles.forEach(a => addFile('documentosAtividade', a, formData));
			// documentosResidenciaFiles.forEach(a => addFile('documentosResidencia', a, formData));
			// outrosDocumentosFiles.forEach(files => addFile('outrosDocumentos', files, formData));

			const res = await axios.post(`${config.get('apiUrl')}estabelecimentos/cadastre-se`, formData);

			if (res.data.success) {
				setLoading(false);

				dispatch(addMessage({ type: 'success', message: 'Operação realizada com sucesso!' }));

				window.location = '/cadastro/sucesso';
			} else {
				setLoading(false);

				dispatch(addMessage({ type: 'error', message: res.data.error }));

				window.scrollTo(0, 0);
			}
		} catch (erro) {
			window.scrollTo(0, 0);
			dispatch(addMessage({ type: 'error', message: 'Existem dados inválidos' }));
			setLoading(false);
			setOnError(true);

			return false;
		}
	}, [
		dispatch,
		data,
		// documentosAtividade,
		// documentos,
		// documentosProprietario,
		// documentosResidencia,
		// outrosDocumentos,
		// logoData,
	]);

	const handleChange = useCallback(
		name => event => {
			const { value } = event.target;

			if (name === 'cpf') {
				if (!ValidarCpf(value)) {
					dispatch(addMessage({ type: 'error', message: 'CPF inválido' }));

					setData(data => ({ ...data, cpf: '' }));

					return false;
				} else {
					dispatch(addMessage({ type: 'error', message: false }));
				}
			}

			setData(data => ({ ...data, [name]: value }));
		},
		[dispatch]
	);

	const handleProprietario = useCallback(
		name => event => {
			setData(data => ({
				...data,
				proprietario: {
					...data.proprietario,
					[name]: event.target.value,
				},
			}));
		},
		[]
	);

	const handleChangeProprietarioEndereco = name => async event => {
		var value = event.target.value;

		if (name === 'cep') {
			value = Mask.OnlyNumber(value);
		}

		if (name === 'cep' && event.target.value.length === 9) {
			const res = await axios.get(`https://viacep.com.br/ws/${value}/json/`);

			if (res.status !== 200) {
				setData(data => ({
					...data,
					proprietario: {
						...data.proprietario,
						endereco: {
							...data.proprietario.endereco,
							cep: value,
							logradouro: '',
							cidade: '',
							estado: '',
							complemento: '',
						},
					},
				}));
				// setErrors([ 'CEP não encontrado' ]);
				dispatch(addMessage({ type: 'error', message: 'CEP não encontrado' }));
				return false;
			}

			setData(data => ({
				...data,
				proprietario: {
					...data.proprietario,
					endereco: {
						...data.proprietario.endereco,
						cep: value,
						logradouro: res.data.logradouro,
						cidade: res.data.localidade,
						estado: res.data.uf,
						complemento: '',
						bairro: res.data.bairro,
					},
				},
			}));
		} else {
			setData(data => ({
				...data,
				proprietario: {
					...data.proprietario,
					endereco: {
						...data.proprietario.endereco,
						[name]: value,
					},
				},
			}));
		}
	};

	const handleChangeEndereco = name => async event => {
		var value = event.target.value;
		if (name === 'cep') {
			value = Mask.OnlyNumber(value);
		}

		if (name === 'cep' && event.target.value.length === 9) {
			var res = await axios.get(`https://viacep.com.br/ws/${value}/json/`);

			if (res.status !== 200) {
				setData({
					...data,
					endereco: {
						...data.endereco,
						cep: value,
						logradouro: '',
						cidade: '',
						estado: '',
						complemento: '',
					},
				});
				// setErrors([ 'CEP não encontrado' ]);
				dispatch(addMessage({ type: 'error', message: 'CEP não encontrado' }));
				return false;
			}
			setData({
				...data,
				endereco: {
					...data.endereco,
					cep: value,
					logradouro: res.data.logradouro,
					cidade: res.data.localidade,
					estado: res.data.uf,
					complemento: '',
					bairro: res.data.bairro,
				},
			});
		} else {
			setData({
				...data,
				endereco: {
					...data.endereco,
					[name]: value,
				},
			});
		}
	};

	const handleDataNascimento = name => event => {
		if (name === 'dataNascimento2') {
			setData({
				...data,
				[name]: event.target.value,
				dataNascimento: moment(event.target.value, 'DD/MM/YYYY').format('YYYY-MM-DD'),
			});
		}
	};

	const handleOpenTermo = () => {
		setOpenTermo(!openTermo);
	};

	return (
		<Container style={{ padding: '64px 0' }}>
			<ModalTermoDeUso
				marketplaceId={data.marketplaceId}
				open={openTermo}
				handleSubmitForm={handleSubmitForm}
				handleClose={handleOpenTermo}
			/>
			<Grid className={classes.conteudo} container spacing={1} display="flex" direction="column">
				<Grid item>
					<Typography className={classes.titulo} variant="h4">
						Cadastrar Estabelecimento
					</Typography>
				</Grid>
				{step === 0 && (
					<>
						<Grid
							className={classes.primeiroStep}
							container
							spacing={4}
							display="flex"
							justifyContent="center"
							alignItems="center"
						>
							<Typography variant="h6">Quero me cadastrar como:</Typography>
							<Grid item>
								<Button
									size="large"
									className={classes.botaoInicial}
									onClick={() => {
										setStep(1);
										setTipoDeEstabelecimento(2);
										setData(data => ({ ...data, tipoEstabelecimentoId: 2 }));
									}}
									variant="contained"
								>
									Pessoa Jurídica
								</Button>
							</Grid>
							<Grid item>
								<Button
									size="large"
									className={classes.botaoInicial}
									onClick={() => {
										setStep(1);
										setTipoDeEstabelecimento(1);
										setData(data => ({ ...data, tipoEstabelecimentoId: 1 }));
									}}
									variant="contained"
								>
									Pessoa Física
								</Button>
							</Grid>
						</Grid>
					</>
				)}
				{step === 1 && (
					<>
						<Grid container spacing={1}>
							<Grid item xs={10} md={6} className={classes.segundoStep}>
								<Typography variant="h6">Preencha as suas informações abaixo.</Typography>
								<Grid container spacing={4}>
									<Grid item xs={12}>
										<FormControl style={{ marginTop: 15, width: '100%' }}>
											<Autocomplete
												options={categorias.filter(cat => cat.id)} // filtra apenas os itens selecionáveis
												getOptionLabel={option => option.description}
												value={categorias.find(cat => cat.id === data.categoria) || null}
												onChange={(event, newValue) =>
													setData({ ...data, categoria: newValue ? newValue.id : '' })
												}
												renderInput={params => (
													<TextField
														{...params}
														label="Categoria"
														variant="standard"
														required
														error={onError && !data.categoria}
														helperText={
															onError && !data.categoria
																? 'O campo não pode ser vazio.'
																: ''
														}
													/>
												)}
											/>
										</FormControl>
									</Grid>
									{tipoDeEstabelecimento === 1 ? (
										<>
											<Grid item xs={12} md={6}>
												<ZText
													onError={onError}
													required={true}
													onBlur={handleChange('nome')}
													value={data.nome}
													label="Nome Completo"
												/>
											</Grid>
											<Grid item xs={12} md={6}>
												<ZText
													label="Data Nascimento"
													onError={onError}
													required={true}
													value={data.dataNascimento2}
													mask="99/99/9999"
													onBlur={handleDataNascimento('dataNascimento2')}
												/>
											</Grid>
										</>
									) : (
										<>
											<Grid item xs={12} md={6}>
												<ZText
													onError={onError}
													required={true}
													value={data.razaoSocial}
													onBlur={handleChange('razaoSocial')}
													label="Razão Social"
												/>
											</Grid>
											<Grid item xs={12} md={6}>
												<ZText
													label="Nome Fantasia"
													onError={onError}
													required={true}
													value={data.nomeFantasia}
													onBlur={handleChange('nomeFantasia')}
												/>
											</Grid>
										</>
									)}
									<Grid item xs={12} md={6}>
										<ZText
											label="E-mail"
											onError={onError}
											required={true}
											type="email"
											email={true}
											value={data.email}
											onBlur={handleChange('email')}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										{tipoDeEstabelecimento === 1 ? (
											<>
												<ZText
													onError={onError}
													required={true}
													mask="999.999.999-99"
													value={data.cpf}
													onBlur={handleChange('cpf')}
													label="CPF"
												/>
											</>
										) : (
											<>
												<ZText
													onError={onError}
													required={true}
													mask="99.999.999/9999-99"
													value={data.cnpj}
													onBlur={handleChange('cnpj')}
													label="CNPJ"
												/>
											</>
										)}
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											label="Celular"
											onError={onError}
											required={true}
											value={data.celular}
											mask="(99) 9 9999-9999"
											onBlur={handleChange('celular')}
										/>
									</Grid>

									<Grid item xs={12} md={6}>
										<ZText
											onError={onError}
											required={true}
											value={data.nomeComprovante}
											onBlur={handleChange('nomeComprovante')}
											label="Nome Comprovante"
										/>
									</Grid>
									{tipoDeEstabelecimento === 1 && (
										<>
											<Grid item xs={12} md={12}>
												<ZText
													label={'Faturamento estimado'}
													onError={onError}
													// disabled={match.params.estabelecimentoId ? true : false}
													required={true}
													value={Mask.Real(data.faturamento_estimado || 0)}
													onChange={value =>
														setData({
															...data,
															faturamento_estimado: Mask.OnlyNumber(value) / 100,
														})
													}
												/>
											</Grid>
										</>
									)}
									{tipoDeEstabelecimento === 2 && (
										<>
											<Grid item xs={12} md={6}>
												<ZText
													label="Telefone"
													onError={onError}
													required={true}
													value={data.telefone}
													mask="(99) 9999-9999"
													onBlur={handleChange('telefone')}
												/>
											</Grid>
											<Grid item xs={12} md={6}>
												<ZText
													label={'Faturamento estimado'}
													onError={onError}
													// disabled={match.params.estabelecimentoId ? true : false}
													required={true}
													value={Mask.Real(data.faturamento_estimado || 0)}
													onChange={value =>
														setData({
															...data,
															faturamento_estimado: Mask.OnlyNumber(value) / 100,
														})
													}
												/>
											</Grid>
										</>
									)}
									<Grid item xs={12} md={6}>
										<Button
											fullWidth
											size="large"
											className={classes.botao}
											onClick={() => setStep(step - 1)}
											variant="contained"
										>
											Voltar
										</Button>
									</Grid>
									<Grid item xs={12} md={6}>
										<Button
											fullWidth
											onClick={() => setStep(step + 1)}
											size="large"
											className={classes.botao}
											variant="contained"
										>
											Continuar
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</>
				)}
				{step === 2 && (
					<>
						<Grid container spacing={1}>
							<Grid item xs={10} md={6} className={classes.segundoStep}>
								<Typography variant="h6">Preencha seu endereço.</Typography>
								<Grid container spacing={4}>
									<Grid item xs={12}>
										<ZText
											label="CEP"
											onError={onError}
											required={true}
											mask="99999-999"
											value={data.endereco.cep}
											onBlur={handleChangeEndereco('cep')}
										/>
									</Grid>
									<Grid item xs={12}>
										<ZText
											label="Endereço"
											onError={onError}
											required={true}
											value={data.endereco.logradouro}
											onBlur={handleChangeEndereco('logradouro')}
										/>
									</Grid>
									<Grid item xs={12} md={3}>
										<ZText
											label="Número"
											onError={onError}
											required={true}
											value={data.endereco.numero}
											onBlur={handleChangeEndereco('numero')}
										/>
									</Grid>
									<Grid item xs={12} md={9}>
										<ZText
											onError={onError}
											value={data.endereco.complemento}
											onBlur={handleChangeEndereco('complemento')}
											label="Complemento"
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											onError={onError}
											required={true}
											value={data.endereco.cidade}
											onBlur={handleChangeEndereco('cidade')}
											label="Cidade"
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											onError={onError}
											required={true}
											value={data.endereco.estado}
											onBlur={handleChangeEndereco('estado')}
											label="UF"
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											onError={onError}
											required={true}
											value={data.endereco.bairro}
											onBlur={handleChangeEndereco('bairro')}
											label="Bairro"
										/>
									</Grid>
									<Grid item xs={6}></Grid>
									<Grid item xs={12} md={6}>
										<Button
											fullWidth
											size="large"
											className={classes.botao}
											onClick={() => setStep(step - 1)}
											variant="contained"
										>
											Voltar
										</Button>
									</Grid>
									<Grid item xs={12} md={6}>
										<Button
											fullWidth
											onClick={
												tipoDeEstabelecimento === 1
													? () => setStep(step + 3)
													: () => setStep(step + 1)
											}
											size="large"
											className={classes.botao}
											variant="contained"
										>
											Continuar
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</>
				)}
				{step === 3 && (
					<>
						<Grid container spacing={1}>
							<Grid item md={6} xs={10} className={classes.segundoStep}>
								<Typography variant="h6">Preencha os dados do proprietário.</Typography>
								<Grid container spacing={4}>
									<Grid item xs={12} md={6}>
										<ZText
											label="Nome"
											onError={onError}
											required={true}
											value={data.proprietario.nome}
											onBlur={handleProprietario('nome')}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											label="Sobrenome"
											onError={onError}
											required={true}
											value={data.proprietario.sobrenome}
											onBlur={handleProprietario('sobrenome')}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											label="E-mail"
											onError={onError}
											required={true}
											type="email"
											email={true}
											value={data.proprietario.email}
											onBlur={handleProprietario('email')}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											label="Data Nascimento"
											onError={onError}
											required={true}
											value={data.proprietario.dataNascimento}
											mask="99/99/9999"
											onBlur={handleProprietario('dataNascimento')}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											label="Celular"
											onError={onError}
											required={true}
											mask="(99) 9 9999-9999"
											value={data.proprietario.celular}
											onBlur={handleProprietario('celular')}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											mask="999.999.999-99"
											label="CPF"
											onError={onError}
											required={true}
											value={data.proprietario.cpf}
											onBlur={handleProprietario('cpf')}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<Button
											fullWidth
											size="large"
											className={classes.botao}
											onClick={() => setStep(step - 1)}
											variant="contained"
										>
											Voltar
										</Button>
									</Grid>
									<Grid item xs={12} md={6}>
										<Button
											fullWidth
											size="large"
											className={classes.botao}
											onClick={() => setStep(step + 1)}
											variant="contained"
										>
											Continuar
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</>
				)}
				{step === 4 && (
					<>
						<Grid container spacing={1}>
							<Grid item xs={10} md={6} className={classes.segundoStep}>
								<Typography variant="h6">Preencha os dados residenciais do proprietário.</Typography>
								<Grid container spacing={4}>
									<Grid item xs={12}>
										<ZText
											label="CEP"
											onError={onError}
											required={true}
											mask="99999-999"
											value={data.proprietario.endereco.cep}
											onBlur={handleChangeProprietarioEndereco('cep')}
										/>
									</Grid>
									<Grid item xs={12}>
										<ZText
											label="Endereço"
											onError={onError}
											required={true}
											value={data.proprietario.endereco.logradouro}
											onBlur={handleChangeProprietarioEndereco('logradouro')}
										/>
									</Grid>
									<Grid item xs={12} md={3}>
										<ZText
											label="Número"
											onError={onError}
											required={true}
											value={data.proprietario.endereco.numero}
											onBlur={handleChangeProprietarioEndereco('numero')}
										/>
									</Grid>
									<Grid item xs={12} md={9}>
										<ZText
											onError={onError}
											value={data.proprietario.endereco.complemento}
											onBlur={handleChangeProprietarioEndereco('complemento')}
											label="Complemento"
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											onError={onError}
											required={true}
											value={data.proprietario.endereco.cidade}
											onBlur={handleChangeProprietarioEndereco('cidade')}
											label="Cidade"
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											onError={onError}
											required={true}
											value={data.proprietario.endereco.estado}
											onBlur={handleChangeProprietarioEndereco('estado')}
											label="UF"
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<ZText
											onError={onError}
											required={true}
											value={data.proprietario.endereco.bairro}
											onBlur={handleChangeProprietarioEndereco('bairro')}
											label="Bairro"
										/>
									</Grid>
									<Grid item xs={6}></Grid>
									<Grid item xs={12} md={6}>
										<Button
											fullWidth
											size="large"
											className={classes.botao}
											onClick={() => setStep(step - 1)}
											variant="contained"
										>
											Voltar
										</Button>
									</Grid>
									<Grid item xs={12} md={6}>
										<Button
											fullWidth
											onClick={
												tipoDeEstabelecimento === 1
													? () => setStep(step + 3)
													: () => setStep(step + 1)
											}
											size="large"
											className={classes.botao}
											variant="contained"
										>
											Continuar
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</>
				)}
				{step === 5 && (
					<>
						<Grid container spacing={1}>
							<Grid item xs={10} md={6} className={classes.segundoStep}>
								<Typography variant="h6">Preencha seus dados bancários.</Typography>
								<Grid container spacing={4}>
									<Grid item xs={12} md={6}>
										<TextField
											label="Nome"
											value={data.contaBancaria.nomeTitular}
											onChange={e =>
												setData({
													...data,
													contaBancaria: {
														...data.contaBancaria,
														nomeTitular: e.target.value,
													},
												})
											}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<TextField
											label={tipoDeEstabelecimento === 2 ? `CNPJ` : `CPF`}
											disabled={true}
											value={
												tipoDeEstabelecimento === 2
													? Mask.Cnpj(data.cnpj || '')
													: Mask.Cpf(data.cpf || '')
											}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<FormControl
											//error={errors.bancoId || false}
											className={classes.formControl}
										>
											<InputLabel htmlFor="banco">Banco</InputLabel>
											<Select
												value={data.contaBancaria.bancoId}
												onChange={e =>
													setData({
														...data,
														contaBancaria: {
															...data.contaBancaria,
															bancoId: e.target.value,
														},
													})
												}
												inputProps={{ id: 'bancoId' }}
											>
												{bancos.map((b, i) => (
													<MenuItem key={i} value={b.id}>
														{b.nome}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={12} md={6}>
										<TextField
											label="Agência"
											value={data.contaBancaria.agencia}
											onChange={e =>
												setData({
													...data,
													contaBancaria: {
														...data.contaBancaria,
														agencia: e.target.value,
													},
												})
											}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<TextField
											label="Conta"
											value={data.contaBancaria.conta}
											onChange={e =>
												setData({
													...data,
													contaBancaria: {
														...data.contaBancaria,
														conta: e.target.value,
													},
												})
											}
										/>
									</Grid>
									<Grid item xs={6}></Grid>
									<Grid item xs={12} md={6}>
										<Button
											fullWidth
											size="large"
											className={classes.botao}
											onClick={
												tipoDeEstabelecimento === 1
													? () => setStep(step - 3)
													: () => setStep(step - 1)
											}
											variant="contained"
										>
											Voltar
										</Button>
									</Grid>
									<Grid item xs={12} md={6}>
										{/* <Button
												fullWidth
												size="large"
												className={classes.botao}
												onClick={() =>
													//data.marketplaceId === 17 || data.marketplaceId === 16
													termosSuccess === true ? handleOpenTermo() : handleSubmitForm()
												}
												variant="contained"
											>
												Finalizar
											</Button> */}
										<ZSaveButton
											onClick={() =>
												//data.marketplaceId === 17 || data.marketplaceId === 16
												termosSuccess === true ? handleOpenTermo() : handleSubmitForm()
											}
											// onClick={() => (data.marketplaceId === 1 ) ? handleOpenTermoEdmond() : handleSubmitForm()}
											align="right"
											label={'Salvar'}
											required={true}
											loading={loading}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</>
				)}
			</Grid>
			{message.message.length > 0 && (
				<Grid item xs={12}>
					<ZAlert type={message.type} message={message.message} time={moment().unix()} vertical="top" />
				</Grid>
			)}
		</Container>
		// </div>
	);
};
