/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import ZLoader from '../system/ZLoader';
import ZFiltros from '../system/ZFiltros';
import ZEmpty from '../system/ZEmpty';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import config from 'react-global-configuration';
import { Isset, Formatter } from '../../utils/';
import FileDownload from 'js-file-download';
import TablePagination from '@material-ui/core/TablePagination';
import { makeStyles } from '@material-ui/core/styles';
import { addMessage } from '../../store/alert';
import clsx from 'clsx';
import { FaDownload, FaInfoCircle } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import ZModalWarning from '../system/ZModalWarning';
import LastExport from '../Export/LastExport';

const useStyles = makeStyles(theme => ({
	header: {
		fontWeight: 700,
		fontSize: 20,
		margin: 8,
	},
	export: {
		position: 'absolute',
		right: 10,
		opacity: 0.5,
		cursor: 'pointer',
		color: '#464ae6',
		fontSize: 16,
		transition: theme.transitions.create(['all'], {
			duration: theme.transitions.duration.complex,
		}),
		'&:hover': {
			fontSize: 18,
			opacity: 1,
		},
	},
	tableHeader: {
		textAlign: 'center',
		fontWeight: 700,
		fontSize: 18,
	},
	tableSubHeader: {
		textAlign: 'center',
		fontWeight: 700,
		fontSize: 16,
	},
	tableDateRow: {
		fontWeight: 700,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 10,
		backgroundColor: `${theme.palette.primary.main}aa`,
		color: theme.palette.getContrastText(theme.palette.primary.main),
		paddingLeft: 20,
	},
	exportDownload: {
		color: '#011D29',
		fontSize: 16,
		marginLeft: 8,
	},
	iconButton: {
		minWidth: 0,
	},
	buttonExport: {
		color: '#040633',
		border: `solid 1px #040633`,
		'&:hover': {
			color: '#FFF',
			backgroundColor: '#011d29',
			'& svg': {
				color: '#FFF',
			},
		},
		[theme.breakpoints.down('xs')]: {
			width: '90%',
			marginTop: 16,
		},
		'& svg': {
			margin: 0,
		},
	},
	color: '#011D29',
	fontSize: 16,
	marginLeft: 8,
	tableRow: {
		textAlign: 'center',
		fontWeight: 700,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 20,
		cursor: 'pointer',
		marginBottom: 2,
		backgroundColor: `${theme.palette.primary.main}1a`,
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}2b`,
		},
	},
	tableSubRow: {
		textAlign: 'center',
		fontWeight: 700,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		borderRadius: 20,
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main}2b`,
		},
	},
	tableRowSelected: {
		backgroundColor: `${theme.palette.primary.main}3c`,
	},
	vendas: {
		marginBottom: 10,
	},
}));
const tooltipTitle =
	'O tempo para concluir a exportação é geralmente inferior a 10 minutos, mas pode variar devido à complexidade dos relatórios e à quantidade de dados. Também pode ser afetado por picos de solicitações de arquivos.';

export default props => {
	const classes = useStyles();

	const dispatch = useDispatch();

	const history = useHistory();
	const token = useSelector(store => store.auth.token);
	const usuario = useSelector(store => store.auth.usuario);

	const { id: estabelecimentoId } = useSelector(state => state.admin.estabelecimento) || {};

	const queryFilter = {
		data_venda: { de: new Date(), ate: new Date() },
		estabelecimentoId: estabelecimentoId ? [estabelecimentoId] : [],
	};

	const [filtros, setFiltros] = useState(queryFilter);
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);

	const [pagination, setPagination] = useState({
		limit: 50,
		current: 0,
		totalRows: 0,
	});
	const [showLastExport, setShowLastExport] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [descriptionModal, setDescriptionModal] = useState('Carregando informações a serem exportadas ...');
	const [exportInProgress, setExportInProgress] = useState(false);

	const filtrosChange = async data => {
		data.data_venda = {
			de: data.date.start,
			ate: data.date.end,
		};
		data.estabelecimentoId =
			Isset(() => data.estabelecimento) && Array.isArray(data.estabelecimento) ? [...data.estabelecimento] : [];

		// if (estabelecimentoId) {
		// 	data.estabelecimentoId.push(estabelecimentoId);
		// }

		setFiltros(data);
	};

	useEffect(() => {
		setFiltros({
			...filtros,
			estabelecimentoId,
		});
	}, [estabelecimentoId]);

	useEffect(() => {
		const getData = async () => {
			setLoading(true);
			setData([]);
			const filters = {};

			if (filtros.estabelecimentoId && filtros.estabelecimentoId.length > 0) {
				filters.estabelecimentoId = filtros.estabelecimentoId;
			}
			if (filtros.data_venda.de) {
				filters.startDate = filtros.data_venda.de;
			}
			if (filtros.data_venda.ate) {
				filters.endDate = filtros.data_venda.ate;
			}

			if (filtros.omniEstabelecimento) {
				filters.omniEstabelecimento = filtros.omniEstabelecimento;
			}

			const res = await axios.post(
				`${config.get('apiUrl')}graphql`,
				{
					query: `
						query vendasPorPOS($filters: Filter, $pagination: Pagination) {
							vendasPorPOS(filters: $filters, pagination: $pagination){
								id, estabelecimento, identificationNumber, serial, chip, quantidadeVendas, valorTotal
							}
							vendasPorPosPagination(filters: $filters, pagination: $pagination){
								limit, current, totalRows
							}
						}
					`,
					variables: {
						filters,
						pagination,
					},
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			if (res.status === 200 && Isset(() => res.data.data.vendasPorPOS)) {
				const pos = res.data.data.vendasPorPOS;

				setData(pos);
				setPagination({ ...pagination, totalRows: res.data.data.vendasPorPosPagination.totalRows });
			}
			setLoading(false);
		};

		getData();
	}, [pagination.limit, pagination.current, filtros]);
	const exportXlsClick = () => {
		setExportInProgress(true); // Bloqueia o botão de exportação
		setShowLastExport(true); // Abre o modal de exportação
	};

	const exportXls = async getLastExports => {
		try {
			dispatch(addMessage({ type: 'warning', message: 'Sua nova exportação está sendo processada.' }));

			const filters = {
				...filtros,
				onlyPOS: true,
				estabelecimentoId: filtros.estabelecimentoId,
			};

			console.log('Iniciando exportação de vendas...', filters);

			await axios.get(`${config.get('apiUrl')}export/vendas`, {
				headers: { Authorization: `Bearer ${token}` },
				responseType: 'blob',
				params: {
					filters,
				},
			});
		} catch (error) {
			console.error('Erro ao exportar vendas:', error);
			const errorMessage =
				error.response && error.response.data && error.response.data.message
					? error.response.data.message
					: 'Erro ao exportar vendas.';
			dispatch(addMessage({ type: 'error', message: errorMessage }));
		}
	};

	const newExport = async getLastExports => {
		try {
			await exportXls(getLastExports); // Executa a exportação
		} catch (error) {
			console.error('Erro ao processar nova exportação:', error);
		} finally {
			setExportInProgress(false); // Libera o botão após finalizar as operações
		}
	};

	const rowClick = identificationNumber => {
		history.push(
			`/vendas?pos=${identificationNumber}&startDate=${filtros.data_venda.de}&endData=${filtros.data_venda.ate}`
		);
	};

	return (
		<Grid className={classes.vendas}>
			<div className={classes.header}>Vendas Por POS</div>

			<Paper
				style={{
					position: 'relative',
					marginBottom: 10,
					borderRadius: 4,
					padding: '5px 10px',
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<ZFiltros
					onChange={filtrosChange}
					dateRange={{
						default: 'custom',
						startDate: filtros.data_venda.de,
						endDate: filtros.data_venda.ate,
					}}
					input={
						![2, 3].includes(usuario.grupo_id)
							? [
									{
										key: 'omniEstabelecimento',
										defaultValue: filtros.omniEstabelecimento,
										placeholder: 'Estabelecimento',
										modal: false,
										md: 3,
									},
							  ]
							: null
					}
				/>
				{usuario.grupo_id !== 7 && (
					<>
						<Tooltip title={tooltipTitle}>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<FaInfoCircle style={{ marginRight: 5, color: '#FF9800' }} />
							</div>
						</Tooltip>

						<Tooltip title="Exportar">
							<Button className={clsx(classes.buttonExport, classes.iconButton)} onClick={exportXlsClick}>
								<FaDownload className={classes.exportDownload} />
							</Button>
						</Tooltip>
					</>
				)}
			</Paper>

			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper style={{ padding: 15 }}>
						{loading ? (
							<ZLoader height={200} />
						) : (
							<>
								{data.length > 0 ? (
									<Grid container className={classes.vendas}>
										<Grid container item className={classes.tableHeader}>
											<Grid item xs={3}>
												Estabelecimento
											</Grid>
											<Grid item xs={2}>
												Serial
											</Grid>
											<Grid item xs={3}>
												Chip
											</Grid>
											<Grid item xs={2}>
												Quantidade
											</Grid>
											<Grid item xs={2}>
												Valor
											</Grid>
										</Grid>
										{data.map((pos, key) => (
											<React.Fragment key={key}>
												<Grid
													container
													item
													className={classes.tableRow}
													onClick={() => rowClick(pos.identificationNumber)}
												>
													<Grid item xs={3}>
														{pos.estabelecimento}
													</Grid>
													<Grid item xs={2}>
														{pos.serial}
													</Grid>
													<Grid item xs={3}>
														{pos.chip}
													</Grid>
													<Grid item xs={2}>
														{pos.quantidadeVendas}
													</Grid>
													<Grid item xs={2}>
														{Formatter.Real(pos.valorTotal)}
													</Grid>
												</Grid>
												{/* <Collapse in={pos.id === posSelected} style={{ width: '100%' }}>
													<Grid container item spacing={2} className={classes.vendas}>
														<Grid container item xs={12} className={classes.tableSubHeader}>
															<Grid item xs={2}>
																Data
															</Grid>
															<Grid item xs={4}>
																Cliente
															</Grid>
															<Grid item xs={2}>
																Forma de Pagamento
															</Grid>
															<Grid item xs={2}>
																Valor
															</Grid>
															<Grid item xs={2}>
																Valor Recebido
															</Grid>
														</Grid>
														{pos.vendas.map((venda, key) => (
															<Grid
																key={key}
																container
																item
																className={classes.tableSubRow}
																onClick={() => openVenda(venda.id)}
															>
																<Grid item xs={2}>
																	{format(venda.data, 'DD/MM/YYYY HH:mm')}
																</Grid>
																<Grid item xs={4}>
																	{venda.cliente}
																</Grid>
																<Grid item xs={2}>
																	{venda.formaPagamento}
																</Grid>
																<Grid item xs={2}>
																	{Formatter.Real(venda.valor)}
																</Grid>
																<Grid item xs={2}>
																	{Formatter.Real(venda.valorRecebido)}
																</Grid>
															</Grid>
														))}
													</Grid>
												</Collapse> */}
											</React.Fragment>
										))}
										<Grid item xs={12}>
											<TablePagination
												rowsPerPageOptions={[25, 50, 100]}
												component="div"
												count={pagination.totalRows}
												rowsPerPage={pagination.limit}
												page={pagination.current}
												onChangePage={(e, page) =>
													setPagination({ ...pagination, current: page })
												}
												onChangeRowsPerPage={e => {
													const totalRows = e.target.value * pagination.limit;
													if (totalRows > pagination.totalRows) {
														setPagination({
															...pagination,
															limit: e.target.value,
															current: 0,
														});
													} else {
														setPagination({ ...pagination, limit: e.target.value });
													}
												}}
												labelRowsPerPage={'Linhas por página:'}
												labelDisplayedRows={({ from, to, count }) =>
													`${from}-${to === -1 ? count : to} de ${count}`
												}
											/>
										</Grid>
									</Grid>
								) : (
									<ZEmpty height={200} text="Nenhuma Venda Encontrada." />
								)}
							</>
						)}
						{showLastExport && (
							<>
								<LastExport
									show={showLastExport}
									onClose={() => setShowLastExport(false)}
									url={'/export/vendasPOS'}
									newExport={newExport}
									loadingProp={exportInProgress}
								/>
							</>
						)}
					</Paper>
				</Grid>
			</Grid>
			<ZModalWarning
				title={`Atenção`}
				description={descriptionModal}
				open={showModal}
				content={
					descriptionModal === 'Carregando informações a serem exportadas ...' ? (
						<ZLoader height={50} size={25} />
					) : (
						''
					)
				}
				handleClose={() => setShowModal(false)}
				button={
					descriptionModal === 'Carregando informações a serem exportadas ...' ? (
						''
					) : (
						<Button onClick={() => setShowModal(false)} color="primary">
							Ok
						</Button>
					)
				}
			/>
		</Grid>
	);
};
